.categories {
  background-color: var(--xs-categories-background);

  &.x6 {
    @media (max-width: 767px) {
      .container {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;

        h2 {
          margin-left: 10px;
        }

        p {
          margin-left: 10px;
          margin-bottom: 10px;
        }
      }
    }

    @media (min-width: 992px) {
      .container {
        padding: 40px 24px 30px 23px;

        h2 {
          margin-left: 7px;
        }

        p {
          margin-left: 7px;
          margin-bottom: 22px;
        }
      }
    }

    @media (min-width: 1200px) {
      .container p {
        margin-bottom: 20px;
      }
    }
  }

  &.x3 {
    @media (min-width: 992px) {
      .container {
        padding-top: 40px;

        @media (max-width: 1199px) {
          padding-bottom: 30px;
        }
      }
    }
  }

  &.x4 {
    @media (min-width: 768px) {
      .container {
        padding-left: 15px;
        padding-right: 20px;

        @media (max-width: 1199px) {
          padding-bottom: 30px;
        }
      }
    }

    @media (min-width: 992px) {
      .container {
        padding-left: 30px;
        padding-right: 32px;
        padding-top: 40px;
      }
    }
  }

  @media (min-width: 768px) {
    background-color: var(--sm-categories-background);
  }

  .container {
    padding: 30px 30px 25px;

    @media (min-width: 768px) {
      background-color: var(--sm-categories-container-background);
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 30px 15px 25px;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    h2 {
      color: var(--xs-categories-heading-color);
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      margin: 0 0 10px;

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 5px;
      }
    }

    p {
      color: var(--xs-categories-color);
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;

      @media (min-width: 992px) and (max-width: 1199px) {
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
      }

      a {
        color: var(--xs-categories-link-color);

        &:hover {
          color: var(--xs-categories-link-hover-color);
        }
      }
    }

    .categories-grid {
      padding: 0;
      margin-bottom: 0;

      &::before {
        display: none;
      }

      @media (min-width: 768px) {
        padding: 2.5px 7.5px 0;
        display: flex;
        flex-wrap: wrap;
      }

      @media (min-width: 992px) {
        padding: 0 5px;
      }

      @media (min-width: 1200px) {
        padding-top: 5px;
      }

      .category-item {
        background-color: transparent;
        padding: 3px 15px;

        @media (min-width: 768px) {
          padding: 7.5px;
          display: flex;
        }

        @media (min-width: 992px) {
          padding: 10px;
        }

        @media (min-width: 1200px) {
          padding: 15px;
        }

        a {
          text-decoration: none;

          @media (min-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }

        a > img {
          max-width: 100%;
        }

        a > span {
          background-color: var(--xs-categories-item-background);
          border: var(--border-width) solid var(--xs-categories-item-border-color);
          color: var(--xs-categories-item-color);
          font-weight: 600;
          font-size: 21px;
          line-height: 24px;
          text-align: center;
          padding: 21px 20px;
          display: block;

          @media (min-width: 768px) {
            background-color: var(--sm-categories-item-background);
            border-color: var(--sm-categories-item-border);
            padding: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 auto;
          }

          @media (min-width: 992px) {
            padding: 17px 14px;
          }

          @media (min-width: 1200px) {
            padding: 21px 18px;
          }

          @media (max-width: 767px) {
            padding: 14px 10px 15px;
            font-size: 18px;
            line-height: 21px;
          }
        }

        a:hover > span {
          @media (min-width: 768px) {
            background-color: var(--sm-category-item-hover-background);
            border-color: var(--sm-category-item-hover-border);
            color: var(--sm-category-item-hover-color);
          }
        }
      }

      &.grid-3 {
        @media (min-width: 1200px) {
          padding-left: 0;
          padding-right: 0;
        }

        .category-item {
          a > span {
            padding: 14px 20px;
            min-height: 50px;

            @media (min-width: 768px) {
              padding: 12px 13px;
              min-height: 46px;
            }

            @media (min-width: 992px) {
              padding: 18px 14px;
              min-height: 58px;
            }

            @media (min-width: 1200px) {
              padding: 22px 18px;
              min-height: 70px;
            }
          }
        }
      }

      &.grid-4 {
        @media (min-width: 768px) {
          padding-left: 6px;
          padding-right: 2px;
        }

        @media (min-width: 1200px) {
          padding: 8px 0 0;
        }

        .category-item {
          @media (min-width: 768px) {
            padding: 8px;
          }

          @media (min-width: 992px) {
            padding: 10px;
          }

          @media (min-width: 1200px) {
            padding: 15px;
          }

          a > span {
            padding: 14px 20px;
            min-height: 50px;

            @media (min-width: 768px) {
              padding: 6px 10px;
              min-height: 40px;
            }

            @media (min-width: 992px) {
              padding: 11px 14px;
              min-height: 50px;
            }

            @media (min-width: 1200px) {
              padding: 17px 10px 16px;
              min-height: 60px;
            }
          }
        }
      }

      &.grid-6 {
        margin: auto;
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 768px) {
          padding-left: 22px;
          padding-top: 5px;
        }

        @media (min-width: 992px) {
          padding: 0;
        }

        .category-item {
          padding: 10px;
          display: flex;

          @media (min-width: 768px) {
            padding: 15px 25px;
            max-width: 225px;
          }

          @media (min-width: 992px) {
            padding: 8px 5px;
            max-width: 163px;
          }

          @media (min-width: 1200px) {
            padding: 10px 6px;
            min-width: 187px;
          }

          a {
            display: flex;
            flex-direction: column;
          }

          a > span {
            padding: 7px 5px;
            min-height: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1 0 auto;

            @media (min-width: 768px) {
              padding: 10px 8px;
              min-height: 50px;
            }

            @media (min-width: 992px) {
              padding: 8px 5px;
              min-height: 41px;
            }

            @media (min-width: 1200px) {
              padding: 12px 10px;
              min-height: 50px;
            }
          }
        }
      }
    }
  }
}
