@media (max-width: 767px) {
  body > .alert a {
    /* xs - home | locator.alert-text */
    /* xs - home | locator.alert-bg-1 */
    /* xs - stateful.alert-text */
    /* xs - stateful.alert-bg-1 */
  }

  body > .alert a:hover {
    /* xs - stateful.alert-text-hover */
    /* xs - stateful.alert-bg-1-hover */
  }

  body > .alert a:active {
    /* xs - stateful.alert-text-active */
    /* xs - stateful.alert-bg-1-active */
  }

  .dropdown-toggle .caret {
    /* xs - home | locator.links-cities-arrow */
  }

  .navbar-collapse .dropdown-toggle .caret {
    /* xs - stateful.menu-arrow */
  }

  .navbar-collapse .dropdown-toggle:hover .caret {
    /* xs - stateful.menu-arrow-hover */
  }

  .navbar-collapse .dropdown-toggle:active .caret {
    /* xs - stateful.menu-arrow-active */
  }

  .navbar-collapse ul.navbar-nav li > .text-center {
    /* xs - stateful.menu-dropdown-top-bg */
  }

  .navbar-collapse ul.navbar-nav li.close > a {
    /* xs - stateful.menu-dropdown-close-bg */
  }

  .navbar-collapse ul.navbar-nav li.close:hover {
    /* xs - stateful.menu-dropdown-close-bg-hover */
  }

  #menu > div > div > ul > li.close.hidden-md.hidden-lg > a:hover {
    /* xs - stateful.menu-dropdown-close-bg-hover */
  }

  #menu > div > div > ul > li.close.hidden-md.hidden-lg > a:active {
    /* xs - stateful.menu-dropdown-close-bg-active */
  }

  .navbar-collapse ul.navbar-nav li.close > a .fa {
    /* xs - stateful.menu-dropdown-close-x */
  }

  .navbar-collapse ul.navbar-nav li.close > a:hover .fa {
    /* xs - stateful.menu-dropdown-close-x-hover */
  }

  .navbar-collapse ul.navbar-nav li.close > a:active .fa {
    /* xs - stateful.menu-dropdown-close-x-active */
  }

  .navbar-collapse .navbar-text {
    /* xs - stateful.top-text */
    /* xs - stateful.menu-bg */
  }

  .navbar-collapse .navbar-text a {
    /* xs - stateful.top-text-link */
  }

  .navbar-collapse .navbar-text a:hover {
    /* xs - stateful.top-text-hover */
  }

  .navbar-collapse .navbar-text a:active {
    /* xs - stateful.top-text-active */
  }

  .top {
    /* xs - home | locator.top-bg-2 */
    /* xs - home | locator.top-bg-1 */
    background: #3e464b;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .container {
    /* xs - home | locator.top-bg-2 */
    background: #3e464b;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .container.phone {
    /* xs - home | locator.xs-top-phone-bg */
  }

  .top .navbar-header.brands .divider {
    /* xs - home | locator.top-brand-divider */
  }

  .top a.phone {
    /* xs - home | locator.xs-top-phone-number */
    /* xs - home | locator.top-phone-number */
    /* xs - home | locator.xs-top-phone-bg */
    /* xs - home | locator.top-phone-bg */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 23px;
    font-weight: normal;
  }

  .top .navbar-header.brands a.phone:hover,
  .top a.phone:hover {
    /* xs - stateful.xs-top-phone-button-hover */
    /* xs - stateful.xs-top-phone-number-hover */
    /* xs - stateful.top-phone-number-hover */
    /* xs - stateful.top-phone-bg-hover */
  }

  .top .navbar-header.brands a.phone:active,
  .top a.phone:active {
    /* xs - stateful.xs-top-phone-button-active */
    /* xs - stateful.xs-top-phone-number-active */
    /* xs - stateful.top-phone-number-active */
    /* xs - stateful.top-phone-bg-active */
  }

  .top .navbar-right .navbar-nav > li.divider {
    /* xs - home | locator.top-link-divider */
  }

  .top .navbar-right.phone::before {
    /* xs - home | locator.top-phone-divider */
  }

  .top .navbar-right .navbar-nav.utility > li > a {
    /* xs - home | locator.top-utility-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.links > li > a {
    /* xs - home | locator.top-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.links > li > a {
    /* xs - home | locator.top-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.utility > li.divider {
    /* xs - home | locator.top-utility-link-divider */
  }

  .top .navbar-right .navbar-nav.links > li.divider {
    /* xs - home | locator.top-link-divider */
  }

  .top .navbar-right .navbar-text {
    /* xs - home | locator.top-text */
  }

  #menu {
    /* xs - home | locator.menu-bg-1 */
  }

  .navbar .navbar-toggle {
    /* xs - home | locator.menu-hamburger-bg */
    /* xs - stateful.menu-hamburger-bg */
  }

  .navbar .navbar-toggle:hover {
    /* xs - stateful.menu-hamburger-bg-hover */
  }

  .navbar .navbar-toggle:active {
    /* xs - stateful.menu-hamburger-bg-active */
  }

  .navbar .navbar-toggle:hover .icon-bar {
    /* xs - stateful.menu-hamburger-hover */
  }

  .navbar .navbar-toggle:active .icon-bar {
    /* xs - stateful.menu-hamburger-active */
  }

  .navbar .navbar-toggle .icon-bar {
    /* xs - home | locator.menu-hamburger */
    /* xs - home | locator.menu-hamburger-lines */
  }

  .navbar-collapse {
    /* xs - stateful.menu-dropdown-top-bg */
  }

  .navbar-collapse .container {
    /* xs - home | locator.menu-bg-2 */
  }

  .navbar-collapse ul.navbar-nav .open > a,
  .navbar-collapse ul.navbar-nav > li > a {
    /* xs - stateful.menu-anchor-text-# */
    /* xs - stateful.menu-button */
    /* xs - home | locator.menu-anchor-text-1 */
    /* xs - home | locator.menu-button height */
  }

  .navbar-collapse ul.navbar-nav .open > a:hover,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:hover {
    /* xs - stateful.menu-anchor-text-#-hover */
    /* xs - stateful.menu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open > a:focus,
  .navbar-collapse ul.navbar-nav .open > a:active,
  .navbar-collapse ul.navbar-nav .open > a:hover:focus,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus:hover,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:active,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus {
    /* xs - stateful.menu-anchor-text-#-active */
    /* xs - stateful.menu-button-active */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a {
    /* xs - stateful.submenu-anchor-text-#-# */
    /* xs - stateful.submenu-button */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:hover {
    /* xs - stateful.submenu-anchor-text-#-#-hover */
    /* xs - stateful.submenu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:active {
    /* xs - stateful.submenu-anchor-text-#-#-active */
    /* xs - stateful.submenu-button-active */
  }

  .navbar-collapse ul.navbar-nav.links > li > a {
    /* xs - stateful.top-anchor-text-# */
    /* xs - stateful.top-link-button */
  }

  .navbar-collapse ul.navbar-nav.links > li > a:hover {
    /* xs - stateful.top-anchor-text-#-hover */
    /* xs - stateful.top-link-button-hover */
  }

  .navbar-collapse ul.navbar-nav.links > li > a:active {
    /* xs - stateful.top-anchor-text-#-active */
    /* xs - stateful.top-link-button-active */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a {
    /* xs - stateful.top-utility-anchor-text-# */
    /* xs - stateful.top-utility-link-button */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a:hover {
    /* xs - stateful.top-utility-anchor-text-#-hover */
    /* xs - stateful.top-utility-link-button-hover */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a:active {
    /* xs - stateful.top-utility-anchor-text-#-active */
    /* xs - stateful.top-utility-link-button-active */
  }

  .banner {
    /* xs - home | locator.banner-bg */
  }

  .category-hero {
    /* xs - tag.category-hero-location */
    /* xs - tag.category-hero-bg-1 */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
  }

  .category-hero .container {
    /* xs - tag.category-hero-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container h3 {
    /* xs - tag.category-hero-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 27px;
    font-weight: normal;
  }

  .category-hero .container ul .btn {
    /* xs - tag.category-hero-anchor-text-# important */
    /* xs - tag.category-hero-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    background: #007ac4;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container ul .btn:hover {
    /* xs - stateful.category-hero-anchor-text-hover important */
    /* xs - stateful.category-hero-button-hover */
  }

  .category-hero .container ul .btn:active {
    /* xs - stateful.category-hero-anchor-text-active important */
    /* xs - stateful.category-hero-button-active */
  }

  div.not-found {
    /* xs - 404.404-text */
    /* xs - 404.404-bg-2 */
    /* xs - 404.404-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  div.not-found h1 {
    /* xs - 404.404-header */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 26px;
    line-height: 30px;
    font-weight: normal;
  }

  div.not-found a {
    /* xs - stateful.404-text-link */
  }

  div.not-found a:hover {
    /* xs - stateful.404-text-hover */
  }

  div.not-found a:active {
    /* xs - stateful.404-text-active */
  }

  div.not-found .container {
    /* xs - 404.404-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .header-sm {
    /* xs - home | locator.header-bg-1 */
  }

  .header {
    /* xs - home | locator.header-bg-1 */
    /* xs - home | locator.header-cta-bg-1 */
  }

  .header .container {
    /* xs - home | locator.header-bg-2 */
  }

  .header-sm .container {
    /* xs - home | locator.header-bg-2 */
  }

  .header-sm h1 {
    /* xs - home | locator.header-h1 */
  }

  .header h1,
  .header .col-md-8 h1 {
    /* xs - home | locator.header-h1 */
  }

  .header p,
  .header .col-md-8 p {
    /* md - home | locator.header-text-# */
  }

  .header .col-md-8 p a {
    /* md - stateful.header-text-#-link */
  }

  .header .col-md-8 p a:hover {
    /* md - stateful.header-text-#-hover */
  }

  .header .col-md-8 p a:active {
    /* md - stateful.header-text-#-active */
  }

  .header .cta {
    /* xs - home | locator.header-cta-title */
    /* xs - home | locator.header-cta-bg-2 height */
    background: #cc0000;
    min-height: 144px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .header .cta strong {
    /* xs - home | locator.header-cta-title */
  }

  .header .cta p {
    /* xs - home | locator.header-cta-text */
    /* xs - home | locator.header-cta-content-1 */
  }

  .header .cta a {
    /* xs - stateful.header-cta-text-link */
  }

  .header .cta a:hover {
    /* xs - stateful.header-cta-text-hover */
  }

  .header .cta a:active {
    /* xs - stateful.header-cta-text-active */
  }

  .header .cta .btn {
    /* xs - home | locator.header-cta-anchor-text important */
    /* xs - home | locator.header-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #007ac4;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .header .cta .btn:hover {
    /* xs - stateful.header-cta-anchor-text-hover important */
    /* xs - stateful.header-cta-button-hover */
  }

  .header .cta .btn:active {
    /* xs - stateful.header-cta-anchor-text-active important */
    /* xs - stateful.header-cta-button-active */
  }

  .heading-more-information .more-information .collapsible-title {
    /* xs - home | locator.content-drawer-text */
  }

  h2 > a.dropdown-toggle {
    /* xs - home | locator.xs-drawer-bg */
    /* xs - home | locator.content-drawer-bg */
    background: #007ac4;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  h2 > a.dropdown-toggle:active,
  h2 > a.dropdown-toggle:hover {
    /* xs - stateful.content-drawer-text-active */
    /* xs - stateful.content-drawer-bg-active */
  }

  .container > h2 > a.dropdown-toggle {
    /* xs - home | locator.content-drawer-text */
    /* xs - home | locator.xs-drawer-bg */
    /* xs - home | locator.links-drawer-bg */
    background: #007ac4;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .container > h2 > a.dropdown-toggle:hover,
  .container > h2 > a.dropdown-toggle:active {
    /* xs - stateful.links-?-title-active */
    /* xs - stateful.links-?-drawer-bg-active */
  }

  .locator-drawer {
    /* xs - tag.locator-bg-1 */
    /* xs - tag.list-bg-1 */
  }

  .locator-drawer .container {
    /* xs - tag.list-bg */
    /* xs - tag.list-bg-2 */
  }

  .locator-drawer .container h2 > a.dropdown-toggle {
    /* xs - tag.locator-drawer-text */
    /* xs - tag.locator-drawer-bg */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover {
    /* xs - stateful.locator-drawer-text-show-hover */
    /* xs - stateful.locator-drawer-bg-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active {
    /* xs - stateful.locator-drawer-text-show-active */
    /* xs - stateful.locator-drawer-bg-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle .caret {
    /* xs - tag.locator-drawer-arrow */
  }

  .locator {
    /* xs - home | locator.locator-bg-1 */
    /* sm - locality [city].locator-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .locator .container {
    /* xs - home | locator.locator-bg-2 */
    /* sm - locality [city].locator-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .geolocate-input {
    /* xs - home | locator.locator-start-box */
  }

  #start-geolocate .start-label {
    /* xs - home | locator.locator-start-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
  }

  #start-geolocate .location-holder .btn {
    /* xs - home | locator.locator-start-locate-anchor-text important */
    /* xs - home | locator.locator-start-locate-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .location-holder .btn:hover {
    /* xs - stateful.locator-start-locate-anchor-text-hover important */
    /* xs - stateful.locator-start-locate-button-hover */
  }

  #start-geolocate .location-holder .btn:active {
    /* xs - stateful.locator-start-locate-anchor-text-active important */
    /* xs - stateful.locator-start-locate-button-active */
  }

  #start-geolocate .geolocate-error-wrapper {
    /* xs - messages.locator-error-text */
    /* xs - messages.locator-error-bg */
  }

  #start-geocoder {
    /* xs - home | locator.locator-start-divider */
  }

  #start-geocoder input {
    /* xs - home | locator.locator-start-search-field */
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geocoder input::placeholder {
    /* xs - home | locator.locator-start-search-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  #start-geocoder .geocoder-pin-far-right .btn-search {
    /* xs - home | locator.locator-start-search-icon color */
    /* xs - home | locator.locator-start-search-button */
    color: #ffffff;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:hover {
    /* xs - stateful.locator-start-search-button-hover */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:hover::before {
    /* xs - stateful.locator-start-search-icon-hover */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:active {
    /* xs - stateful.locator-start-search-button-active */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:active::before {
    /* xs - stateful.locator-start-search-icon-active */
  }

  #location-list {
    /* xs - locality [city].locator-sm-xs-bg-1 */
    /* xs - locality [city].list-location-bg-inactive */
  }

  #location-list.error {
    /* xs - messages.locator-sm-xs-bg-1 */
  }

  #location-list p.no-locations {
    /* xs - messages.list-error-text */
    /* xs - messages.list-error-bg */
  }

  #location-list .telephone a:hover {
    /* xs - stateful.location-detail-phone-number-hover */
  }

  #location-list .telephone a:active {
    /* xs - stateful.location-detail-phone-number-active */
  }

  #list-panel .place {
    /* xs - locality [city].list-location-address */
    /* xs - locality [city].locator-sm-xs-bg-2 */
    /* md - locality [city].list-location-bg-inactive */
    /* md - locality [city].list-location-hr border */
  }

  #list-panel .place.active {
    /* xs - locality [city].list-location-bg-active */
  }

  #list-panel .place.active:hover {
    /* md - locality [city].list-location-city-state-zip */
    /* md - locality [city].list-location-bg-active */
    /* md - locality [city].list-location-hr border */
  }

  #list-panel .place:hover {
    /* md - locality [city].list-location-city-state-zip */
    /* md - locality [city].list-location-bg-active */
    /* md - locality [city].list-location-hr border */
  }

  #list-panel .place strong.name a {
    /* xs - locality [city].list-location-name */
    /* xs - locality [city].location-name */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
  }

  #list-panel .place strong.name a:hover {
    /* xs - stateful.location-name-hover */
  }

  #list-panel .place strong.name a:active {
    /* xs - stateful.location-name-active */
  }

  #list-panel .place .hours {
    /* xs - locality [city].list-location-hours-today-hours */
    /* xs - locality [city].location-hours-today */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
  }

  #list-panel .place .hours dt {
    /* xs - locality [city].list-location-hours-today */
  }

  #list-panel .place .hours dd a {
    /* xs - stateful.list-location-special-hours-today */
  }

  #list-panel .place .hours dd a:hover {
    /* xs - stateful.list-location-special-hours-today-hover */
  }

  #list-panel .place .hours dd a:active {
    /* xs - stateful.list-location-special-hours-today-active */
  }

  #list-panel .place .btn {
    /* xs - locality [city].list-location-cta-anchor-text important */
    /* xs - locality [city].list-location-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #list-panel .place .btn:hover {
    /* xs - stateful.list-location-cta-anchor-text-hover important */
    /* xs - stateful.list-location-cta-button-hover */
  }

  #list-panel .place .btn:active {
    /* xs - stateful.list-location-cta-anchor-text-active important */
    /* xs - stateful.list-location-cta-button-active */
  }

  #location-list a {
    /* xs - locality [city].list-location-secondary-anchor-text */
  }

  #location-list a.list-location-phone-number {
    /* xs - locality [city].list-location-phone-number */
  }

  #location-list a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #location-list a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  #list-panel .icons a {
    /* xs - locality [city].locator-icon-map-anchor-text */
    /* xs - locality [city].xs-locator-icon-anchor-text-# */
  }

  #list-panel .icons a:hover {
    /* xs - stateful.xs-locator-icon-anchor-text-#-hover */
  }

  #list-panel .icons a:active {
    /* xs - stateful.xs-locator-icon-anchor-text-#-active */
  }

  #list-panel .pager {
    /* xs - locality [city].locator-pager-text-bg */
    /* xs - locality [city].locator-pager-left-bg radius */
  }

  #list-panel .pager li.col-xs-6 {
    /* xs - locality [city].locator-pager-text */
  }

  #list-panel .pager a {
    /* xs - locality [city].locator-pager-left height */
    /* xs - locality [city].locator-pager-left-bg height */
  }

  #list-panel .pager a:hover {
    /* xs - stateful.location-cta-anchor-text-hover */
    /* xs - stateful.locator-pager-left-bg-hover */
  }

  #list-panel .pager a:active {
    /* xs - stateful.location-cta-anchor-text-active */
    /* xs - stateful.locator-pager-left-bg-active */
  }

  #list-panel .pager .disabled a {
    /* xs - stateful.locator-pager-left-disabled */
    /* xs - stateful.locator-pager-left-bg-disabled */
  }

  #list-panel .place .links a {
    /* md - locality [city].list-location-anchor-text-# */
  }

  #list-panel .place .links a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #list-panel .place .links a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  #list-panel .place .secondary a {
    /* xs - locality [city].list-location-secondary-anchor-text */
  }

  #list-panel .place .secondary a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #list-panel .place .secondary a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  .sk-circle .sk-child::before {
    /* xs - messages.loading-animation-color background */
  }

  #map-panel-holder .mapboxgl-popup-content {
    /* xs - locality [city].tooltip-location-address */
    /* xs - locality [city].tooltip-bubble */
  }

  #map-panel-holder .mapboxgl-popup-content a {
    /* xs - locality [city].tooltip-location-name */
  }

  #map-panel-holder .mapboxgl-popup-content a:hover {
    /* xs - stateful.tooltip-location-name-hover */
  }

  #map-panel-holder .mapboxgl-popup-content a:active {
    /* xs - stateful.tooltip-location-name-active */
  }

  #map-panel-holder .mapboxgl-popup-content dt {
    /* xs - locality [city].tooltip-location-hours-today */
  }

  #map .mapboxgl-popup-close-button::before {
    /* xs - locality [city].tooltip-close */
  }

  #map .mapboxgl-popup-close-button:hover::before {
    /* xs - stateful.tooltip-close-hover */
  }

  #map .mapboxgl-popup-close-button:active::before {
    /* xs - stateful.tooltip-close-active */
  }

  #location-search {
    /* xs - locality [city].locator-search-bg height */
    /* xs - locality - map shown.locator-search-bg */
  }

  #location-search .mapboxgl-ctrl-geocoder input {
    /* xs - locality [city].locator-search-field */
    /* xs - locality - map shown.locator-search-field */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #location-search .mapboxgl-ctrl-geocoder input::placeholder {
    /* xs - locality [city].locator-search-placeholder-text */
    /* xs - locality - map shown.locator-search-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  #location-search .btn-search {
    /* xs - locality [city].locator-search-button height */
    /* xs - locality - map shown.locator-search-button height */
    /* xs - locality [city].locator-search-icon color */
    /* xs - locality - map shown.locator-search-icon color */
    background: #cc0000;
    min-height: 50px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    color: #ffffff;
  }

  #location-search .btn-search:hover {
    /* xs - stateful.locator-start-search-icon-hover important */
    /* xs - stateful.locator-start-search-button-hover */
  }

  #location-search .btn-search:active {
    /* xs - stateful.locator-start-search-icon-active important */
    /* xs - stateful.locator-start-search-button-active */
  }

  body > .location {
    /* xs - location.locator-sm-xs-bg-1 */
    /* xs - location.locator-bg-1 */
  }

  body > .location .container {
    /* xs - location.locator-sm-xs-bg-2 */
  }

  body > .location .container > .row > .col-md-5 {
    /* xs - location.locator-sm-xs-bg-2 */
  }

  body > .location .location-detail {
    /* xs - location.location-detail-address */
    /* xs - location.location-detail-bg height */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }

  body > .location .location-detail .additional-info {
    /* xs - location.location-detail-hr */
  }

  body > .location .location-detail .btn {
    /* xs - locality [city].list-location-cta-anchor-text important */
    /* xs - locality [city].list-location-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  body > .location .location-detail .btn:hover {
    /* xs - stateful.location-cta-anchor-text-hover important */
    /* xs - stateful.location-cta-button-hover */
  }

  body > .location .location-detail .btn:active {
    /* xs - stateful.location-cta-anchor-text-active important */
    /* xs - stateful.location-cta-button-active */
  }

  body > .location .location-detail strong.name {
    /* xs - location.location-detail-name */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
  }

  body > .location .stars .star-top {
    /* xs - location.rating-stars color */
  }

  body > .location .stars .star-bottom {
    /* xs - location.rating-stars color */
  }

  body > .location a {
    /* xs - location.location-detail-anchor-text-# */
  }

  body > .location a:hover {
    /* xs - stateful.location-detail-anchor-text-#-hover */
  }

  body > .location a:active {
    /* xs - stateful.location-detail-anchor-text-#-active */
  }

  body > .location .telephone a {
    /* xs - location.location-detail-phone-number */
  }

  body > .location .telephone a:hover {
    /* xs - stateful.location-detail-phone-number-hover */
  }

  body > .location .telephone:active a:active {
    /* xs - stateful.location-detail-phone-number-active */
  }

  body > .location .location-detail .telephone a::before {
    /* xs - location.location-detail-phone-number color */
  }

  body > .location .location-detail .telephone a:hover::before {
    /* xs - stateful.location-detail-phone-number-hover color */
  }

  body > .location .location-detail .telephone a:active::before {
    /* xs - stateful.location-detail-phone-number-active color */
  }

  body > .location .location-detail .hours dt,
  body > .location .location-detail .hours dd {
    /* xs - location.location-detail-hours */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }

  body > .location .location-detail .hours .special::after,
  body > .location .location-detail .special-hours .special::before {
    /* xs - location.location-detail-modified-hours color */
  }

  body > .location .location-detail .special-hours strong {
    /* xs - location.location-detail-modified-hours-header */
  }

  body > .location .location-detail .special-hours dt,
  body > .location .location-detail .special-hours dd {
    /* xs - location.location-detail-modified-hours */
  }

  body > .location .icons a {
    /* xs - location.locator-icon-map-anchor-text */
    /* xs - location.xs-locator-icon-anchor-text-# */
  }

  body > .location .icons a:hover {
    /* xs - stateful.xs-locator-icon-anchor-text-#-hover */
  }

  body > .location .icons a:active {
    /* xs - stateful.xs-locator-icon-anchor-text-#-active */
  }

  .events {
    /* xs - location.events-text-#-# */
    /* xs - location.events-bg-1 */
  }

  .events .container {
    /* xs - location.events-bg-2 */
  }

  .events .container > h2 {
    /* xs - location.events-title-# */
  }

  .events p a {
    /* xs - stateful.events-text-#-link */
  }

  .events p a:hover {
    /* xs - stateful.events-text-#-hover */
  }

  .events p a:active {
    /* xs - stateful.events-text-#-active */
  }

  #events .event {
    /* xs - location.event-text */
    /* xs - location.event-bg */
  }

  #events .event h3,
  #events .event strong {
    /* xs - location.event-price */
  }

  #events .event .event-info > a,
  #events .event h3 > a {
    /* xs - location.event-title */
  }

  #events .event .event-info > a:hover,
  #events .event .event-info > h3 a:hover {
    /* xs - stateful.event-title-hover */
  }

  #events .event .event-info > a:active,
  #events .event .event-info > h3 a:active {
    /* xs - stateful.event-title-active */
  }

  #events .event-performer-info a {
    /* xs - stateful.event-person-link */
  }

  #events .event-performer-info a:hover {
    /* xs - stateful.event-person-hover */
  }

  #events .event-performer-info a:active {
    /* xs - stateful.event-person-active */
  }

  .events .btn {
    /* xs - location.event-cta-anchor-text important */
    /* xs - location.event-cta-button */
  }

  .events .btn:hover {
    /* xs - stateful.event-cta-anchor-text-hover important */
    /* xs - stateful.event-cta-button-hover */
  }

  .events .btn:active {
    /* xs - stateful.event-cta-anchor-text-active important */
    /* xs - stateful.event-cta-button-active */
  }

  .show-more .events-show-more {
    /* xs - location.events-view-more */
    /* xs - location.events-view-more-bg */
  }

  .show-more .events-show-more:hover {
    /* xs - stateful.events-view-more-hover */
    /* xs - stateful.events-view-more-bg-hover */
  }

  .show-more .events-show-more:active {
    /* xs - stateful.events-view-more-active */
    /* xs - stateful.events-view-more-bg-active */
  }

  .show-more .events-show-more i.fa {
    /* xs - location.events-view-more-arrow */
  }

  .show-more .events-show-more:hover i.fa {
    /* xs - stateful.events-view-more-hover */
  }

  .show-more .events-show-more:active i.fa {
    /* xs - stateful.events-view-more-active */
  }

  .offers-grid {
    /* xs - home | locator.site-offers-bg-1 */
    /* xs - location.site-offers-bg-2 */
    /* sm - location.location-offers-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container {
    /* xs - home | locator.site-offers-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .location-offers-grid .container {
    /* xs - location.location-offers-bg-2 */
  }

  .offers-grid .container h2 {
    /* xs - home | locator.site-offers-title */
    /* xs - location.location-offers-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 27px;
    font-weight: normal;
  }

  .offers-grid .container p {
    /* xs - home | locator.site-offers-text */
    /* xs - location.location-offers-text */
  }

  .offers-grid .container p a {
    /* xs - stateful.site-offers-text-link */
  }

  .offers-grid .container p a:hover {
    /* xs - stateful.site-offers-text-hover */
  }

  .offers-grid .container p a:active {
    /* xs - stateful.site-offers-text-active */
  }

  .offers-grid .container .offers-grid-item {
    /* xs - home | locator.site-offer-bg */
    /* xs - location.location-offer-bg */
    background: #f2f2f2;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-title {
    /* xs - home | locator.site-offer-title */
    /* xs - location.location-offer-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  }

  .offers-grid .container .offers-grid-item .offer-info a:hover .offer-title {
    /* xs - stateful.site-offer-title-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info a:active .offer-title {
    /* xs - stateful.site-offer-title-link-active */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-text {
    /* xs - home | locator.site-offer-text */
    /* xs - location.location-offer-text */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-price a {
    /* xs - home | locator.site-offer-price */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button {
    /* xs - home | locator.site-offer-cta-anchor-text important */
    /* xs - home | locator.site-offer-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button:hover {
    /* xs - stateful.site-offer-cta-anchor-text-hover important */
    /* xs - stateful.site-offer-cta-button-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button:active {
    /* xs - stateful.site-offer-cta-anchor-text-active important */
    /* xs - stateful.site-offer-cta-button-active */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-expires {
    /* xs - home | locator.site-offer-expires */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action {
    /* xs - home | locator.site-offer-secondary-action */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action:hover {
    /* xs - stateful.site-offer-secondary-action-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action:active {
    /* xs - stateful.site-offer-secondary-action-active */
  }

  .offers-grid .container .offers-grid-item.image-only {
    /* xs - home | locator.site-offers-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offer-more {
    /* xs - home | locator.site-offers-view-more-bg */
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offer-more .offer-view-more {
    /* xs - home | locator.site-offers-view-more */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Open Sans;
    font-size: 16px;
    line-height: 15px;
    font-weight: bold;
  }

  .offers-grid .container .offer-more .offer-view-more:hover {
    /* xs - stateful.site-offers-view-more-hover */
    /* xs - stateful.site-offers-view-more-bg-hover */
  }

  .offers-grid .container .offer-more .offer-view-more:active {
    /* xs - stateful.site-offers-view-more-active */
    /* xs - stateful.site-offers-view-more-bg-active */
  }

  .offers-grid .container .offer-more .offer-view-more i.fa {
    /* xs - home | locator.site-offers-view-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .offers-grid .container .offer-more .offer-view-more:hover i.fa {
    /* xs - stateful.site-offers-view-more-arrow-hover */
  }

  .offers-grid .container .offer-more .offer-view-more:active i.fa {
    /* xs - stateful.site-offers-view-more-arrow-active */
  }

  .offers-grid .container .panel {
    /* xs - messages.site-offers-no-offers */
    /* xs - messages.site-offers-no-offers-bg */
  }

  .reviews {
    /* xs - location.reviews-bg-1 */
  }

  .reviews .container {
    /* xs - location.reviews-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .container > h2 {
    /* xs - location.reviews-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 21px;
    line-height: 24px;
    font-weight: normal;
  }

  .reviews .container > p {
    /* xs - location.reviews-text */
  }

  .reviews p a {
    /* xs - stateful.reviews-text-link */
  }

  .reviews p a:hover {
    /* xs - stateful.reviews-text-hover */
  }

  .reviews p a:active {
    /* xs - stateful.reviews-text-active */
  }

  .reviews .panel {
    /* xs - location.rating-statistics */
    /* xs - location.rating-bg */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    background: #eeeeee;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .panel .panel-heading .panel-title {
    /* xs - location.rating-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
  }

  .reviews .btn {
    /* xs - location.review-submit-anchor-text important */
    /* xs - location.review-submit-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .btn:hover {
    /* xs - stateful.review-submit-anchor-text-hover important */
    /* xs - stateful.review-submit-button-hover */
  }

  .reviews .btn:active {
    /* xs - stateful.review-submit-anchor-text-active important */
    /* xs - stateful.review-submit-button-active */
  }

  .reviews #reviews .panel {
    /* xs - location.review-bg */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews #reviews .panel .panel-heading .panel-title {
    /* xs - location.review-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }

  .reviews #reviews .panel .panel-heading p {
    /* xs - location.review-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .reviews .panel .panel-body.no-reviews {
    /* xs - messages.reviews-no-reviews */
    /* xs - messages.reviews-no-reviews-bg */
  }

  .reviews #reviews hr {
    /* sm - location.review-divider-line */
  }

  .reviews .reviews-show-more {
    /* xs - location.reviews-view-more */
    /* xs - location.reviews-view-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .reviews-show-more:hover {
    /* xs - stateful.reviews-view-more-hover */
    /* xs - stateful.reviews-view-more-bg-hover */
  }

  .reviews .reviews-show-more:active {
    /* xs - stateful.reviews-view-more-active */
    /* xs - stateful.reviews-view-more-bg-active */
  }

  .reviews .reviews-show-more i.fa {
    /* xs - location.reviews-view-more-arrow */
  }

  .reviews .aggregate .stars .star-top,
  .reviews .aggregate .stars .star-bottom {
    /* xs - location.rating-stars color */
  }

  .reviews #reviews .stars .star-top,
  .reviews #reviews .stars .star-bottom {
    /* xs - location.review-stars */
  }

  #content .content {
    /* xs - home | locator.content-bg-2 */
    /* sm - home | locator.content-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .content .container {
    /* sm - home | locator.content-bg-2 */
  }

  .content .container p {
    /* xs - home | locator.content-text-#-# */
  }

  .content .container p a {
    /* xs - stateful.content-text-#-#-link */
  }

  .content .container p a:hover {
    /* xs - stateful.content-text-#-#-hover */
  }

  .content .container p a:active {
    /* xs - stateful.content-text-#-#-active */
  }

  .content .container h1,
  .content .container h2 {
    /* xs - home | locator.content-title-#-# */
    /* xs - home | locator.content-title-# */
  }

  .content .container .btn-danger {
    /* xs - home | locator.content-cta-anchor-text-# important */
    /* xs - home | locator.content-cta-button */
  }

  .content .container .btn-danger:hover {
    /* xs - stateful.content-cta-anchor-text-#-hover important */
    /* xs - stateful.content-cta-button-hover */
  }

  .content .container .btn-danger:active {
    /* xs - stateful.content-cta-anchor-text-#-active important */
    /* xs - stateful.content-cta-button-active */
  }

  #content .legal {
    /* xs - home | locator.content-legal-bg-1 */
  }

  #content .legal .container {
    /* xs - home | locator.content-legal-bg-2 */
  }

  #content .legal .container .content-legal-text {
    /* xs - home | locator.content-legal-text-# */
    /* xs - home | locator.content-legal-hr border */
  }

  #content .legal .container .content-legal-text a {
    /* xs - stateful.content-legal-text-#-link */
  }

  #content .legal .container .content-legal-text a:hover {
    /* xs - stateful.content-legal-text-#-hover */
  }

  #content .legal .container .content-legal-text a:active {
    /* xs - stateful.content-legal-text-#-active */
  }

  .body-cta {
    /* xs - home | locator.body-cta-bg-1 */
  }

  .body-cta .container {
    /* xs - home | locator.body-cta-bg-2 */
    background: transparent;
    border-width: 0px;
  }

  .body-cta h2 {
    /* xs - home | locator.body-cta-title */
  }

  .body-cta p {
    /* xs - home | locator.body-cta-text */
  }

  .body-cta p a {
    /* xs - stateful.body-cta-text-link */
  }

  .body-cta p a:hover {
    /* xs - stateful.body-cta-text-hover */
  }

  .body-cta p a:active {
    /* xs - stateful.body-cta-text-active */
  }

  .body-cta .btn-danger {
    /* xs - home | locator.body-cta-anchor-text important */
    /* xs - home | locator.body-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #007ac4;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .body-cta .btn-danger:hover {
    /* xs - stateful.body-cta-anchor-text-hover */
    /* xs - stateful.body-cta-button-hover */
  }

  .body-cta .btn-danger:active {
    /* xs - stateful.body-cta-anchor-text-active */
    /* xs - stateful.body-cta-button-active */
  }

  .categories {
    /* xs - home | locator.categories-bg-1 */
  }

  .categories .container {
    /* xs - home | locator.categories-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container h2 {
    /* xs - home | locator.categories-title-3x */
    /* xs - home | locator.categories-title-4x */
    color: #000000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 21px;
    line-height: 24px;
    font-weight: normal;
  }

  .categories .container p {
    /* xs - home | locator.categories-text */
    color: #000000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
  }

  .categories .container p a {
    /* xs - stateful.categories-text-link */
  }

  .categories .container p a:hover {
    /* xs - stateful.categories-text-hover */
  }

  .categories .container p a:active {
    /* xs - stateful.categories-text-active */
  }

  .categories .container .categories-grid.grid-4 .category-item a > span,
  .categories .container .categories-grid .category-item a > span {
    /* xs - home | locator.category-name */
    /* xs - home | locator.category-name-bg */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container .categories-grid .category-item a:hover > span {
    /* xs - stateful.category-name-hover */
    /* xs - stateful.category-name-bg-hover */
  }

  .categories .container .categories-grid .category-item a:active > span {
    /* xs - stateful.category-name-active */
    /* xs - stateful.category-name-bg-active */
  }

  .secondary-content {
    /* xs - home | locator.secondary-content-text-#-# */
    /* xs - home | locator.content-bg-1 */
    /* xs - locality [city].secondary-content-text-#-# */
    /* xs - locality [city].content-bg-2 */
    /* xs - locality [city].content-bg-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content-drawer .more-information .collapsible-title {
    /* xs - home | locator.secondary-content-drawer-text */
    /* xs - locality [city].secondary-content-drawer-text */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
  }

  .secondary-content .container {
    /* xs - home | locator.content-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content h2 {
    /* xs - locality [city].secondary-content-title-# */
    /* xs - home | locator.secondary-content-title-# */
  }

  .secondary-content p {
    /* xs - locality [city].secondary-content-text-#-# */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
  }

  .secondary-content p a {
    /* xs - stateful.secondary-content-text-#-#-link */
  }

  .secondary-content p a:hover {
    /* xs - stateful.secondary-content-text-#-#-hover */
  }

  .secondary-content p a:active {
    /* xs - stateful.secondary-content-text-#-#-active */
  }

  .secondary-content .btn-danger {
    /* xs - home | locator.secondary-content-cta-anchor-text-# important */
    /* xs - home | locator.secondary-content-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content .btn-danger:hover {
    /* xs - stateful.secondary-content-cta-anchor-text-hover important */
    /* xs - stateful.secondary-content-cta-button-hover */
  }

  .secondary-content .btn-danger:active {
    /* xs - stateful.secondary-content-cta-anchor-text-active important */
    /* xs - stateful.secondary-content-cta-button-active */
  }

  .hasPOS,
  .availableFrom,
  .areaServed,
  .makesOffer,
  .containsPlace {
    /* xs - home | locator.links-bg-1 */
  }

  .hasPOS .container,
  .availableFrom .container,
  .areaServed .container,
  .makesOffer .container,
  .containsPlace .container {
    /* xs - home | locator.links-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .list-group-item a,
  .availableFrom .list-group-item a,
  .areaServed .list-group-item a,
  .makesOffer .list-group-item a,
  .containsPlace .list-group-item a {
    /* xs - home | locator.links-cities-anchor-text-1 */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 32px;
    font-weight: normal;
  }

  .hasPOS .list-group-item a:hover,
  .availableFrom .list-group-item a:hover,
  .areaServed .list-group-item a:hover,
  .makesOffer .list-group-item a:hover,
  .containsPlace .list-group-item a:hover {
    /* xs - stateful.links-?-hover */
  }

  .hasPOS .list-group-item a:active,
  .availableFrom .list-group-item a:active,
  .areaServed .list-group-item a:active,
  .makesOffer .list-group-item a:active,
  .containsPlace .list-group-item a:active {
    /* xs - stateful.links-?-active */
  }

  .hasPOS .location-nearby .location-nearby-name,
  .availableFrom .location-nearby .location-nearby-name {
    /* xs - locality [city].location-nearby-name */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-name:hover,
  .availableFrom .location-nearby .location-nearby-name:hover {
    /* xs - stateful.location-nearby-name-hover */
  }

  .hasPOS .location-nearby .location-nearby-name:active,
  .availableFrom .location-nearby .location-nearby-name:active {
    /* xs - stateful.location-nearby-name-active */
  }

  .hasPOS .location-nearby .location-nearby-address,
  .hasPOS .location-nearby .location-nearby-directions,
  .availableFrom .location-nearby .location-nearby-address,
  .availableFrom .location-nearby .location-nearby-directions {
    /* xs - locality [city].location-nearby-address */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-directions,
  .availableFrom .location-nearby .location-nearby-directions {
    /* xs - locality [city].location-nearby-directions */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-directions:hover,
  .availableFrom .location-nearby .location-nearby-directions:hover {
    /* xs - stateful.location-nearby-directions-hover */
  }

  .hasPOS .location-nearby .location-nearby-directions:active,
  .availableFrom .location-nearby .location-nearby-directions:active {
    /* xs - stateful.location-nearby-directions-active */
  }

  .hasPOS .location-nearby .location-nearby-phone-number,
  .availableFrom .location-nearby .location-nearby-phone-number {
    /* xs - locality [city].location-nearby-phone-number */
    color: #007ac4;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-phone-number:hover,
  .availableFrom .location-nearby .location-nearby-phone-number:hover {
    /* xs - stateful.location-nearby-phone-number-hover */
  }

  .hasPOS .location-nearby .location-nearby-phone-number:active,
  .availableFrom .location-nearby .location-nearby-phone-number:active {
    /* xs - stateful.location-nearby-phone-number-active */
  }

  .hasPOS .location-nearby .location-nearby-hours strong,
  .availableFrom .location-nearby .location-nearby-hours strong {
    /* xs - locality [city].location-nearby-hours-title */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-hours dt,
  .hasPOS .location-nearby .location-nearby-hours dd,
  .availableFrom .location-nearby .location-nearby-hours dt,
  .availableFrom .location-nearby .location-nearby-hours dd {
    /* xs - locality [city].location-nearby-hours */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .btn,
  .availableFrom .location-nearby .btn {
    /* xs - locality [city].location-nearby-cta-anchor-text important */
    /* xs - locality [city].location-nearby-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .location-nearby .btn:hover,
  .availableFrom .location-nearby .btn:hover {
    /* xs - stateful.location-nearby-cta-anchor-text-hover important */
    /* xs - stateful.location-nearby-cta-button-hover */
  }

  .hasPOS .location-nearby .btn:active,
  .availableFrom .location-nearby .btn:active {
    /* xs - stateful.location-nearby-cta-anchor-text-active important */
    /* xs - stateful.location-nearby-cta-button-active */
  }

  .availableFrom .links-locations-show-more {
    /* xs - locality [city].links-locations-show-more */
    /* xs - locality [city].links-locations-show-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .availableFrom .links-locations-show-more .fa {
    /* xs - locality [city].links-locations-show-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .availableFrom .links-locations-show-more:hover {
    /* xs - stateful.links-locations-show-more-hover important */
    /* xs - stateful.links-locations-show-more-bg-hover */
  }

  .availableFrom .links-locations-show-more:active {
    /* xs - stateful.links-locations-show-more-active important */
    /* xs - stateful.links-locations-show-more-bg-active */
  }

  .hasPOS .links-locations-show-more {
    /* xs - location.links-locations-show-more */
    /* xs - location.links-locations-show-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .links-locations-show-more .caret {
    /* xs - location.links-locations-show-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .hasPOS .links-locations-show-more:hover {
    /* xs - stateful.links-locations-show-more-hover important */
    /* xs - stateful.links-locations-show-more-bg-hover */
  }

  .hasPOS .links-locations-show-more:active {
    /* xs - stateful.links-locations-show-more-active important */
    /* xs - stateful.links-locations-show-more-bg-active */
  }

  .email-signup {
    /* xs - home | locator.email-signup-text */
    /* xs - home | locator.email-signup-bg-1 */
    color: #333333;
    background: #ffffff;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }

  .email-signup .container {
    /* xs - home | locator.email-signup-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel {
    /* xs - home | locator.email-signup-box */
    background: #f2f2f2;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel a {
    /* xs - stateful.email-signup-text-link */
  }

  .email-signup .panel a:hover {
    /* xs - stateful.email-signup-text-hover */
  }

  .email-signup .panel a:active {
    /* xs - stateful.email-signup-text-active */
  }

  .email-signup .panel .panel-heading h3 {
    /* xs - home | locator.email-signup-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  }

  .email-signup .panel .panel-body form input {
    /* xs - home | locator.email-signup-text */
    /* xs - home | locator.email-signup-field */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel .panel-body form input::placeholder {
    /* xs - home | locator.email-signup-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  .email-signup .panel .panel-body form .btn,
  .email-signup .panel .panel-body .form .btn {
    /* xs - home | locator.email-signup-anchor-text important */
    /* xs - home | locator.email-signup-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel .panel-body form .btn:hover,
  .email-signup .panel .panel-body .form .btn:hover {
    /* xs - stateful.email-signup-anchor-text-hover important */
    /* xs - stateful.email-signup-button-hover */
  }

  .email-signup .panel .panel-body form .btn:active,
  .email-signup .panel .panel-body .form .btn:active {
    /* xs - stateful.email-signup-anchor-text-active important */
    /* xs - stateful.email-signup-button-active */
  }

  .footer {
    /* xs - home | locator.footer-bg-1 */
  }

  .footer .container {
    /* xs - home | locator.footer-copyright */
    /* xs - home | locator.footer-bg-2 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .footer .social-text a {
    /* xs - stateful.footer-social-text-link */
  }

  .footer .social-text a:hover {
    /* xs - stateful.footer-social-text-hover */
  }

  .footer .social-text a:active {
    /* xs - stateful.footer-social-text-active */
  }

  .footer ol.breadcrumb > li,
  .footer ol.breadcrumb > li,
  .footer ol.breadcrumb > li.active {
    /* xs - home | locator.footer-breadcrumbs */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 17px;
    font-weight: normal;
  }

  .footer ol.breadcrumb > li a {
    /* xs - home | locator.footer-breadcrumbs-x */
  }

  .footer ol.breadcrumb > li a:hover {
    /* xs - stateful.footer-breadcrumbs-hover */
  }

  .footer ol.breadcrumb > li a:active {
    /* xs - stateful.footer-breadcrumbs-active */
  }

  .footer ol.breadcrumb > li::after {
    /* xs - home | locator.footer-breadcrumbs-divider gradient-slash */
  }

  .footer .navbar-nav.social-text {
    /* xs - home | locator.footer-social-text */
  }

  .footer .navbar-nav.social-icons > li a {
    /* xs - home | locator.footer-social-facebook */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 40px;
    line-height: 40px;
    font-weight: normal;
  }

  .footer .navbar-nav.social-icons > li a:hover {
    /* xs - stateful.footer-social-#-hover */
  }

  .footer .navbar-nav.social-icons > li a:active {
    /* xs - stateful.footer-social-#-active */
  }

  .footer .nav > li a {
    /* xs - home | locator.footer-menu-anchor-text-# */
  }

  .footer .menu .navbar-nav > li a:hover {
    /* xs - stateful.footer-menu-anchor-text-#-hover */
  }

  .footer .menu .navbar-nav > li a:active {
    /* xs - stateful.footer-menu-anchor-text-#-active */
  }

  .footer .nav > li.divider {
    /* xs - home | locator.footer-menu-divider gradient-line */
  }

  .footer hr {
    /* xs - home | locator.footer-social-hr */
    /* xs - home | locator.footer-social-divider */
  }

  .footer .columns {
    /* xs - home | locator.footer-menu-hr */
  }

  .footer .columns .navbar-nav strong {
    /* xs - home | locator.footer-menu-header-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
  }

  .footer .columns .navbar-nav > li a {
    /* xs - home | locator.footer-menu-anchor-text-1-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 15px;
    font-weight: normal;
  }

  .footer .columns .navbar-nav > li a:hover {
    /* xs - stateful.footer-menu-anchor-text-#-#-hover */
  }

  .footer .columns .navbar-nav > li a:active {
    /* xs - stateful.footer-menu-anchor-text-#-#-active */
  }

  .footer .menu ul > li::after,
  .footer ul.disclaimers > li::after {
    /* xs - home | locator.footer-disclaimers-divider gradient-line */
  }

  .footer .disclaimers > li a {
    /* xs - home | locator.footer-disclaimers-anchor-text-# */
    /* xs - home | locator.footer-disclaimers-anchor-text-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
  }

  .footer .disclaimers > li a:hover {
    /* xs - stateful.footer-disclaimers-anchor-text-#-hover */
  }

  .footer .disclaimers > li a:active {
    /* xs - stateful.footer-disclaimers-anchor-text-#-active */
  }

  .footer .navbar-text > p {
    /* xs - home | locator.footer-legal */
  }

  .footer .navbar-text.legal {
    /* xs - home | locator.footer-copyright */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
  }

  .footer .navbar-text.legal a {
    /* xs - stateful.footer-copyright-link */
  }

  .footer .navbar-text.legal a:hover {
    /* xs - stateful.footer-copyright-hover */
  }

  .footer .navbar-text.legal a:active {
    /* xs - stateful.footer-copyright-active */
  }

  .footer .navbar-text.legal p a {
    /* xs - stateful.footer-legal-link */
  }

  .footer .navbar-text.legal p a:hover {
    /* xs - stateful.footer-legal-hover */
  }

  .footer .navbar-text.legal p a:active {
    /* xs - stateful.footer-legal-active */
  }

  .team .container {
    /* xs - location.team-bg-2 */
  }



  .team .container .team-more .team-view-more {
    /* xs - stateful.team-view-more */
    /* xs - stateful.team-view-more-bg */
  }

  .team .container .team-more .team-view-more:hover {
    /* xs - stateful.team-view-more-hover */
    /* xs - stateful.team-view-more-bg-hover */
  }

  .team .container .team-more .team-view-more:active {
    /* xs - stateful.team-view-more-active */
    /* xs - stateful.team-view-more-bg-active */
  }

  .team .container .team-more .team-view-more i.fa {
    /* xs - stateful.team-view-more-arrow */
  }

  .team .container .team-more .team-view-more:hover i.fa {
    /* xs - stateful.team-view-more-arrow-hover */
  }

  .team .container .team-more .team-view-more:active i.fa {
    /* xs - stateful.team-view-more-arrow-active */
  }

  .team .container .team-title {
    /* xs - location.team-title-# */
  }

  .team .container .team-text {
    /* xs - location.team-text-#-# */
  }

  .team .container .team-grid .team-item {
    /* xs - location.team-member-bg */
  }

  .team .container .team-grid .team-item .team-picture {
    /* xs - location.team-member-image */
  }

  .team .container .team-grid .team-item .team-name {
    /* xs - location.team-member-name */
  }

  .team .container .team-grid .team-item .team-job-title {
    /* xs - location.team-member-title */
  }

  .team .container .team-grid .team-item .team-work-location {
    /* xs - location.team-member-location */
  }

  .team .container .team-grid .team-item .team-description {
    /* xs - location.team-member-description */
  }

  .team .container .team-grid .team-item .team-telephone {
    /* xs - location.team-member-additional-info */
  }
}

@media (min-width: 768px) {
  h2 > a.dropdown-toggle {
    /* xs - home | locator.links-cities-title */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
  }

  .navbar-collapse.in .menus,
  .navbar.collapsing .menus {
    /* xs - stateful.menu-dropdown-top-bg */
  }



  .dropdown-toggle:hover .caret {
    /* sm - stateful.links-?-arrow-hover */
  }

  .dropdown-toggle:active .caret {
    /* sm - stateful.links-?-arrow-active */
  }

  .container > h2 > a.dropdown-toggle:hover {
    /* sm - stateful.links-?-title-hover */
  }

  .container > h2 > a.dropdown-toggle:active {
    /* sm - stateful.links-?-title-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover {
    /* sm - stateful.locator-drawer-text-hover */
    /* sm - stateful.list-bg-2-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover .caret {
    /* sm - stateful.locator-drawer-arrow-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active {
    /* sm - stateful.locator-drawer-text-active */
    /* sm - stateful.list-bg-2-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active .caret {
    /* sm - stateful.locator-drawer-arrow-active */
  }

  .team {
    /* sm - location.team-bg-1 */
  }
}

@media (min-width: 992px) {
  .top .navbar-right p.utility a {
    /* md - stateful.top-text-link */
  }

  .top .navbar-right p.utility a:hover {
    /* md - stateful.top-text-hover */
  }

  .top .navbar-right p.utility a:active {
    /* md - stateful.top-text-active */
  }

  .top .navbar-right .navbar-nav.links > li > a:hover {
    /* md - stateful.top-anchor-text-#-hover */
  }

  .top .navbar-right .navbar-nav.links > li > a:active {
    /* md - stateful.top-anchor-text-#-active */
  }

  .top .navbar-right .navbar-nav.utility > li > a:hover {
    /* md - stateful.top-utility-anchor-text-#-hover */
  }

  .top .navbar-right .navbar-nav.utility > li > a:active {
    /* md - stateful.top-utility-anchor-text-#-active */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a {
    /* md - stateful.submenu-anchor-text-#-# */
    /* md - stateful.submenu-button */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:hover {
    /* md - stateful.submenu-anchor-text-#-#-hover */
    /* md - stateful.submenu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:active {
    /* md - stateful.submenu-anchor-text-#-#-active */
    /* md - stateful.submenu-button-active */
  }
}

@media (min-width: 768px) {
  body > .alert a {
    /* sm - home | locator.alert-text */
    /* sm - home | locator.alert-bg-1 */
    /* xs - stateful.alert-text */
    /* xs - stateful.alert-bg-1 */
  }

  body > .alert a:hover {
    /* xs - stateful.alert-text-hover */
    /* xs - stateful.alert-bg-1-hover */
  }

  body > .alert a:active {
    /* xs - stateful.alert-text-active */
    /* xs - stateful.alert-bg-1-active */
  }

  .dropdown-toggle .caret {
    /* sm - home | locator.links-cities-arrow */
  }

  .navbar-collapse .dropdown-toggle .caret {
    /* xs - stateful.menu-arrow */
  }

  .navbar-collapse .dropdown-toggle:hover .caret {
    /* xs - stateful.menu-arrow-hover */
  }

  .navbar-collapse .dropdown-toggle:active .caret {
    /* xs - stateful.menu-arrow-active */
  }

  .navbar-collapse ul.navbar-nav li > .text-center {
    /* xs - stateful.menu-dropdown-top-bg */
  }

  .navbar-collapse ul.navbar-nav li.close > a {
    /* xs - stateful.menu-dropdown-close-bg */
  }

  .navbar-collapse ul.navbar-nav li.close:hover {
    /* xs - stateful.menu-dropdown-close-bg-hover */
  }

  #menu > div > div > ul > li.close.hidden-md.hidden-lg > a:hover {
    /* xs - stateful.menu-dropdown-close-bg-hover */
  }

  #menu > div > div > ul > li.close.hidden-md.hidden-lg > a:active {
    /* xs - stateful.menu-dropdown-close-bg-active */
  }

  .navbar-collapse ul.navbar-nav li.close > a .fa {
    /* xs - stateful.menu-dropdown-close-x */
  }

  .navbar-collapse ul.navbar-nav li.close > a:hover .fa {
    /* xs - stateful.menu-dropdown-close-x-hover */
  }

  .navbar-collapse ul.navbar-nav li.close > a:active .fa {
    /* xs - stateful.menu-dropdown-close-x-active */
  }

  .navbar-collapse .navbar-text {
    /* xs - stateful.top-text */
    /* xs - stateful.menu-bg */
  }

  .navbar-collapse .navbar-text a {
    /* xs - stateful.top-text-link */
  }

  .navbar-collapse .navbar-text a:hover {
    /* xs - stateful.top-text-hover */
  }

  .navbar-collapse .navbar-text a:active {
    /* xs - stateful.top-text-active */
  }

  .top {
    /* sm - home | locator.top-bg-2 */
    /* sm - home | locator.top-bg-1 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #3e464b;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .container {
    /* sm - home | locator.top-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .container.phone {
    /* sm - home | locator.xs-top-phone-bg */
  }

  .top .navbar-header.brands .divider {
    /* sm - home | locator.top-brand-divider */
  }

  .top a.phone {
    /* sm - home | locator.xs-top-phone-number */
    /* sm - home | locator.top-phone-number */
    /* sm - home | locator.xs-top-phone-bg */
    /* sm - home | locator.top-phone-bg */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .navbar-header.brands a.phone:hover,
  .top a.phone:hover {
    /* xs - stateful.xs-top-phone-button-hover */
    /* xs - stateful.xs-top-phone-number-hover */
    /* xs - stateful.top-phone-number-hover */
    /* xs - stateful.top-phone-bg-hover */
  }

  .top .navbar-header.brands a.phone:active,
  .top a.phone:active {
    /* xs - stateful.xs-top-phone-button-active */
    /* xs - stateful.xs-top-phone-number-active */
    /* xs - stateful.top-phone-number-active */
    /* xs - stateful.top-phone-bg-active */
  }

  .top .navbar-right .navbar-nav > li.divider {
    /* sm - home | locator.top-link-divider */
  }

  .top .navbar-right.phone::before {
    /* sm - home | locator.top-phone-divider */
  }

  .top .navbar-right .navbar-nav.utility > li > a {
    /* sm - home | locator.top-utility-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.links > li > a {
    /* sm - home | locator.top-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.links > li > a {
    /* sm - home | locator.top-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.utility > li.divider {
    /* sm - home | locator.top-utility-link-divider */
  }

  .top .navbar-right .navbar-nav.links > li.divider {
    /* sm - home | locator.top-link-divider */
  }

  .top .navbar-right .navbar-text {
    /* sm - home | locator.top-text */
  }

  #menu {
    /* sm - home | locator.menu-bg-1 */
  }

  .navbar .navbar-toggle {
    /* sm - home | locator.menu-hamburger-bg */
    /* xs - stateful.menu-hamburger-bg */
  }

  .navbar .navbar-toggle:hover {
    /* xs - stateful.menu-hamburger-bg-hover */
  }

  .navbar .navbar-toggle:active {
    /* xs - stateful.menu-hamburger-bg-active */
  }

  .navbar .navbar-toggle:hover .icon-bar {
    /* xs - stateful.menu-hamburger-hover */
  }

  .navbar .navbar-toggle:active .icon-bar {
    /* xs - stateful.menu-hamburger-active */
  }

  .navbar .navbar-toggle .icon-bar {
    /* sm - home | locator.menu-hamburger */
    /* sm - home | locator.menu-hamburger-lines */
  }

  .navbar-collapse {
    /* xs - stateful.menu-dropdown-top-bg */
  }

  .navbar-collapse .container {
    /* sm - home | locator.menu-bg-2 */
  }

  .navbar-collapse ul.navbar-nav .open > a,
  .navbar-collapse ul.navbar-nav > li > a {
    /* xs - stateful.menu-anchor-text-# */
    /* xs - stateful.menu-button */
    /* sm - home | locator.menu-anchor-text-1 */
    /* sm - home | locator.menu-button height */
  }

  .navbar-collapse ul.navbar-nav .open > a:hover,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:hover {
    /* xs - stateful.menu-anchor-text-#-hover */
    /* xs - stateful.menu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open > a:focus,
  .navbar-collapse ul.navbar-nav .open > a:active,
  .navbar-collapse ul.navbar-nav .open > a:hover:focus,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus:hover,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:active,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus {
    /* xs - stateful.menu-anchor-text-#-active */
    /* xs - stateful.menu-button-active */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a {
    /* xs - stateful.submenu-anchor-text-#-# */
    /* xs - stateful.submenu-button */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:hover {
    /* xs - stateful.submenu-anchor-text-#-#-hover */
    /* xs - stateful.submenu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:active {
    /* xs - stateful.submenu-anchor-text-#-#-active */
    /* xs - stateful.submenu-button-active */
  }

  .navbar-collapse ul.navbar-nav.links > li > a {
    /* xs - stateful.top-anchor-text-# */
    /* xs - stateful.top-link-button */
  }

  .navbar-collapse ul.navbar-nav.links > li > a:hover {
    /* xs - stateful.top-anchor-text-#-hover */
    /* xs - stateful.top-link-button-hover */
  }

  .navbar-collapse ul.navbar-nav.links > li > a:active {
    /* xs - stateful.top-anchor-text-#-active */
    /* xs - stateful.top-link-button-active */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a {
    /* xs - stateful.top-utility-anchor-text-# */
    /* xs - stateful.top-utility-link-button */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a:hover {
    /* xs - stateful.top-utility-anchor-text-#-hover */
    /* xs - stateful.top-utility-link-button-hover */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a:active {
    /* xs - stateful.top-utility-anchor-text-#-active */
    /* xs - stateful.top-utility-link-button-active */
  }

  .banner {
    /* sm - home | locator.banner-bg */
  }

  .category-hero {
    /* sm - tag.category-hero-location */
    /* sm - tag.category-hero-bg-1 */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container {
    /* sm - tag.category-hero-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container h3 {
    /* sm - tag.category-hero-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 30px;
    font-weight: normal;
  }

  .category-hero .container ul .btn {
    /* sm - tag.category-hero-anchor-text-# important */
    /* sm - tag.category-hero-button */
    background: #007ac4;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container ul .btn:hover {
    /* xs - stateful.category-hero-anchor-text-hover important */
    /* xs - stateful.category-hero-button-hover */
  }

  .category-hero .container ul .btn:active {
    /* xs - stateful.category-hero-anchor-text-active important */
    /* xs - stateful.category-hero-button-active */
  }

  div.not-found {
    /* sm - 404.404-text */
    /* sm - 404.404-bg-2 */
    /* sm - 404.404-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  div.not-found h1 {
    /* sm - 404.404-header */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 32px;
    line-height: 30px;
    font-weight: normal;
  }

  div.not-found a {
    /* xs - stateful.404-text-link */
  }

  div.not-found a:hover {
    /* xs - stateful.404-text-hover */
  }

  div.not-found a:active {
    /* xs - stateful.404-text-active */
  }

  div.not-found .container {
    /* sm - 404.404-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .header-sm {
    /* sm - home | locator.header-bg-1 */
  }

  .header {
    /* sm - home | locator.header-bg-1 */
    /* sm - home | locator.header-cta-bg-1 */
  }

  .header .container {
    /* sm - home | locator.header-bg-2 */
  }

  .header-sm .container {
    /* sm - home | locator.header-bg-2 */
  }

  .header-sm h1 {
    /* sm - home | locator.header-h1 */
  }

  .header h1,
  .header .col-md-8 h1 {
    /* sm - home | locator.header-h1 */
  }

  .header p,
  .header .col-md-8 p {
    /* md - home | locator.header-text-# */
  }

  .header .col-md-8 p a {
    /* md - stateful.header-text-#-link */
  }

  .header .col-md-8 p a:hover {
    /* md - stateful.header-text-#-hover */
  }

  .header .col-md-8 p a:active {
    /* md - stateful.header-text-#-active */
  }

  .header .cta {
    /* sm - home | locator.header-cta-title */
    /* sm - home | locator.header-cta-bg-2 height */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 30px;
    line-height: 34px;
    font-weight: normal;
    background: #cc0000;
    min-height: 164px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .header .cta strong {
    /* sm - home | locator.header-cta-title */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 30px;
    line-height: 34px;
    font-weight: normal;
  }

  .header .cta p {
    /* sm - home | locator.header-cta-text */
    /* sm - home | locator.header-cta-content-1 */
  }

  .header .cta a {
    /* xs - stateful.header-cta-text-link */
  }

  .header .cta a:hover {
    /* xs - stateful.header-cta-text-hover */
  }

  .header .cta a:active {
    /* xs - stateful.header-cta-text-active */
  }

  .header .cta .btn {
    /* sm - home | locator.header-cta-anchor-text important */
    /* sm - home | locator.header-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #007ac4;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .header .cta .btn:hover {
    /* xs - stateful.header-cta-anchor-text-hover important */
    /* xs - stateful.header-cta-button-hover */
  }

  .header .cta .btn:active {
    /* xs - stateful.header-cta-anchor-text-active important */
    /* xs - stateful.header-cta-button-active */
  }

  .heading-more-information .more-information .collapsible-title {
    /* sm - home | locator.content-drawer-text */
  }

  h2 > a.dropdown-toggle {
    /* sm - home | locator.xs-drawer-bg */
    /* sm - home | locator.content-drawer-bg */
  }

  h2 > a.dropdown-toggle:active,
  h2 > a.dropdown-toggle:hover {
    /* xs - stateful.content-drawer-text-active */
    /* xs - stateful.content-drawer-bg-active */
  }

  .container > h2 > a.dropdown-toggle {
    /* sm - home | locator.content-drawer-text */
    /* sm - home | locator.xs-drawer-bg */
    /* sm - home | locator.links-drawer-bg */
  }

  .container > h2 > a.dropdown-toggle:hover,
  .container > h2 > a.dropdown-toggle:active {
    /* xs - stateful.links-?-title-active */
    /* xs - stateful.links-?-drawer-bg-active */
  }

  .locator-drawer {
    /* sm - tag.locator-bg-1 */
    /* sm - tag.list-bg-1 */
  }

  .locator-drawer .container {
    /* sm - tag.list-bg */
    /* sm - tag.list-bg-2 */
  }

  .locator-drawer .container h2 > a.dropdown-toggle {
    /* sm - tag.locator-drawer-text */
    /* sm - tag.locator-drawer-bg */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover {
    /* xs - stateful.locator-drawer-text-show-hover */
    /* xs - stateful.locator-drawer-bg-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active {
    /* xs - stateful.locator-drawer-text-show-active */
    /* xs - stateful.locator-drawer-bg-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle .caret {
    /* sm - tag.locator-drawer-arrow */
  }

  .locator {
    /* sm - home | locator.locator-bg-1 */
    /* sm - locality [city].locator-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .locator .container {
    /* sm - home | locator.locator-bg-2 */
    /* sm - locality [city].locator-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .geolocate-input {
    /* sm - home | locator.locator-start-box */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .start-label {
    /* sm - home | locator.locator-start-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
  }

  #start-geolocate .location-holder .btn {
    /* sm - home | locator.locator-start-locate-anchor-text important */
    /* sm - home | locator.locator-start-locate-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .location-holder .btn:hover {
    /* xs - stateful.locator-start-locate-anchor-text-hover important */
    /* xs - stateful.locator-start-locate-button-hover */
  }

  #start-geolocate .location-holder .btn:active {
    /* xs - stateful.locator-start-locate-anchor-text-active important */
    /* xs - stateful.locator-start-locate-button-active */
  }

  #start-geolocate .geolocate-error-wrapper {
    /* xs - messages.locator-error-text */
    /* xs - messages.locator-error-bg */
  }

  #start-geocoder {
    /* sm - home | locator.locator-start-divider */
    background: transparent;
    border-color: #bbbbbb;
    border-width: 2px;
  }

  #start-geocoder input {
    /* sm - home | locator.locator-start-search-field */
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geocoder input::placeholder {
    /* sm - home | locator.locator-start-search-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  #start-geocoder .geocoder-pin-far-right .btn-search {
    /* sm - home | locator.locator-start-search-icon color */
    /* sm - home | locator.locator-start-search-button */
    color: #ffffff;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:hover {
    /* xs - stateful.locator-start-search-button-hover */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:hover::before {
    /* xs - stateful.locator-start-search-icon-hover */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:active {
    /* xs - stateful.locator-start-search-button-active */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:active::before {
    /* xs - stateful.locator-start-search-icon-active */
  }

  #location-list {
    /* sm - locality [city].locator-sm-xs-bg-1 */
    /* sm - locality [city].list-location-bg-inactive */
  }

  #location-list.error {
    /* xs - messages.locator-sm-xs-bg-1 */
  }

  #location-list p.no-locations {
    /* xs - messages.list-error-text */
    /* xs - messages.list-error-bg */
  }

  #location-list .telephone a:hover {
    /* xs - stateful.location-detail-phone-number-hover */
  }

  #location-list .telephone a:active {
    /* xs - stateful.location-detail-phone-number-active */
  }

  #list-panel .place {
    /* sm - locality [city].list-location-address */
    /* sm - locality [city].locator-sm-xs-bg-2 */
    /* md - locality [city].list-location-bg-inactive */
    /* md - locality [city].list-location-hr border */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
  }

  #list-panel .place.active {
    /* sm - locality [city].list-location-bg-active */
  }

  #list-panel .place.active:hover {
    /* md - locality [city].list-location-city-state-zip */
    /* md - locality [city].list-location-bg-active */
    /* md - locality [city].list-location-hr border */
  }

  #list-panel .place:hover {
    /* md - locality [city].list-location-city-state-zip */
    /* md - locality [city].list-location-bg-active */
    /* md - locality [city].list-location-hr border */
  }

  #list-panel .place strong.name a {
    /* sm - locality [city].list-location-name */
    /* sm - locality [city].location-name */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
  }

  #list-panel .place strong.name a:hover {
    /* xs - stateful.location-name-hover */
  }

  #list-panel .place strong.name a:active {
    /* xs - stateful.location-name-active */
  }

  #list-panel .place .hours {
    /* sm - locality [city].list-location-hours-today-hours */
    /* sm - locality [city].location-hours-today */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
  }

  #list-panel .place .hours dt {
    /* sm - locality [city].list-location-hours-today */
  }

  #list-panel .place .hours dd a {
    /* xs - stateful.list-location-special-hours-today */
  }

  #list-panel .place .hours dd a:hover {
    /* xs - stateful.list-location-special-hours-today-hover */
  }

  #list-panel .place .hours dd a:active {
    /* xs - stateful.list-location-special-hours-today-active */
  }

  #list-panel .place .btn {
    /* sm - locality [city].list-location-cta-anchor-text important */
    /* sm - locality [city].list-location-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #list-panel .place .btn:hover {
    /* xs - stateful.list-location-cta-anchor-text-hover important */
    /* xs - stateful.list-location-cta-button-hover */
  }

  #list-panel .place .btn:active {
    /* xs - stateful.list-location-cta-anchor-text-active important */
    /* xs - stateful.list-location-cta-button-active */
  }

  #location-list a {
    /* sm - locality [city].list-location-secondary-anchor-text */
  }

  #location-list a.list-location-phone-number {
    /* sm - locality [city].list-location-phone-number */
  }

  #location-list a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #location-list a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  #list-panel .icons a {
    /* sm - locality [city].locator-icon-map-anchor-text */
    /* sm - locality [city].xs-locator-icon-anchor-text-# */
  }

  #list-panel .icons a:hover {
    /* xs - stateful.xs-locator-icon-anchor-text-#-hover */
  }

  #list-panel .icons a:active {
    /* xs - stateful.xs-locator-icon-anchor-text-#-active */
  }

  #list-panel .pager {
    /* sm - locality [city].locator-pager-text-bg */
    /* sm - locality [city].locator-pager-left-bg radius */
  }

  #list-panel .pager li.col-xs-6 {
    /* sm - locality [city].locator-pager-text */
  }

  #list-panel .pager a {
    /* sm - locality [city].locator-pager-left height */
    /* sm - locality [city].locator-pager-left-bg height */
  }

  #list-panel .pager a:hover {
    /* xs - stateful.location-cta-anchor-text-hover */
    /* xs - stateful.locator-pager-left-bg-hover */
  }

  #list-panel .pager a:active {
    /* xs - stateful.location-cta-anchor-text-active */
    /* xs - stateful.locator-pager-left-bg-active */
  }

  #list-panel .pager .disabled a {
    /* xs - stateful.locator-pager-left-disabled */
    /* xs - stateful.locator-pager-left-bg-disabled */
  }

  #list-panel .place .links a {
    /* md - locality [city].list-location-anchor-text-# */
  }

  #list-panel .place .links a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #list-panel .place .links a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  #list-panel .place .secondary a {
    /* sm - locality [city].list-location-secondary-anchor-text */
  }

  #list-panel .place .secondary a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #list-panel .place .secondary a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  .sk-circle .sk-child::before {
    /* xs - messages.loading-animation-color background */
  }

  #map-panel-holder .mapboxgl-popup-content {
    /* sm - locality [city].tooltip-location-address */
    /* sm - locality [city].tooltip-bubble */
  }

  #map-panel-holder .mapboxgl-popup-content a {
    /* sm - locality [city].tooltip-location-name */
  }

  #map-panel-holder .mapboxgl-popup-content a:hover {
    /* xs - stateful.tooltip-location-name-hover */
  }

  #map-panel-holder .mapboxgl-popup-content a:active {
    /* xs - stateful.tooltip-location-name-active */
  }

  #map-panel-holder .mapboxgl-popup-content dt {
    /* sm - locality [city].tooltip-location-hours-today */
  }

  #map .mapboxgl-popup-close-button::before {
    /* sm - locality [city].tooltip-close */
  }

  #map .mapboxgl-popup-close-button:hover::before {
    /* xs - stateful.tooltip-close-hover */
  }

  #map .mapboxgl-popup-close-button:active::before {
    /* xs - stateful.tooltip-close-active */
  }

  #location-search {
    /* sm - locality [city].locator-search-bg height */
    /* sm - locality - map shown.locator-search-bg */
  }

  #location-search .mapboxgl-ctrl-geocoder input {
    /* sm - locality [city].locator-search-field */
    /* sm - locality - map shown.locator-search-field */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #location-search .mapboxgl-ctrl-geocoder input::placeholder {
    /* sm - locality [city].locator-search-placeholder-text */
    /* sm - locality - map shown.locator-search-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  #location-search .btn-search {
    /* sm - locality [city].locator-search-button height */
    /* sm - locality - map shown.locator-search-button height */
    /* sm - locality [city].locator-search-icon color */
    /* sm - locality - map shown.locator-search-icon color */
    background: #cc0000;
    min-height: 50px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    color: #ffffff;
  }

  #location-search .btn-search:hover {
    /* xs - stateful.locator-start-search-icon-hover important */
    /* xs - stateful.locator-start-search-button-hover */
  }

  #location-search .btn-search:active {
    /* xs - stateful.locator-start-search-icon-active important */
    /* xs - stateful.locator-start-search-button-active */
  }

  body > .location {
    /* sm - location.locator-sm-xs-bg-1 */
    /* sm - location.locator-bg-1 */
  }

  body > .location .container {
    /* sm - location.locator-sm-xs-bg-2 */
  }

  body > .location .container > .row > .col-md-5 {
    /* sm - location.locator-sm-xs-bg-2 */
  }

  body > .location .location-detail {
    /* sm - location.location-detail-address */
    /* sm - location.location-detail-bg height */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }

  body > .location .location-detail .additional-info {
    /* sm - location.location-detail-hr */
  }

  body > .location .location-detail .btn {
    /* sm - locality [city].list-location-cta-anchor-text important */
    /* sm - locality [city].list-location-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  body > .location .location-detail .btn:hover {
    /* xs - stateful.location-cta-anchor-text-hover important */
    /* xs - stateful.location-cta-button-hover */
  }

  body > .location .location-detail .btn:active {
    /* xs - stateful.location-cta-anchor-text-active important */
    /* xs - stateful.location-cta-button-active */
  }

  body > .location .location-detail strong.name {
    /* sm - location.location-detail-name */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
  }

  body > .location .stars .star-top {
    /* sm - location.rating-stars color */
  }

  body > .location .stars .star-bottom {
    /* sm - location.rating-stars color */
  }

  body > .location a {
    /* sm - location.location-detail-anchor-text-# */
  }

  body > .location a:hover {
    /* xs - stateful.location-detail-anchor-text-#-hover */
  }

  body > .location a:active {
    /* xs - stateful.location-detail-anchor-text-#-active */
  }

  body > .location .telephone a {
    /* sm - location.location-detail-phone-number */
  }

  body > .location .telephone a:hover {
    /* xs - stateful.location-detail-phone-number-hover */
  }

  body > .location .telephone:active a:active {
    /* xs - stateful.location-detail-phone-number-active */
  }

  body > .location .location-detail .telephone a::before {
    /* sm - location.location-detail-phone-number color */
  }

  body > .location .location-detail .telephone a:hover::before {
    /* xs - stateful.location-detail-phone-number-hover color */
  }

  body > .location .location-detail .telephone a:active::before {
    /* xs - stateful.location-detail-phone-number-active color */
  }

  body > .location .location-detail .hours dt,
  body > .location .location-detail .hours dd {
    /* sm - location.location-detail-hours */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }

  body > .location .location-detail .hours .special::after,
  body > .location .location-detail .special-hours .special::before {
    /* sm - location.location-detail-modified-hours color */
  }

  body > .location .location-detail .special-hours strong {
    /* sm - location.location-detail-modified-hours-header */
  }

  body > .location .location-detail .special-hours dt,
  body > .location .location-detail .special-hours dd {
    /* sm - location.location-detail-modified-hours */
  }

  body > .location .icons a {
    /* sm - location.locator-icon-map-anchor-text */
    /* sm - location.xs-locator-icon-anchor-text-# */
  }

  body > .location .icons a:hover {
    /* xs - stateful.xs-locator-icon-anchor-text-#-hover */
  }

  body > .location .icons a:active {
    /* xs - stateful.xs-locator-icon-anchor-text-#-active */
  }

  .events {
    /* sm - location.events-text-#-# */
    /* sm - location.events-bg-1 */
  }

  .events .container {
    /* sm - location.events-bg-2 */
  }

  .events .container > h2 {
    /* sm - location.events-title-# */
  }

  .events p a {
    /* xs - stateful.events-text-#-link */
  }

  .events p a:hover {
    /* xs - stateful.events-text-#-hover */
  }

  .events p a:active {
    /* xs - stateful.events-text-#-active */
  }

  #events .event {
    /* sm - location.event-text */
    /* sm - location.event-bg */
  }

  #events .event h3,
  #events .event strong {
    /* sm - location.event-price */
  }

  #events .event .event-info > a,
  #events .event h3 > a {
    /* sm - location.event-title */
  }

  #events .event .event-info > a:hover,
  #events .event .event-info > h3 a:hover {
    /* xs - stateful.event-title-hover */
  }

  #events .event .event-info > a:active,
  #events .event .event-info > h3 a:active {
    /* xs - stateful.event-title-active */
  }

  #events .event-performer-info a {
    /* xs - stateful.event-person-link */
  }

  #events .event-performer-info a:hover {
    /* xs - stateful.event-person-hover */
  }

  #events .event-performer-info a:active {
    /* xs - stateful.event-person-active */
  }

  .events .btn {
    /* sm - location.event-cta-anchor-text important */
    /* sm - location.event-cta-button */
  }

  .events .btn:hover {
    /* xs - stateful.event-cta-anchor-text-hover important */
    /* xs - stateful.event-cta-button-hover */
  }

  .events .btn:active {
    /* xs - stateful.event-cta-anchor-text-active important */
    /* xs - stateful.event-cta-button-active */
  }

  .show-more .events-show-more {
    /* sm - location.events-view-more */
    /* sm - location.events-view-more-bg */
  }

  .show-more .events-show-more:hover {
    /* xs - stateful.events-view-more-hover */
    /* xs - stateful.events-view-more-bg-hover */
  }

  .show-more .events-show-more:active {
    /* xs - stateful.events-view-more-active */
    /* xs - stateful.events-view-more-bg-active */
  }

  .show-more .events-show-more i.fa {
    /* sm - location.events-view-more-arrow */
  }

  .show-more .events-show-more:hover i.fa {
    /* xs - stateful.events-view-more-hover */
  }

  .show-more .events-show-more:active i.fa {
    /* xs - stateful.events-view-more-active */
  }

  .offers-grid {
    /* sm - home | locator.site-offers-bg-1 */
    /* sm - location.site-offers-bg-2 */
    /* sm - location.location-offers-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container {
    /* sm - home | locator.site-offers-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .location-offers-grid .container {
    /* sm - location.location-offers-bg-2 */
  }

  .offers-grid .container h2 {
    /* sm - home | locator.site-offers-title */
    /* sm - location.location-offers-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 40px;
    line-height: 44px;
    font-weight: normal;
  }

  .offers-grid .container p {
    /* sm - home | locator.site-offers-text */
    /* sm - location.location-offers-text */
  }

  .offers-grid .container p a {
    /* xs - stateful.site-offers-text-link */
  }

  .offers-grid .container p a:hover {
    /* xs - stateful.site-offers-text-hover */
  }

  .offers-grid .container p a:active {
    /* xs - stateful.site-offers-text-active */
  }

  .offers-grid .container .offers-grid-item {
    /* sm - home | locator.site-offer-bg */
    /* sm - location.location-offer-bg */
    background: #f2f2f2;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-title {
    /* sm - home | locator.site-offer-title */
    /* sm - location.location-offer-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info a:hover .offer-title {
    /* xs - stateful.site-offer-title-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info a:active .offer-title {
    /* xs - stateful.site-offer-title-link-active */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-text {
    /* sm - home | locator.site-offer-text */
    /* sm - location.location-offer-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-price a {
    /* sm - home | locator.site-offer-price */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button {
    /* sm - home | locator.site-offer-cta-anchor-text important */
    /* sm - home | locator.site-offer-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button:hover {
    /* xs - stateful.site-offer-cta-anchor-text-hover important */
    /* xs - stateful.site-offer-cta-button-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button:active {
    /* xs - stateful.site-offer-cta-anchor-text-active important */
    /* xs - stateful.site-offer-cta-button-active */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-expires {
    /* sm - home | locator.site-offer-expires */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action {
    /* sm - home | locator.site-offer-secondary-action */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action:hover {
    /* xs - stateful.site-offer-secondary-action-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action:active {
    /* xs - stateful.site-offer-secondary-action-active */
  }

  .offers-grid .container .offers-grid-item.image-only {
    /* sm - home | locator.site-offers-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offer-more {
    /* sm - home | locator.site-offers-view-more-bg */
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offer-more .offer-view-more {
    /* sm - home | locator.site-offers-view-more */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
  }

  .offers-grid .container .offer-more .offer-view-more:hover {
    /* xs - stateful.site-offers-view-more-hover */
    /* xs - stateful.site-offers-view-more-bg-hover */
  }

  .offers-grid .container .offer-more .offer-view-more:active {
    /* xs - stateful.site-offers-view-more-active */
    /* xs - stateful.site-offers-view-more-bg-active */
  }

  .offers-grid .container .offer-more .offer-view-more i.fa {
    /* sm - home | locator.site-offers-view-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .offers-grid .container .offer-more .offer-view-more:hover i.fa {
    /* xs - stateful.site-offers-view-more-arrow-hover */
  }

  .offers-grid .container .offer-more .offer-view-more:active i.fa {
    /* xs - stateful.site-offers-view-more-arrow-active */
  }

  .offers-grid .container .panel {
    /* xs - messages.site-offers-no-offers */
    /* xs - messages.site-offers-no-offers-bg */
  }

  .reviews {
    /* sm - location.reviews-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .container {
    /* sm - location.reviews-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .container > h2 {
    /* sm - location.reviews-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 39px;
    font-weight: normal;
  }

  .reviews .container > p {
    /* sm - location.reviews-text */
  }

  .reviews p a {
    /* xs - stateful.reviews-text-link */
  }

  .reviews p a:hover {
    /* xs - stateful.reviews-text-hover */
  }

  .reviews p a:active {
    /* xs - stateful.reviews-text-active */
  }

  .reviews .panel {
    /* sm - location.rating-statistics */
    /* sm - location.rating-bg */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    background: #eeeeee;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .panel .panel-heading .panel-title {
    /* sm - location.rating-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 21px;
    line-height: 24px;
    font-weight: normal;
  }

  .reviews .btn {
    /* sm - location.review-submit-anchor-text important */
    /* sm - location.review-submit-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 16px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .btn:hover {
    /* xs - stateful.review-submit-anchor-text-hover important */
    /* xs - stateful.review-submit-button-hover */
  }

  .reviews .btn:active {
    /* xs - stateful.review-submit-anchor-text-active important */
    /* xs - stateful.review-submit-button-active */
  }

  .reviews #reviews .panel {
    /* sm - location.review-bg */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews #reviews .panel .panel-heading .panel-title {
    /* sm - location.review-title */
  }

  .reviews #reviews .panel .panel-heading p {
    /* sm - location.review-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }

  .reviews .panel .panel-body.no-reviews {
    /* xs - messages.reviews-no-reviews */
    /* xs - messages.reviews-no-reviews-bg */
  }

  .reviews #reviews hr {
    /* sm - location.review-divider-line */
    background: transparent;
    border-color: #979797;
    border-width: 1px;
  }

  .reviews .reviews-show-more {
    /* sm - location.reviews-view-more */
    /* sm - location.reviews-view-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .reviews-show-more:hover {
    /* xs - stateful.reviews-view-more-hover */
    /* xs - stateful.reviews-view-more-bg-hover */
  }

  .reviews .reviews-show-more:active {
    /* xs - stateful.reviews-view-more-active */
    /* xs - stateful.reviews-view-more-bg-active */
  }

  .reviews .reviews-show-more i.fa {
    /* sm - location.reviews-view-more-arrow */
  }

  .reviews .aggregate .stars .star-top,
  .reviews .aggregate .stars .star-bottom {
    /* sm - location.rating-stars color */
  }

  .reviews #reviews .stars .star-top,
  .reviews #reviews .stars .star-bottom {
    /* sm - location.review-stars */
  }

  #content .content {
    /* sm - home | locator.content-bg-2 */
    /* sm - home | locator.content-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .content .container {
    /* sm - home | locator.content-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .content .container p {
    /* sm - home | locator.content-text-#-# */
  }

  .content .container p a {
    /* xs - stateful.content-text-#-#-link */
  }

  .content .container p a:hover {
    /* xs - stateful.content-text-#-#-hover */
  }

  .content .container p a:active {
    /* xs - stateful.content-text-#-#-active */
  }

  .content .container h1,
  .content .container h2 {
    /* sm - home | locator.content-title-#-# */
    /* sm - home | locator.content-title-# */
  }

  .content .container .btn-danger {
    /* sm - home | locator.content-cta-anchor-text-# important */
    /* sm - home | locator.content-cta-button */
  }

  .content .container .btn-danger:hover {
    /* xs - stateful.content-cta-anchor-text-#-hover important */
    /* xs - stateful.content-cta-button-hover */
  }

  .content .container .btn-danger:active {
    /* xs - stateful.content-cta-anchor-text-#-active important */
    /* xs - stateful.content-cta-button-active */
  }

  #content .legal {
    /* sm - home | locator.content-legal-bg-1 */
  }

  #content .legal .container {
    /* sm - home | locator.content-legal-bg-2 */
  }

  #content .legal .container .content-legal-text {
    /* sm - home | locator.content-legal-text-# */
    /* sm - home | locator.content-legal-hr border */
  }

  #content .legal .container .content-legal-text a {
    /* xs - stateful.content-legal-text-#-link */
  }

  #content .legal .container .content-legal-text a:hover {
    /* xs - stateful.content-legal-text-#-hover */
  }

  #content .legal .container .content-legal-text a:active {
    /* xs - stateful.content-legal-text-#-active */
  }

  .body-cta {
    /* sm - home | locator.body-cta-bg-1 */
    background: transparent;
    border-width: 0px;
  }

  .body-cta .container {
    /* sm - home | locator.body-cta-bg-2 */
  }

  .body-cta h2 {
    /* sm - home | locator.body-cta-title */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 28px;
    line-height: 44px;
    font-weight: normal;
  }

  .body-cta p {
    /* sm - home | locator.body-cta-text */
  }

  .body-cta p a {
    /* xs - stateful.body-cta-text-link */
  }

  .body-cta p a:hover {
    /* xs - stateful.body-cta-text-hover */
  }

  .body-cta p a:active {
    /* xs - stateful.body-cta-text-active */
  }

  .body-cta .btn-danger {
    /* sm - home | locator.body-cta-anchor-text important */
    /* sm - home | locator.body-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #007ac4;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .body-cta .btn-danger:hover {
    /* xs - stateful.body-cta-anchor-text-hover */
    /* xs - stateful.body-cta-button-hover */
  }

  .body-cta .btn-danger:active {
    /* xs - stateful.body-cta-anchor-text-active */
    /* xs - stateful.body-cta-button-active */
  }

  .categories {
    /* sm - home | locator.categories-bg-1 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container {
    /* sm - home | locator.categories-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container h2 {
    /* sm - home | locator.categories-title-3x */
    /* sm - home | locator.categories-title-4x */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 39px;
    font-weight: normal;
  }

  .categories .container p {
    /* sm - home | locator.categories-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
  }

  .categories .container p a {
    /* xs - stateful.categories-text-link */
  }

  .categories .container p a:hover {
    /* xs - stateful.categories-text-hover */
  }

  .categories .container p a:active {
    /* xs - stateful.categories-text-active */
  }

  .categories .container .categories-grid.grid-4 .category-item a > span,
  .categories .container .categories-grid .category-item a > span {
    /* sm - home | locator.category-name */
    /* sm - home | locator.category-name-bg */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 15px;
    line-height: 18px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container .categories-grid .category-item a:hover > span {
    /* xs - stateful.category-name-hover */
    /* xs - stateful.category-name-bg-hover */
  }

  .categories .container .categories-grid .category-item a:active > span {
    /* xs - stateful.category-name-active */
    /* xs - stateful.category-name-bg-active */
  }

  .secondary-content {
    /* sm - home | locator.secondary-content-text-#-# */
    /* sm - home | locator.content-bg-1 */
    /* sm - locality [city].secondary-content-text-#-# */
    /* sm - locality [city].content-bg-2 */
    /* sm - locality [city].content-bg-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content-drawer .more-information .collapsible-title {
    /* sm - home | locator.secondary-content-drawer-text */
    /* sm - locality [city].secondary-content-drawer-text */
  }

  .secondary-content .container {
    /* sm - home | locator.content-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content h2 {
    /* sm - locality [city].secondary-content-title-# */
    /* sm - home | locator.secondary-content-title-# */
  }

  .secondary-content p {
    /* sm - locality [city].secondary-content-text-#-# */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
  }

  .secondary-content p a {
    /* xs - stateful.secondary-content-text-#-#-link */
  }

  .secondary-content p a:hover {
    /* xs - stateful.secondary-content-text-#-#-hover */
  }

  .secondary-content p a:active {
    /* xs - stateful.secondary-content-text-#-#-active */
  }

  .secondary-content .btn-danger {
    /* sm - home | locator.secondary-content-cta-anchor-text-# important */
    /* sm - home | locator.secondary-content-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content .btn-danger:hover {
    /* xs - stateful.secondary-content-cta-anchor-text-hover important */
    /* xs - stateful.secondary-content-cta-button-hover */
  }

  .secondary-content .btn-danger:active {
    /* xs - stateful.secondary-content-cta-anchor-text-active important */
    /* xs - stateful.secondary-content-cta-button-active */
  }

  .hasPOS,
  .availableFrom,
  .areaServed,
  .makesOffer,
  .containsPlace {
    /* sm - home | locator.links-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .container,
  .availableFrom .container,
  .areaServed .container,
  .makesOffer .container,
  .containsPlace .container {
    /* sm - home | locator.links-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .list-group-item a,
  .availableFrom .list-group-item a,
  .areaServed .list-group-item a,
  .makesOffer .list-group-item a,
  .containsPlace .list-group-item a {
    /* sm - home | locator.links-cities-anchor-text-1 */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 32px;
    font-weight: normal;
  }

  .hasPOS .list-group-item a:hover,
  .availableFrom .list-group-item a:hover,
  .areaServed .list-group-item a:hover,
  .makesOffer .list-group-item a:hover,
  .containsPlace .list-group-item a:hover {
    /* xs - stateful.links-?-hover */
  }

  .hasPOS .list-group-item a:active,
  .availableFrom .list-group-item a:active,
  .areaServed .list-group-item a:active,
  .makesOffer .list-group-item a:active,
  .containsPlace .list-group-item a:active {
    /* xs - stateful.links-?-active */
  }

  .hasPOS .location-nearby .location-nearby-name,
  .availableFrom .location-nearby .location-nearby-name {
    /* sm - locality [city].location-nearby-name */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-name:hover,
  .availableFrom .location-nearby .location-nearby-name:hover {
    /* xs - stateful.location-nearby-name-hover */
  }

  .hasPOS .location-nearby .location-nearby-name:active,
  .availableFrom .location-nearby .location-nearby-name:active {
    /* xs - stateful.location-nearby-name-active */
  }

  .hasPOS .location-nearby .location-nearby-address,
  .hasPOS .location-nearby .location-nearby-directions,
  .availableFrom .location-nearby .location-nearby-address,
  .availableFrom .location-nearby .location-nearby-directions {
    /* sm - locality [city].location-nearby-address */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-directions,
  .availableFrom .location-nearby .location-nearby-directions {
    /* sm - locality [city].location-nearby-directions */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-directions:hover,
  .availableFrom .location-nearby .location-nearby-directions:hover {
    /* xs - stateful.location-nearby-directions-hover */
  }

  .hasPOS .location-nearby .location-nearby-directions:active,
  .availableFrom .location-nearby .location-nearby-directions:active {
    /* xs - stateful.location-nearby-directions-active */
  }

  .hasPOS .location-nearby .location-nearby-phone-number,
  .availableFrom .location-nearby .location-nearby-phone-number {
    /* sm - locality [city].location-nearby-phone-number */
    color: #007ac4;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-phone-number:hover,
  .availableFrom .location-nearby .location-nearby-phone-number:hover {
    /* xs - stateful.location-nearby-phone-number-hover */
  }

  .hasPOS .location-nearby .location-nearby-phone-number:active,
  .availableFrom .location-nearby .location-nearby-phone-number:active {
    /* xs - stateful.location-nearby-phone-number-active */
  }

  .hasPOS .location-nearby .location-nearby-hours strong,
  .availableFrom .location-nearby .location-nearby-hours strong {
    /* sm - locality [city].location-nearby-hours-title */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-hours dt,
  .hasPOS .location-nearby .location-nearby-hours dd,
  .availableFrom .location-nearby .location-nearby-hours dt,
  .availableFrom .location-nearby .location-nearby-hours dd {
    /* sm - locality [city].location-nearby-hours */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .btn,
  .availableFrom .location-nearby .btn {
    /* sm - locality [city].location-nearby-cta-anchor-text important */
    /* sm - locality [city].location-nearby-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .location-nearby .btn:hover,
  .availableFrom .location-nearby .btn:hover {
    /* xs - stateful.location-nearby-cta-anchor-text-hover important */
    /* xs - stateful.location-nearby-cta-button-hover */
  }

  .hasPOS .location-nearby .btn:active,
  .availableFrom .location-nearby .btn:active {
    /* xs - stateful.location-nearby-cta-anchor-text-active important */
    /* xs - stateful.location-nearby-cta-button-active */
  }

  .availableFrom .links-locations-show-more {
    /* sm - locality [city].links-locations-show-more */
    /* sm - locality [city].links-locations-show-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .availableFrom .links-locations-show-more .fa {
    /* sm - locality [city].links-locations-show-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .availableFrom .links-locations-show-more:hover {
    /* xs - stateful.links-locations-show-more-hover important */
    /* xs - stateful.links-locations-show-more-bg-hover */
  }

  .availableFrom .links-locations-show-more:active {
    /* xs - stateful.links-locations-show-more-active important */
    /* xs - stateful.links-locations-show-more-bg-active */
  }

  .hasPOS .links-locations-show-more {
    /* sm - location.links-locations-show-more */
    /* sm - location.links-locations-show-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .links-locations-show-more .caret {
    /* sm - location.links-locations-show-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .hasPOS .links-locations-show-more:hover {
    /* xs - stateful.links-locations-show-more-hover important */
    /* xs - stateful.links-locations-show-more-bg-hover */
  }

  .hasPOS .links-locations-show-more:active {
    /* xs - stateful.links-locations-show-more-active important */
    /* xs - stateful.links-locations-show-more-bg-active */
  }

  .email-signup {
    /* sm - home | locator.email-signup-text */
    /* sm - home | locator.email-signup-bg-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .container {
    /* sm - home | locator.email-signup-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel {
    /* sm - home | locator.email-signup-box */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel a {
    /* xs - stateful.email-signup-text-link */
  }

  .email-signup .panel a:hover {
    /* xs - stateful.email-signup-text-hover */
  }

  .email-signup .panel a:active {
    /* xs - stateful.email-signup-text-active */
  }

  .email-signup .panel .panel-heading h3 {
    /* sm - home | locator.email-signup-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
  }

  .email-signup .panel .panel-body form input {
    /* sm - home | locator.email-signup-text */
    /* sm - home | locator.email-signup-field */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel .panel-body form input::placeholder {
    /* sm - home | locator.email-signup-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  .email-signup .panel .panel-body form .btn,
  .email-signup .panel .panel-body .form .btn {
    /* sm - home | locator.email-signup-anchor-text important */
    /* sm - home | locator.email-signup-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel .panel-body form .btn:hover,
  .email-signup .panel .panel-body .form .btn:hover {
    /* xs - stateful.email-signup-anchor-text-hover important */
    /* xs - stateful.email-signup-button-hover */
  }

  .email-signup .panel .panel-body form .btn:active,
  .email-signup .panel .panel-body .form .btn:active {
    /* xs - stateful.email-signup-anchor-text-active important */
    /* xs - stateful.email-signup-button-active */
  }

  .footer {
    /* sm - home | locator.footer-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .footer .container {
    /* sm - home | locator.footer-copyright */
    /* sm - home | locator.footer-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .footer .social-text a {
    /* xs - stateful.footer-social-text-link */
  }

  .footer .social-text a:hover {
    /* xs - stateful.footer-social-text-hover */
  }

  .footer .social-text a:active {
    /* xs - stateful.footer-social-text-active */
  }

  .footer ol.breadcrumb > li,
  .footer ol.breadcrumb > li,
  .footer ol.breadcrumb > li.active {
    /* sm - home | locator.footer-breadcrumbs */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
  }

  .footer ol.breadcrumb > li a {
    /* sm - home | locator.footer-breadcrumbs-x */
  }

  .footer ol.breadcrumb > li a:hover {
    /* xs - stateful.footer-breadcrumbs-hover */
  }

  .footer ol.breadcrumb > li a:active {
    /* xs - stateful.footer-breadcrumbs-active */
  }

  .footer ol.breadcrumb > li::after {
    /* sm - home | locator.footer-breadcrumbs-divider gradient-slash */
  }

  .footer .navbar-nav.social-text {
    /* sm - home | locator.footer-social-text */
  }

  .footer .navbar-nav.social-icons > li a {
    /* sm - home | locator.footer-social-facebook */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 40px;
    line-height: 40px;
    font-weight: normal;
  }

  .footer .navbar-nav.social-icons > li a:hover {
    /* xs - stateful.footer-social-#-hover */
  }

  .footer .navbar-nav.social-icons > li a:active {
    /* xs - stateful.footer-social-#-active */
  }

  .footer .nav > li a {
    /* sm - home | locator.footer-menu-anchor-text-# */
  }

  .footer .menu .navbar-nav > li a:hover {
    /* xs - stateful.footer-menu-anchor-text-#-hover */
  }

  .footer .menu .navbar-nav > li a:active {
    /* xs - stateful.footer-menu-anchor-text-#-active */
  }

  .footer .nav > li.divider {
    /* sm - home | locator.footer-menu-divider gradient-line */
  }

  .footer hr {
    /* sm - home | locator.footer-social-hr */
    /* sm - home | locator.footer-social-divider */
  }

  .footer .columns {
    /* sm - home | locator.footer-menu-hr */
  }

  .footer .columns .navbar-nav strong {
    /* sm - home | locator.footer-menu-header-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
  }

  .footer .columns .navbar-nav > li a {
    /* sm - home | locator.footer-menu-anchor-text-1-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 15px;
    font-weight: normal;
  }

  .footer .columns .navbar-nav > li a:hover {
    /* xs - stateful.footer-menu-anchor-text-#-#-hover */
  }

  .footer .columns .navbar-nav > li a:active {
    /* xs - stateful.footer-menu-anchor-text-#-#-active */
  }

  .footer .menu ul > li::after,
  .footer ul.disclaimers > li::after {
    /* sm - home | locator.footer-disclaimers-divider gradient-line */
  }

  .footer .disclaimers > li a {
    /* sm - home | locator.footer-disclaimers-anchor-text-# */
    /* sm - home | locator.footer-disclaimers-anchor-text-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
  }

  .footer .disclaimers > li a:hover {
    /* xs - stateful.footer-disclaimers-anchor-text-#-hover */
  }

  .footer .disclaimers > li a:active {
    /* xs - stateful.footer-disclaimers-anchor-text-#-active */
  }

  .footer .navbar-text > p {
    /* sm - home | locator.footer-legal */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
  }

  .footer .navbar-text.legal {
    /* sm - home | locator.footer-copyright */
  }

  .footer .navbar-text.legal a {
    /* xs - stateful.footer-copyright-link */
  }

  .footer .navbar-text.legal a:hover {
    /* xs - stateful.footer-copyright-hover */
  }

  .footer .navbar-text.legal a:active {
    /* xs - stateful.footer-copyright-active */
  }

  .footer .navbar-text.legal p a {
    /* xs - stateful.footer-legal-link */
  }

  .footer .navbar-text.legal p a:hover {
    /* xs - stateful.footer-legal-hover */
  }

  .footer .navbar-text.legal p a:active {
    /* xs - stateful.footer-legal-active */
  }

  .team .container {
    /* sm - location.team-bg-2 */
  }



  .team .container .team-more .team-view-more {
    /* xs - stateful.team-view-more */
    /* xs - stateful.team-view-more-bg */
  }

  .team .container .team-more .team-view-more:hover {
    /* xs - stateful.team-view-more-hover */
    /* xs - stateful.team-view-more-bg-hover */
  }

  .team .container .team-more .team-view-more:active {
    /* xs - stateful.team-view-more-active */
    /* xs - stateful.team-view-more-bg-active */
  }

  .team .container .team-more .team-view-more i.fa {
    /* xs - stateful.team-view-more-arrow */
  }

  .team .container .team-more .team-view-more:hover i.fa {
    /* xs - stateful.team-view-more-arrow-hover */
  }

  .team .container .team-more .team-view-more:active i.fa {
    /* xs - stateful.team-view-more-arrow-active */
  }

  .team .container .team-title {
    /* sm - location.team-title-# */
  }

  .team .container .team-text {
    /* sm - location.team-text-#-# */
  }

  .team .container .team-grid .team-item {
    /* sm - location.team-member-bg */
  }

  .team .container .team-grid .team-item .team-picture {
    /* sm - location.team-member-image */
  }

  .team .container .team-grid .team-item .team-name {
    /* sm - location.team-member-name */
  }

  .team .container .team-grid .team-item .team-job-title {
    /* sm - location.team-member-title */
  }

  .team .container .team-grid .team-item .team-work-location {
    /* sm - location.team-member-location */
  }

  .team .container .team-grid .team-item .team-description {
    /* sm - location.team-member-description */
  }

  .team .container .team-grid .team-item .team-telephone {
    /* sm - location.team-member-additional-info */
  }

  h2 > a.dropdown-toggle {
    /* sm - home | locator.links-cities-title */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 26px;
    line-height: 33px;
    font-weight: normal;
  }

  .navbar-collapse.in .menus,
  .navbar.collapsing .menus {
    /* xs - stateful.menu-dropdown-top-bg */
  }



  .dropdown-toggle:hover .caret {
    /* sm - stateful.links-?-arrow-hover */
  }

  .dropdown-toggle:active .caret {
    /* sm - stateful.links-?-arrow-active */
  }

  .container > h2 > a.dropdown-toggle:hover {
    /* sm - stateful.links-?-title-hover */
  }

  .container > h2 > a.dropdown-toggle:active {
    /* sm - stateful.links-?-title-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover {
    /* sm - stateful.locator-drawer-text-hover */
    /* sm - stateful.list-bg-2-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover .caret {
    /* sm - stateful.locator-drawer-arrow-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active {
    /* sm - stateful.locator-drawer-text-active */
    /* sm - stateful.list-bg-2-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active .caret {
    /* sm - stateful.locator-drawer-arrow-active */
  }

  .team {
    /* sm - location.team-bg-1 */
  }

  .top .navbar-right p.utility a {
    /* md - stateful.top-text-link */
  }

  .top .navbar-right p.utility a:hover {
    /* md - stateful.top-text-hover */
  }

  .top .navbar-right p.utility a:active {
    /* md - stateful.top-text-active */
  }

  .top .navbar-right .navbar-nav.links > li > a:hover {
    /* md - stateful.top-anchor-text-#-hover */
  }

  .top .navbar-right .navbar-nav.links > li > a:active {
    /* md - stateful.top-anchor-text-#-active */
  }

  .top .navbar-right .navbar-nav.utility > li > a:hover {
    /* md - stateful.top-utility-anchor-text-#-hover */
  }

  .top .navbar-right .navbar-nav.utility > li > a:active {
    /* md - stateful.top-utility-anchor-text-#-active */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a {
    /* md - stateful.submenu-anchor-text-#-# */
    /* md - stateful.submenu-button */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:hover {
    /* md - stateful.submenu-anchor-text-#-#-hover */
    /* md - stateful.submenu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:active {
    /* md - stateful.submenu-anchor-text-#-#-active */
    /* md - stateful.submenu-button-active */
  }
}

@media (min-width: 992px) {
  body > .alert a {
    /* md - home | locator.alert-text */
    /* md - home | locator.alert-bg-1 */
    /* xs - stateful.alert-text */
    /* xs - stateful.alert-bg-1 */
  }

  body > .alert a:hover {
    /* xs - stateful.alert-text-hover */
    /* xs - stateful.alert-bg-1-hover */
  }

  body > .alert a:active {
    /* xs - stateful.alert-text-active */
    /* xs - stateful.alert-bg-1-active */
  }

  .dropdown-toggle .caret {
    /* md - home | locator.links-cities-arrow */
  }

  .navbar-collapse .dropdown-toggle .caret {
    /* xs - stateful.menu-arrow */
  }

  .navbar-collapse .dropdown-toggle:hover .caret {
    /* xs - stateful.menu-arrow-hover */
  }

  .navbar-collapse .dropdown-toggle:active .caret {
    /* xs - stateful.menu-arrow-active */
  }

  .navbar-collapse ul.navbar-nav li > .text-center {
    /* xs - stateful.menu-dropdown-top-bg */
  }

  .navbar-collapse ul.navbar-nav li.close > a {
    /* xs - stateful.menu-dropdown-close-bg */
  }

  .navbar-collapse ul.navbar-nav li.close:hover {
    /* xs - stateful.menu-dropdown-close-bg-hover */
  }

  #menu > div > div > ul > li.close.hidden-md.hidden-lg > a:hover {
    /* xs - stateful.menu-dropdown-close-bg-hover */
  }

  #menu > div > div > ul > li.close.hidden-md.hidden-lg > a:active {
    /* xs - stateful.menu-dropdown-close-bg-active */
  }

  .navbar-collapse ul.navbar-nav li.close > a .fa {
    /* xs - stateful.menu-dropdown-close-x */
  }

  .navbar-collapse ul.navbar-nav li.close > a:hover .fa {
    /* xs - stateful.menu-dropdown-close-x-hover */
  }

  .navbar-collapse ul.navbar-nav li.close > a:active .fa {
    /* xs - stateful.menu-dropdown-close-x-active */
  }

  .navbar-collapse .navbar-text {
    /* xs - stateful.top-text */
    /* xs - stateful.menu-bg */
  }

  .navbar-collapse .navbar-text a {
    /* xs - stateful.top-text-link */
  }

  .navbar-collapse .navbar-text a:hover {
    /* xs - stateful.top-text-hover */
  }

  .navbar-collapse .navbar-text a:active {
    /* xs - stateful.top-text-active */
  }

  .top {
    /* md - home | locator.top-bg-2 */
    /* md - home | locator.top-bg-1 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .container {
    /* md - home | locator.top-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .container.phone {
    /* md - home | locator.xs-top-phone-bg */
  }

  .top .navbar-header.brands .divider {
    /* md - home | locator.top-brand-divider */
  }

  .top a.phone {
    /* md - home | locator.xs-top-phone-number */
    /* md - home | locator.top-phone-number */
    /* md - home | locator.xs-top-phone-bg */
    /* md - home | locator.top-phone-bg */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .navbar-header.brands a.phone:hover,
  .top a.phone:hover {
    /* xs - stateful.xs-top-phone-button-hover */
    /* xs - stateful.xs-top-phone-number-hover */
    /* xs - stateful.top-phone-number-hover */
    /* xs - stateful.top-phone-bg-hover */
  }

  .top .navbar-header.brands a.phone:active,
  .top a.phone:active {
    /* xs - stateful.xs-top-phone-button-active */
    /* xs - stateful.xs-top-phone-number-active */
    /* xs - stateful.top-phone-number-active */
    /* xs - stateful.top-phone-bg-active */
  }

  .top .navbar-right .navbar-nav > li.divider {
    /* md - home | locator.top-link-divider */
    background: transparent;
    border-color: #cccccc;
    border-width: 1px;
  }

  .top .navbar-right.phone::before {
    /* md - home | locator.top-phone-divider */
    background: transparent;
    border-color: #cccccc;
    border-width: 1px;
  }

  .top .navbar-right .navbar-nav.utility > li > a {
    /* md - home | locator.top-utility-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.links > li > a {
    /* md - home | locator.top-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.links > li > a {
    /* md - home | locator.top-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.utility > li.divider {
    /* md - home | locator.top-utility-link-divider */
  }

  .top .navbar-right .navbar-nav.links > li.divider {
    /* md - home | locator.top-link-divider */
    background: transparent;
    border-color: #cccccc;
    border-width: 1px;
  }

  .top .navbar-right .navbar-text {
    /* md - home | locator.top-text */
  }

  #menu {
    /* md - home | locator.menu-bg-1 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .navbar .navbar-toggle {
    /* md - home | locator.menu-hamburger-bg */
    /* xs - stateful.menu-hamburger-bg */
  }

  .navbar .navbar-toggle:hover {
    /* xs - stateful.menu-hamburger-bg-hover */
  }

  .navbar .navbar-toggle:active {
    /* xs - stateful.menu-hamburger-bg-active */
  }

  .navbar .navbar-toggle:hover .icon-bar {
    /* xs - stateful.menu-hamburger-hover */
  }

  .navbar .navbar-toggle:active .icon-bar {
    /* xs - stateful.menu-hamburger-active */
  }

  .navbar .navbar-toggle .icon-bar {
    /* md - home | locator.menu-hamburger */
    /* md - home | locator.menu-hamburger-lines */
  }

  .navbar-collapse {
    /* xs - stateful.menu-dropdown-top-bg */
  }

  .navbar-collapse .container {
    /* md - home | locator.menu-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .navbar-collapse ul.navbar-nav .open > a,
  .navbar-collapse ul.navbar-nav > li > a {
    /* xs - stateful.menu-anchor-text-# */
    /* xs - stateful.menu-button */
    /* md - home | locator.menu-anchor-text-1 */
    /* md - home | locator.menu-button height */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 15px;
    line-height: 16px;
    font-weight: normal;
    background: #ffffff;
    min-height: 70px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .navbar-collapse ul.navbar-nav .open > a:hover,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:hover {
    /* xs - stateful.menu-anchor-text-#-hover */
    /* xs - stateful.menu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open > a:focus,
  .navbar-collapse ul.navbar-nav .open > a:active,
  .navbar-collapse ul.navbar-nav .open > a:hover:focus,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus:hover,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:active,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus {
    /* xs - stateful.menu-anchor-text-#-active */
    /* xs - stateful.menu-button-active */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a {
    /* xs - stateful.submenu-anchor-text-#-# */
    /* xs - stateful.submenu-button */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:hover {
    /* xs - stateful.submenu-anchor-text-#-#-hover */
    /* xs - stateful.submenu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:active {
    /* xs - stateful.submenu-anchor-text-#-#-active */
    /* xs - stateful.submenu-button-active */
  }

  .navbar-collapse ul.navbar-nav.links > li > a {
    /* xs - stateful.top-anchor-text-# */
    /* xs - stateful.top-link-button */
  }

  .navbar-collapse ul.navbar-nav.links > li > a:hover {
    /* xs - stateful.top-anchor-text-#-hover */
    /* xs - stateful.top-link-button-hover */
  }

  .navbar-collapse ul.navbar-nav.links > li > a:active {
    /* xs - stateful.top-anchor-text-#-active */
    /* xs - stateful.top-link-button-active */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a {
    /* xs - stateful.top-utility-anchor-text-# */
    /* xs - stateful.top-utility-link-button */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a:hover {
    /* xs - stateful.top-utility-anchor-text-#-hover */
    /* xs - stateful.top-utility-link-button-hover */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a:active {
    /* xs - stateful.top-utility-anchor-text-#-active */
    /* xs - stateful.top-utility-link-button-active */
  }

  .banner {
    /* md - home | locator.banner-bg */
  }

  .category-hero {
    /* md - tag.category-hero-location */
    /* md - tag.category-hero-bg-1 */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container {
    /* md - tag.category-hero-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container h3 {
    /* md - tag.category-hero-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 30px;
    font-weight: normal;
  }

  .category-hero .container ul .btn {
    /* md - tag.category-hero-anchor-text-# important */
    /* md - tag.category-hero-button */
    background: #007ac4;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container ul .btn:hover {
    /* xs - stateful.category-hero-anchor-text-hover important */
    /* xs - stateful.category-hero-button-hover */
  }

  .category-hero .container ul .btn:active {
    /* xs - stateful.category-hero-anchor-text-active important */
    /* xs - stateful.category-hero-button-active */
  }

  div.not-found {
    /* md - 404.404-text */
    /* md - 404.404-bg-2 */
    /* md - 404.404-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  div.not-found h1 {
    /* md - 404.404-header */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 32px;
    line-height: 30px;
    font-weight: normal;
  }

  div.not-found a {
    /* xs - stateful.404-text-link */
  }

  div.not-found a:hover {
    /* xs - stateful.404-text-hover */
  }

  div.not-found a:active {
    /* xs - stateful.404-text-active */
  }

  div.not-found .container {
    /* md - 404.404-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .header-sm {
    /* md - home | locator.header-bg-1 */
    background: transparent;
    border-width: 0px;
  }

  .header {
    /* md - home | locator.header-bg-1 */
    /* md - home | locator.header-cta-bg-1 */
    background: transparent;
    border-width: 0px;
  }

  .header .container {
    /* md - home | locator.header-bg-2 */
  }

  .header-sm .container {
    /* md - home | locator.header-bg-2 */
  }

  .header-sm h1 {
    /* md - home | locator.header-h1 */
  }

  .header h1,
  .header .col-md-8 h1 {
    /* md - home | locator.header-h1 */
  }

  .header p,
  .header .col-md-8 p {
    /* md - home | locator.header-text-# */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
  }

  .header .col-md-8 p a {
    /* md - stateful.header-text-#-link */
  }

  .header .col-md-8 p a:hover {
    /* md - stateful.header-text-#-hover */
  }

  .header .col-md-8 p a:active {
    /* md - stateful.header-text-#-active */
  }

  .header .cta {
    /* md - home | locator.header-cta-title */
    /* md - home | locator.header-cta-bg-2 height */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 40px;
    font-weight: normal;
    background: #cc0000;
    min-height: 220px;
    border-width: 0px;
    border-radius: 20px 20px 20px 20px;
  }

  .header .cta strong {
    /* md - home | locator.header-cta-title */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 40px;
    font-weight: normal;
  }

  .header .cta p {
    /* md - home | locator.header-cta-text */
    /* md - home | locator.header-cta-content-1 */
  }

  .header .cta a {
    /* xs - stateful.header-cta-text-link */
  }

  .header .cta a:hover {
    /* xs - stateful.header-cta-text-hover */
  }

  .header .cta a:active {
    /* xs - stateful.header-cta-text-active */
  }

  .header .cta .btn {
    /* md - home | locator.header-cta-anchor-text important */
    /* md - home | locator.header-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #007ac4;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .header .cta .btn:hover {
    /* xs - stateful.header-cta-anchor-text-hover important */
    /* xs - stateful.header-cta-button-hover */
  }

  .header .cta .btn:active {
    /* xs - stateful.header-cta-anchor-text-active important */
    /* xs - stateful.header-cta-button-active */
  }

  .heading-more-information .more-information .collapsible-title {
    /* md - home | locator.content-drawer-text */
  }

  h2 > a.dropdown-toggle {
    /* md - home | locator.xs-drawer-bg */
    /* md - home | locator.content-drawer-bg */
  }

  h2 > a.dropdown-toggle:active,
  h2 > a.dropdown-toggle:hover {
    /* xs - stateful.content-drawer-text-active */
    /* xs - stateful.content-drawer-bg-active */
  }

  .container > h2 > a.dropdown-toggle {
    /* md - home | locator.content-drawer-text */
    /* md - home | locator.xs-drawer-bg */
    /* md - home | locator.links-drawer-bg */
  }

  .container > h2 > a.dropdown-toggle:hover,
  .container > h2 > a.dropdown-toggle:active {
    /* xs - stateful.links-?-title-active */
    /* xs - stateful.links-?-drawer-bg-active */
  }

  .locator-drawer {
    /* md - tag.locator-bg-1 */
    /* md - tag.list-bg-1 */
  }

  .locator-drawer .container {
    /* md - tag.list-bg */
    /* md - tag.list-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .locator-drawer .container h2 > a.dropdown-toggle {
    /* md - tag.locator-drawer-text */
    /* md - tag.locator-drawer-bg */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover {
    /* xs - stateful.locator-drawer-text-show-hover */
    /* xs - stateful.locator-drawer-bg-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active {
    /* xs - stateful.locator-drawer-text-show-active */
    /* xs - stateful.locator-drawer-bg-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle .caret {
    /* md - tag.locator-drawer-arrow */
  }

  .locator {
    /* md - home | locator.locator-bg-1 */
    /* md - locality [city].locator-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .locator .container {
    /* md - home | locator.locator-bg-2 */
    /* md - locality [city].locator-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .geolocate-input {
    /* md - home | locator.locator-start-box */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .start-label {
    /* md - home | locator.locator-start-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
  }

  #start-geolocate .location-holder .btn {
    /* md - home | locator.locator-start-locate-anchor-text important */
    /* md - home | locator.locator-start-locate-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .location-holder .btn:hover {
    /* xs - stateful.locator-start-locate-anchor-text-hover important */
    /* xs - stateful.locator-start-locate-button-hover */
  }

  #start-geolocate .location-holder .btn:active {
    /* xs - stateful.locator-start-locate-anchor-text-active important */
    /* xs - stateful.locator-start-locate-button-active */
  }

  #start-geolocate .geolocate-error-wrapper {
    /* xs - messages.locator-error-text */
    /* xs - messages.locator-error-bg */
  }

  #start-geocoder {
    /* md - home | locator.locator-start-divider */
    background: transparent;
    border-color: #bbbbbb;
    border-width: 2px;
  }

  #start-geocoder input {
    /* md - home | locator.locator-start-search-field */
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geocoder input::placeholder {
    /* md - home | locator.locator-start-search-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  #start-geocoder .geocoder-pin-far-right .btn-search {
    /* md - home | locator.locator-start-search-icon color */
    /* md - home | locator.locator-start-search-button */
    color: #ffffff;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:hover {
    /* xs - stateful.locator-start-search-button-hover */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:hover::before {
    /* xs - stateful.locator-start-search-icon-hover */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:active {
    /* xs - stateful.locator-start-search-button-active */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:active::before {
    /* xs - stateful.locator-start-search-icon-active */
  }

  #location-list {
    /* md - locality [city].locator-sm-xs-bg-1 */
    /* md - locality [city].list-location-bg-inactive */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #location-list.error {
    /* xs - messages.locator-sm-xs-bg-1 */
  }

  #location-list p.no-locations {
    /* xs - messages.list-error-text */
    /* xs - messages.list-error-bg */
  }

  #location-list .telephone a:hover {
    /* xs - stateful.location-detail-phone-number-hover */
  }

  #location-list .telephone a:active {
    /* xs - stateful.location-detail-phone-number-active */
  }

  #list-panel .place {
    /* md - locality [city].list-location-address */
    /* md - locality [city].locator-sm-xs-bg-2 */
    /* md - locality [city].list-location-bg-inactive */
    /* md - locality [city].list-location-hr border */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #list-panel .place.active {
    /* md - locality [city].list-location-bg-active */
    background: #eeeeee;
  }

  #list-panel .place.active:hover {
    /* md - locality [city].list-location-city-state-zip */
    /* md - locality [city].list-location-bg-active */
    /* md - locality [city].list-location-hr border */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    background: #eeeeee;
  }

  #list-panel .place:hover {
    /* md - locality [city].list-location-city-state-zip */
    /* md - locality [city].list-location-bg-active */
    /* md - locality [city].list-location-hr border */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    background: #eeeeee;
  }

  #list-panel .place strong.name a {
    /* md - locality [city].list-location-name */
    /* md - locality [city].location-name */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
  }

  #list-panel .place strong.name a:hover {
    /* xs - stateful.location-name-hover */
  }

  #list-panel .place strong.name a:active {
    /* xs - stateful.location-name-active */
  }

  #list-panel .place .hours {
    /* md - locality [city].list-location-hours-today-hours */
    /* md - locality [city].location-hours-today */
  }

  #list-panel .place .hours dt {
    /* md - locality [city].list-location-hours-today */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
  }

  #list-panel .place .hours dd a {
    /* xs - stateful.list-location-special-hours-today */
  }

  #list-panel .place .hours dd a:hover {
    /* xs - stateful.list-location-special-hours-today-hover */
  }

  #list-panel .place .hours dd a:active {
    /* xs - stateful.list-location-special-hours-today-active */
  }

  #list-panel .place .btn {
    /* md - locality [city].list-location-cta-anchor-text important */
    /* md - locality [city].list-location-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #list-panel .place .btn:hover {
    /* xs - stateful.list-location-cta-anchor-text-hover important */
    /* xs - stateful.list-location-cta-button-hover */
  }

  #list-panel .place .btn:active {
    /* xs - stateful.list-location-cta-anchor-text-active important */
    /* xs - stateful.list-location-cta-button-active */
  }

  #location-list a {
    /* md - locality [city].list-location-secondary-anchor-text */
  }

  #location-list a.list-location-phone-number {
    /* md - locality [city].list-location-phone-number */
    color: #007ac4;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
  }

  #location-list a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #location-list a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  #list-panel .icons a {
    /* md - locality [city].locator-icon-map-anchor-text */
    /* md - locality [city].xs-locator-icon-anchor-text-# */
  }

  #list-panel .icons a:hover {
    /* xs - stateful.xs-locator-icon-anchor-text-#-hover */
  }

  #list-panel .icons a:active {
    /* xs - stateful.xs-locator-icon-anchor-text-#-active */
  }

  #list-panel .pager {
    /* md - locality [city].locator-pager-text-bg */
    /* md - locality [city].locator-pager-left-bg radius */
  }

  #list-panel .pager li.col-xs-6 {
    /* md - locality [city].locator-pager-text */
  }

  #list-panel .pager a {
    /* md - locality [city].locator-pager-left height */
    /* md - locality [city].locator-pager-left-bg height */
  }

  #list-panel .pager a:hover {
    /* xs - stateful.location-cta-anchor-text-hover */
    /* xs - stateful.locator-pager-left-bg-hover */
  }

  #list-panel .pager a:active {
    /* xs - stateful.location-cta-anchor-text-active */
    /* xs - stateful.locator-pager-left-bg-active */
  }

  #list-panel .pager .disabled a {
    /* xs - stateful.locator-pager-left-disabled */
    /* xs - stateful.locator-pager-left-bg-disabled */
  }

  #list-panel .place .links a {
    /* md - locality [city].list-location-anchor-text-# */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
  }

  #list-panel .place .links a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #list-panel .place .links a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  #list-panel .place .secondary a {
    /* md - locality [city].list-location-secondary-anchor-text */
  }

  #list-panel .place .secondary a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #list-panel .place .secondary a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  .sk-circle .sk-child::before {
    /* xs - messages.loading-animation-color background */
  }

  #map-panel-holder .mapboxgl-popup-content {
    /* md - locality [city].tooltip-location-address */
    /* md - locality [city].tooltip-bubble */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 11px;
    line-height: 14px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 8px 8px 8px 0px 0px 0px 8px;
  }

  #map-panel-holder .mapboxgl-popup-content a {
    /* md - locality [city].tooltip-location-name */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 11px;
    line-height: 16px;
    font-weight: normal;
  }

  #map-panel-holder .mapboxgl-popup-content a:hover {
    /* xs - stateful.tooltip-location-name-hover */
  }

  #map-panel-holder .mapboxgl-popup-content a:active {
    /* xs - stateful.tooltip-location-name-active */
  }

  #map-panel-holder .mapboxgl-popup-content dt {
    /* md - locality [city].tooltip-location-hours-today */
  }

  #map .mapboxgl-popup-close-button::before {
    /* md - locality [city].tooltip-close */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
  }

  #map .mapboxgl-popup-close-button:hover::before {
    /* xs - stateful.tooltip-close-hover */
  }

  #map .mapboxgl-popup-close-button:active::before {
    /* xs - stateful.tooltip-close-active */
  }

  #location-search {
    /* md - locality [city].locator-search-bg height */
    /* md - locality - map shown.locator-search-bg */
  }

  #location-search .mapboxgl-ctrl-geocoder input {
    /* md - locality [city].locator-search-field */
    /* md - locality - map shown.locator-search-field */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #location-search .mapboxgl-ctrl-geocoder input::placeholder {
    /* md - locality [city].locator-search-placeholder-text */
    /* md - locality - map shown.locator-search-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  #location-search .btn-search {
    /* md - locality [city].locator-search-button height */
    /* md - locality - map shown.locator-search-button height */
    /* md - locality [city].locator-search-icon color */
    /* md - locality - map shown.locator-search-icon color */
    background: #cc0000;
    min-height: 50px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    color: #ffffff;
  }

  #location-search .btn-search:hover {
    /* xs - stateful.locator-start-search-icon-hover important */
    /* xs - stateful.locator-start-search-button-hover */
  }

  #location-search .btn-search:active {
    /* xs - stateful.locator-start-search-icon-active important */
    /* xs - stateful.locator-start-search-button-active */
  }

  body > .location {
    /* md - location.locator-sm-xs-bg-1 */
    /* md - location.locator-bg-1 */
  }

  body > .location .container {
    /* md - location.locator-sm-xs-bg-2 */
  }

  body > .location .container > .row > .col-md-5 {
    /* md - location.locator-sm-xs-bg-2 */
  }

  body > .location .location-detail {
    /* md - location.location-detail-address */
    /* md - location.location-detail-bg height */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    background: #ffffff;
    min-height: 600px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  body > .location .location-detail .additional-info {
    /* md - location.location-detail-hr */
  }

  body > .location .location-detail .btn {
    /* md - locality [city].list-location-cta-anchor-text important */
    /* md - locality [city].list-location-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  body > .location .location-detail .btn:hover {
    /* xs - stateful.location-cta-anchor-text-hover important */
    /* xs - stateful.location-cta-button-hover */
  }

  body > .location .location-detail .btn:active {
    /* xs - stateful.location-cta-anchor-text-active important */
    /* xs - stateful.location-cta-button-active */
  }

  body > .location .location-detail strong.name {
    /* md - location.location-detail-name */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 27px;
    font-weight: normal;
  }

  body > .location .stars .star-top {
    /* md - location.rating-stars color */
  }

  body > .location .stars .star-bottom {
    /* md - location.rating-stars color */
  }

  body > .location a {
    /* md - location.location-detail-anchor-text-# */
  }

  body > .location a:hover {
    /* xs - stateful.location-detail-anchor-text-#-hover */
  }

  body > .location a:active {
    /* xs - stateful.location-detail-anchor-text-#-active */
  }

  body > .location .telephone a {
    /* md - location.location-detail-phone-number */
    color: #007ac4;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 21px;
    font-weight: normal;
  }

  body > .location .telephone a:hover {
    /* xs - stateful.location-detail-phone-number-hover */
  }

  body > .location .telephone:active a:active {
    /* xs - stateful.location-detail-phone-number-active */
  }

  body > .location .location-detail .telephone a::before {
    /* md - location.location-detail-phone-number color */
    color: #007ac4;
  }

  body > .location .location-detail .telephone a:hover::before {
    /* xs - stateful.location-detail-phone-number-hover color */
  }

  body > .location .location-detail .telephone a:active::before {
    /* xs - stateful.location-detail-phone-number-active color */
  }

  body > .location .location-detail .hours dt,
  body > .location .location-detail .hours dd {
    /* md - location.location-detail-hours */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
  }

  body > .location .location-detail .hours .special::after,
  body > .location .location-detail .special-hours .special::before {
    /* md - location.location-detail-modified-hours color */
  }

  body > .location .location-detail .special-hours strong {
    /* md - location.location-detail-modified-hours-header */
  }

  body > .location .location-detail .special-hours dt,
  body > .location .location-detail .special-hours dd {
    /* md - location.location-detail-modified-hours */
  }

  body > .location .icons a {
    /* md - location.locator-icon-map-anchor-text */
    /* md - location.xs-locator-icon-anchor-text-# */
  }

  body > .location .icons a:hover {
    /* xs - stateful.xs-locator-icon-anchor-text-#-hover */
  }

  body > .location .icons a:active {
    /* xs - stateful.xs-locator-icon-anchor-text-#-active */
  }

  .events {
    /* md - location.events-text-#-# */
    /* md - location.events-bg-1 */
  }

  .events .container {
    /* md - location.events-bg-2 */
  }

  .events .container > h2 {
    /* md - location.events-title-# */
  }

  .events p a {
    /* xs - stateful.events-text-#-link */
  }

  .events p a:hover {
    /* xs - stateful.events-text-#-hover */
  }

  .events p a:active {
    /* xs - stateful.events-text-#-active */
  }

  #events .event {
    /* md - location.event-text */
    /* md - location.event-bg */
  }

  #events .event h3,
  #events .event strong {
    /* md - location.event-price */
  }

  #events .event .event-info > a,
  #events .event h3 > a {
    /* md - location.event-title */
  }

  #events .event .event-info > a:hover,
  #events .event .event-info > h3 a:hover {
    /* xs - stateful.event-title-hover */
  }

  #events .event .event-info > a:active,
  #events .event .event-info > h3 a:active {
    /* xs - stateful.event-title-active */
  }

  #events .event-performer-info a {
    /* xs - stateful.event-person-link */
  }

  #events .event-performer-info a:hover {
    /* xs - stateful.event-person-hover */
  }

  #events .event-performer-info a:active {
    /* xs - stateful.event-person-active */
  }

  .events .btn {
    /* md - location.event-cta-anchor-text important */
    /* md - location.event-cta-button */
  }

  .events .btn:hover {
    /* xs - stateful.event-cta-anchor-text-hover important */
    /* xs - stateful.event-cta-button-hover */
  }

  .events .btn:active {
    /* xs - stateful.event-cta-anchor-text-active important */
    /* xs - stateful.event-cta-button-active */
  }

  .show-more .events-show-more {
    /* md - location.events-view-more */
    /* md - location.events-view-more-bg */
  }

  .show-more .events-show-more:hover {
    /* xs - stateful.events-view-more-hover */
    /* xs - stateful.events-view-more-bg-hover */
  }

  .show-more .events-show-more:active {
    /* xs - stateful.events-view-more-active */
    /* xs - stateful.events-view-more-bg-active */
  }

  .show-more .events-show-more i.fa {
    /* md - location.events-view-more-arrow */
  }

  .show-more .events-show-more:hover i.fa {
    /* xs - stateful.events-view-more-hover */
  }

  .show-more .events-show-more:active i.fa {
    /* xs - stateful.events-view-more-active */
  }

  .offers-grid {
    /* md - home | locator.site-offers-bg-1 */
    /* md - location.site-offers-bg-2 */
    /* md - location.location-offers-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container {
    /* md - home | locator.site-offers-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .location-offers-grid .container {
    /* md - location.location-offers-bg-2 */
  }

  .offers-grid .container h2 {
    /* md - home | locator.site-offers-title */
    /* md - location.location-offers-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 40px;
    line-height: 44px;
    font-weight: normal;
  }

  .offers-grid .container p {
    /* md - home | locator.site-offers-text */
    /* md - location.location-offers-text */
  }

  .offers-grid .container p a {
    /* xs - stateful.site-offers-text-link */
  }

  .offers-grid .container p a:hover {
    /* xs - stateful.site-offers-text-hover */
  }

  .offers-grid .container p a:active {
    /* xs - stateful.site-offers-text-active */
  }

  .offers-grid .container .offers-grid-item {
    /* md - home | locator.site-offer-bg */
    /* md - location.location-offer-bg */
    background: #f2f2f2;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-title {
    /* md - home | locator.site-offer-title */
    /* md - location.location-offer-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info a:hover .offer-title {
    /* xs - stateful.site-offer-title-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info a:active .offer-title {
    /* xs - stateful.site-offer-title-link-active */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-text {
    /* md - home | locator.site-offer-text */
    /* md - location.location-offer-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-price a {
    /* md - home | locator.site-offer-price */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button {
    /* md - home | locator.site-offer-cta-anchor-text important */
    /* md - home | locator.site-offer-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button:hover {
    /* xs - stateful.site-offer-cta-anchor-text-hover important */
    /* xs - stateful.site-offer-cta-button-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button:active {
    /* xs - stateful.site-offer-cta-anchor-text-active important */
    /* xs - stateful.site-offer-cta-button-active */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-expires {
    /* md - home | locator.site-offer-expires */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Open Sans;
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action {
    /* md - home | locator.site-offer-secondary-action */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action:hover {
    /* xs - stateful.site-offer-secondary-action-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action:active {
    /* xs - stateful.site-offer-secondary-action-active */
  }

  .offers-grid .container .offers-grid-item.image-only {
    /* md - home | locator.site-offers-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offer-more {
    /* md - home | locator.site-offers-view-more-bg */
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offer-more .offer-view-more {
    /* md - home | locator.site-offers-view-more */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
  }

  .offers-grid .container .offer-more .offer-view-more:hover {
    /* xs - stateful.site-offers-view-more-hover */
    /* xs - stateful.site-offers-view-more-bg-hover */
  }

  .offers-grid .container .offer-more .offer-view-more:active {
    /* xs - stateful.site-offers-view-more-active */
    /* xs - stateful.site-offers-view-more-bg-active */
  }

  .offers-grid .container .offer-more .offer-view-more i.fa {
    /* md - home | locator.site-offers-view-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .offers-grid .container .offer-more .offer-view-more:hover i.fa {
    /* xs - stateful.site-offers-view-more-arrow-hover */
  }

  .offers-grid .container .offer-more .offer-view-more:active i.fa {
    /* xs - stateful.site-offers-view-more-arrow-active */
  }

  .offers-grid .container .panel {
    /* xs - messages.site-offers-no-offers */
    /* xs - messages.site-offers-no-offers-bg */
  }

  .reviews {
    /* md - location.reviews-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .container {
    /* md - location.reviews-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .container > h2 {
    /* md - location.reviews-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 39px;
    font-weight: normal;
  }

  .reviews .container > p {
    /* md - location.reviews-text */
  }

  .reviews p a {
    /* xs - stateful.reviews-text-link */
  }

  .reviews p a:hover {
    /* xs - stateful.reviews-text-hover */
  }

  .reviews p a:active {
    /* xs - stateful.reviews-text-active */
  }

  .reviews .panel {
    /* md - location.rating-statistics */
    /* md - location.rating-bg */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    background: #eeeeee;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .panel .panel-heading .panel-title {
    /* md - location.rating-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 21px;
    line-height: 24px;
    font-weight: normal;
  }

  .reviews .btn {
    /* md - location.review-submit-anchor-text important */
    /* md - location.review-submit-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 16px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .btn:hover {
    /* xs - stateful.review-submit-anchor-text-hover important */
    /* xs - stateful.review-submit-button-hover */
  }

  .reviews .btn:active {
    /* xs - stateful.review-submit-anchor-text-active important */
    /* xs - stateful.review-submit-button-active */
  }

  .reviews #reviews .panel {
    /* md - location.review-bg */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews #reviews .panel .panel-heading .panel-title {
    /* md - location.review-title */
  }

  .reviews #reviews .panel .panel-heading p {
    /* md - location.review-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }

  .reviews .panel .panel-body.no-reviews {
    /* xs - messages.reviews-no-reviews */
    /* xs - messages.reviews-no-reviews-bg */
  }

  .reviews #reviews hr {
    /* md - location.review-divider-line */
    background: transparent;
    border-color: #979797;
    border-width: 1px;
  }

  .reviews .reviews-show-more {
    /* md - location.reviews-view-more */
    /* md - location.reviews-view-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .reviews-show-more:hover {
    /* xs - stateful.reviews-view-more-hover */
    /* xs - stateful.reviews-view-more-bg-hover */
  }

  .reviews .reviews-show-more:active {
    /* xs - stateful.reviews-view-more-active */
    /* xs - stateful.reviews-view-more-bg-active */
  }

  .reviews .reviews-show-more i.fa {
    /* md - location.reviews-view-more-arrow */
  }

  .reviews .aggregate .stars .star-top,
  .reviews .aggregate .stars .star-bottom {
    /* md - location.rating-stars color */
  }

  .reviews #reviews .stars .star-top,
  .reviews #reviews .stars .star-bottom {
    /* md - location.review-stars */
  }

  #content .content {
    /* md - home | locator.content-bg-2 */
    /* md - home | locator.content-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .content .container {
    /* md - home | locator.content-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .content .container p {
    /* md - home | locator.content-text-#-# */
  }

  .content .container p a {
    /* xs - stateful.content-text-#-#-link */
  }

  .content .container p a:hover {
    /* xs - stateful.content-text-#-#-hover */
  }

  .content .container p a:active {
    /* xs - stateful.content-text-#-#-active */
  }

  .content .container h1,
  .content .container h2 {
    /* md - home | locator.content-title-#-# */
    /* md - home | locator.content-title-# */
  }

  .content .container .btn-danger {
    /* md - home | locator.content-cta-anchor-text-# important */
    /* md - home | locator.content-cta-button */
  }

  .content .container .btn-danger:hover {
    /* xs - stateful.content-cta-anchor-text-#-hover important */
    /* xs - stateful.content-cta-button-hover */
  }

  .content .container .btn-danger:active {
    /* xs - stateful.content-cta-anchor-text-#-active important */
    /* xs - stateful.content-cta-button-active */
  }

  #content .legal {
    /* md - home | locator.content-legal-bg-1 */
  }

  #content .legal .container {
    /* md - home | locator.content-legal-bg-2 */
  }

  #content .legal .container .content-legal-text {
    /* md - home | locator.content-legal-text-# */
    /* md - home | locator.content-legal-hr border */
  }

  #content .legal .container .content-legal-text a {
    /* xs - stateful.content-legal-text-#-link */
  }

  #content .legal .container .content-legal-text a:hover {
    /* xs - stateful.content-legal-text-#-hover */
  }

  #content .legal .container .content-legal-text a:active {
    /* xs - stateful.content-legal-text-#-active */
  }

  .body-cta {
    /* md - home | locator.body-cta-bg-1 */
    background: transparent;
    border-width: 0px;
  }

  .body-cta .container {
    /* md - home | locator.body-cta-bg-2 */
  }

  .body-cta h2 {
    /* md - home | locator.body-cta-title */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 32px;
    line-height: 44px;
    font-weight: normal;
  }

  .body-cta p {
    /* md - home | locator.body-cta-text */
  }

  .body-cta p a {
    /* xs - stateful.body-cta-text-link */
  }

  .body-cta p a:hover {
    /* xs - stateful.body-cta-text-hover */
  }

  .body-cta p a:active {
    /* xs - stateful.body-cta-text-active */
  }

  .body-cta .btn-danger {
    /* md - home | locator.body-cta-anchor-text important */
    /* md - home | locator.body-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #007ac4;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .body-cta .btn-danger:hover {
    /* xs - stateful.body-cta-anchor-text-hover */
    /* xs - stateful.body-cta-button-hover */
  }

  .body-cta .btn-danger:active {
    /* xs - stateful.body-cta-anchor-text-active */
    /* xs - stateful.body-cta-button-active */
  }

  .categories {
    /* md - home | locator.categories-bg-1 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container {
    /* md - home | locator.categories-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container h2 {
    /* md - home | locator.categories-title-3x */
    /* md - home | locator.categories-title-4x */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 39px;
    font-weight: normal;
  }

  .categories .container p {
    /* md - home | locator.categories-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
  }

  .categories .container p a {
    /* xs - stateful.categories-text-link */
  }

  .categories .container p a:hover {
    /* xs - stateful.categories-text-hover */
  }

  .categories .container p a:active {
    /* xs - stateful.categories-text-active */
  }

  .categories .container .categories-grid.grid-4 .category-item a > span,
  .categories .container .categories-grid .category-item a > span {
    /* md - home | locator.category-name */
    /* md - home | locator.category-name-bg */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container .categories-grid .category-item a:hover > span {
    /* xs - stateful.category-name-hover */
    /* xs - stateful.category-name-bg-hover */
  }

  .categories .container .categories-grid .category-item a:active > span {
    /* xs - stateful.category-name-active */
    /* xs - stateful.category-name-bg-active */
  }

  .secondary-content {
    /* md - home | locator.secondary-content-text-#-# */
    /* md - home | locator.content-bg-1 */
    /* md - locality [city].secondary-content-text-#-# */
    /* md - locality [city].content-bg-2 */
    /* md - locality [city].content-bg-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content-drawer .more-information .collapsible-title {
    /* md - home | locator.secondary-content-drawer-text */
    /* md - locality [city].secondary-content-drawer-text */
  }

  .secondary-content .container {
    /* md - home | locator.content-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content h2 {
    /* md - locality [city].secondary-content-title-# */
    /* md - home | locator.secondary-content-title-# */
  }

  .secondary-content p {
    /* md - locality [city].secondary-content-text-#-# */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
  }

  .secondary-content p a {
    /* xs - stateful.secondary-content-text-#-#-link */
  }

  .secondary-content p a:hover {
    /* xs - stateful.secondary-content-text-#-#-hover */
  }

  .secondary-content p a:active {
    /* xs - stateful.secondary-content-text-#-#-active */
  }

  .secondary-content .btn-danger {
    /* md - home | locator.secondary-content-cta-anchor-text-# important */
    /* md - home | locator.secondary-content-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content .btn-danger:hover {
    /* xs - stateful.secondary-content-cta-anchor-text-hover important */
    /* xs - stateful.secondary-content-cta-button-hover */
  }

  .secondary-content .btn-danger:active {
    /* xs - stateful.secondary-content-cta-anchor-text-active important */
    /* xs - stateful.secondary-content-cta-button-active */
  }

  .hasPOS,
  .availableFrom,
  .areaServed,
  .makesOffer,
  .containsPlace {
    /* md - home | locator.links-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .container,
  .availableFrom .container,
  .areaServed .container,
  .makesOffer .container,
  .containsPlace .container {
    /* md - home | locator.links-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .list-group-item a,
  .availableFrom .list-group-item a,
  .areaServed .list-group-item a,
  .makesOffer .list-group-item a,
  .containsPlace .list-group-item a {
    /* md - home | locator.links-cities-anchor-text-1 */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 32px;
    font-weight: normal;
  }

  .hasPOS .list-group-item a:hover,
  .availableFrom .list-group-item a:hover,
  .areaServed .list-group-item a:hover,
  .makesOffer .list-group-item a:hover,
  .containsPlace .list-group-item a:hover {
    /* xs - stateful.links-?-hover */
  }

  .hasPOS .list-group-item a:active,
  .availableFrom .list-group-item a:active,
  .areaServed .list-group-item a:active,
  .makesOffer .list-group-item a:active,
  .containsPlace .list-group-item a:active {
    /* xs - stateful.links-?-active */
  }

  .hasPOS .location-nearby .location-nearby-name,
  .availableFrom .location-nearby .location-nearby-name {
    /* md - locality [city].location-nearby-name */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-name:hover,
  .availableFrom .location-nearby .location-nearby-name:hover {
    /* xs - stateful.location-nearby-name-hover */
  }

  .hasPOS .location-nearby .location-nearby-name:active,
  .availableFrom .location-nearby .location-nearby-name:active {
    /* xs - stateful.location-nearby-name-active */
  }

  .hasPOS .location-nearby .location-nearby-address,
  .hasPOS .location-nearby .location-nearby-directions,
  .availableFrom .location-nearby .location-nearby-address,
  .availableFrom .location-nearby .location-nearby-directions {
    /* md - locality [city].location-nearby-address */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-directions,
  .availableFrom .location-nearby .location-nearby-directions {
    /* md - locality [city].location-nearby-directions */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-directions:hover,
  .availableFrom .location-nearby .location-nearby-directions:hover {
    /* xs - stateful.location-nearby-directions-hover */
  }

  .hasPOS .location-nearby .location-nearby-directions:active,
  .availableFrom .location-nearby .location-nearby-directions:active {
    /* xs - stateful.location-nearby-directions-active */
  }

  .hasPOS .location-nearby .location-nearby-phone-number,
  .availableFrom .location-nearby .location-nearby-phone-number {
    /* md - locality [city].location-nearby-phone-number */
    color: #007ac4;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-phone-number:hover,
  .availableFrom .location-nearby .location-nearby-phone-number:hover {
    /* xs - stateful.location-nearby-phone-number-hover */
  }

  .hasPOS .location-nearby .location-nearby-phone-number:active,
  .availableFrom .location-nearby .location-nearby-phone-number:active {
    /* xs - stateful.location-nearby-phone-number-active */
  }

  .hasPOS .location-nearby .location-nearby-hours strong,
  .availableFrom .location-nearby .location-nearby-hours strong {
    /* md - locality [city].location-nearby-hours-title */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-hours dt,
  .hasPOS .location-nearby .location-nearby-hours dd,
  .availableFrom .location-nearby .location-nearby-hours dt,
  .availableFrom .location-nearby .location-nearby-hours dd {
    /* md - locality [city].location-nearby-hours */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .btn,
  .availableFrom .location-nearby .btn {
    /* md - locality [city].location-nearby-cta-anchor-text important */
    /* md - locality [city].location-nearby-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .location-nearby .btn:hover,
  .availableFrom .location-nearby .btn:hover {
    /* xs - stateful.location-nearby-cta-anchor-text-hover important */
    /* xs - stateful.location-nearby-cta-button-hover */
  }

  .hasPOS .location-nearby .btn:active,
  .availableFrom .location-nearby .btn:active {
    /* xs - stateful.location-nearby-cta-anchor-text-active important */
    /* xs - stateful.location-nearby-cta-button-active */
  }

  .availableFrom .links-locations-show-more {
    /* md - locality [city].links-locations-show-more */
    /* md - locality [city].links-locations-show-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .availableFrom .links-locations-show-more .fa {
    /* md - locality [city].links-locations-show-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .availableFrom .links-locations-show-more:hover {
    /* xs - stateful.links-locations-show-more-hover important */
    /* xs - stateful.links-locations-show-more-bg-hover */
  }

  .availableFrom .links-locations-show-more:active {
    /* xs - stateful.links-locations-show-more-active important */
    /* xs - stateful.links-locations-show-more-bg-active */
  }

  .hasPOS .links-locations-show-more {
    /* md - location.links-locations-show-more */
    /* md - location.links-locations-show-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .links-locations-show-more .caret {
    /* md - location.links-locations-show-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .hasPOS .links-locations-show-more:hover {
    /* xs - stateful.links-locations-show-more-hover important */
    /* xs - stateful.links-locations-show-more-bg-hover */
  }

  .hasPOS .links-locations-show-more:active {
    /* xs - stateful.links-locations-show-more-active important */
    /* xs - stateful.links-locations-show-more-bg-active */
  }

  .email-signup {
    /* md - home | locator.email-signup-text */
    /* md - home | locator.email-signup-bg-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .container {
    /* md - home | locator.email-signup-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel {
    /* md - home | locator.email-signup-box */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel a {
    /* xs - stateful.email-signup-text-link */
  }

  .email-signup .panel a:hover {
    /* xs - stateful.email-signup-text-hover */
  }

  .email-signup .panel a:active {
    /* xs - stateful.email-signup-text-active */
  }

  .email-signup .panel .panel-heading h3 {
    /* md - home | locator.email-signup-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
  }

  .email-signup .panel .panel-body form input {
    /* md - home | locator.email-signup-text */
    /* md - home | locator.email-signup-field */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel .panel-body form input::placeholder {
    /* md - home | locator.email-signup-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  .email-signup .panel .panel-body form .btn,
  .email-signup .panel .panel-body .form .btn {
    /* md - home | locator.email-signup-anchor-text important */
    /* md - home | locator.email-signup-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel .panel-body form .btn:hover,
  .email-signup .panel .panel-body .form .btn:hover {
    /* xs - stateful.email-signup-anchor-text-hover important */
    /* xs - stateful.email-signup-button-hover */
  }

  .email-signup .panel .panel-body form .btn:active,
  .email-signup .panel .panel-body .form .btn:active {
    /* xs - stateful.email-signup-anchor-text-active important */
    /* xs - stateful.email-signup-button-active */
  }

  .footer {
    /* md - home | locator.footer-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .footer .container {
    /* md - home | locator.footer-copyright */
    /* md - home | locator.footer-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .footer .social-text a {
    /* xs - stateful.footer-social-text-link */
  }

  .footer .social-text a:hover {
    /* xs - stateful.footer-social-text-hover */
  }

  .footer .social-text a:active {
    /* xs - stateful.footer-social-text-active */
  }

  .footer ol.breadcrumb > li,
  .footer ol.breadcrumb > li,
  .footer ol.breadcrumb > li.active {
    /* md - home | locator.footer-breadcrumbs */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
  }

  .footer ol.breadcrumb > li a {
    /* md - home | locator.footer-breadcrumbs-x */
  }

  .footer ol.breadcrumb > li a:hover {
    /* xs - stateful.footer-breadcrumbs-hover */
  }

  .footer ol.breadcrumb > li a:active {
    /* xs - stateful.footer-breadcrumbs-active */
  }

  .footer ol.breadcrumb > li::after {
    /* md - home | locator.footer-breadcrumbs-divider gradient-slash */
  }

  .footer .navbar-nav.social-text {
    /* md - home | locator.footer-social-text */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Open Sans;
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
  }

  .footer .navbar-nav.social-icons > li a {
    /* md - home | locator.footer-social-facebook */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 40px;
    line-height: 40px;
    font-weight: normal;
  }

  .footer .navbar-nav.social-icons > li a:hover {
    /* xs - stateful.footer-social-#-hover */
  }

  .footer .navbar-nav.social-icons > li a:active {
    /* xs - stateful.footer-social-#-active */
  }

  .footer .nav > li a {
    /* md - home | locator.footer-menu-anchor-text-# */
  }

  .footer .menu .navbar-nav > li a:hover {
    /* xs - stateful.footer-menu-anchor-text-#-hover */
  }

  .footer .menu .navbar-nav > li a:active {
    /* xs - stateful.footer-menu-anchor-text-#-active */
  }

  .footer .nav > li.divider {
    /* md - home | locator.footer-menu-divider gradient-line */
  }

  .footer hr {
    /* md - home | locator.footer-social-hr */
    /* md - home | locator.footer-social-divider */
  }

  .footer .columns {
    /* md - home | locator.footer-menu-hr */
  }

  .footer .columns .navbar-nav strong {
    /* md - home | locator.footer-menu-header-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
  }

  .footer .columns .navbar-nav > li a {
    /* md - home | locator.footer-menu-anchor-text-1-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 15px;
    font-weight: normal;
  }

  .footer .columns .navbar-nav > li a:hover {
    /* xs - stateful.footer-menu-anchor-text-#-#-hover */
  }

  .footer .columns .navbar-nav > li a:active {
    /* xs - stateful.footer-menu-anchor-text-#-#-active */
  }

  .footer .menu ul > li::after,
  .footer ul.disclaimers > li::after {
    /* md - home | locator.footer-disclaimers-divider gradient-line */
  }

  .footer .disclaimers > li a {
    /* md - home | locator.footer-disclaimers-anchor-text-# */
    /* md - home | locator.footer-disclaimers-anchor-text-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
  }

  .footer .disclaimers > li a:hover {
    /* xs - stateful.footer-disclaimers-anchor-text-#-hover */
  }

  .footer .disclaimers > li a:active {
    /* xs - stateful.footer-disclaimers-anchor-text-#-active */
  }

  .footer .navbar-text > p {
    /* md - home | locator.footer-legal */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
  }

  .footer .navbar-text.legal {
    /* md - home | locator.footer-copyright */
  }

  .footer .navbar-text.legal a {
    /* xs - stateful.footer-copyright-link */
  }

  .footer .navbar-text.legal a:hover {
    /* xs - stateful.footer-copyright-hover */
  }

  .footer .navbar-text.legal a:active {
    /* xs - stateful.footer-copyright-active */
  }

  .footer .navbar-text.legal p a {
    /* xs - stateful.footer-legal-link */
  }

  .footer .navbar-text.legal p a:hover {
    /* xs - stateful.footer-legal-hover */
  }

  .footer .navbar-text.legal p a:active {
    /* xs - stateful.footer-legal-active */
  }

  .team .container {
    /* md - location.team-bg-2 */
  }



  .team .container .team-more .team-view-more {
    /* xs - stateful.team-view-more */
    /* xs - stateful.team-view-more-bg */
  }

  .team .container .team-more .team-view-more:hover {
    /* xs - stateful.team-view-more-hover */
    /* xs - stateful.team-view-more-bg-hover */
  }

  .team .container .team-more .team-view-more:active {
    /* xs - stateful.team-view-more-active */
    /* xs - stateful.team-view-more-bg-active */
  }

  .team .container .team-more .team-view-more i.fa {
    /* xs - stateful.team-view-more-arrow */
  }

  .team .container .team-more .team-view-more:hover i.fa {
    /* xs - stateful.team-view-more-arrow-hover */
  }

  .team .container .team-more .team-view-more:active i.fa {
    /* xs - stateful.team-view-more-arrow-active */
  }

  .team .container .team-title {
    /* md - location.team-title-# */
  }

  .team .container .team-text {
    /* md - location.team-text-#-# */
  }

  .team .container .team-grid .team-item {
    /* md - location.team-member-bg */
  }

  .team .container .team-grid .team-item .team-picture {
    /* md - location.team-member-image */
  }

  .team .container .team-grid .team-item .team-name {
    /* md - location.team-member-name */
  }

  .team .container .team-grid .team-item .team-job-title {
    /* md - location.team-member-title */
  }

  .team .container .team-grid .team-item .team-work-location {
    /* md - location.team-member-location */
  }

  .team .container .team-grid .team-item .team-description {
    /* md - location.team-member-description */
  }

  .team .container .team-grid .team-item .team-telephone {
    /* md - location.team-member-additional-info */
  }

  h2 > a.dropdown-toggle {
    /* md - home | locator.links-cities-title */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 26px;
    line-height: 33px;
    font-weight: normal;
  }

  .navbar-collapse.in .menus,
  .navbar.collapsing .menus {
    /* xs - stateful.menu-dropdown-top-bg */
  }



  .dropdown-toggle:hover .caret {
    /* sm - stateful.links-?-arrow-hover */
  }

  .dropdown-toggle:active .caret {
    /* sm - stateful.links-?-arrow-active */
  }

  .container > h2 > a.dropdown-toggle:hover {
    /* sm - stateful.links-?-title-hover */
  }

  .container > h2 > a.dropdown-toggle:active {
    /* sm - stateful.links-?-title-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover {
    /* sm - stateful.locator-drawer-text-hover */
    /* sm - stateful.list-bg-2-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover .caret {
    /* sm - stateful.locator-drawer-arrow-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active {
    /* sm - stateful.locator-drawer-text-active */
    /* sm - stateful.list-bg-2-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active .caret {
    /* sm - stateful.locator-drawer-arrow-active */
  }

  .team {
    /* md - location.team-bg-1 */
  }

  .top .navbar-right p.utility a {
    /* md - stateful.top-text-link */
  }

  .top .navbar-right p.utility a:hover {
    /* md - stateful.top-text-hover */
  }

  .top .navbar-right p.utility a:active {
    /* md - stateful.top-text-active */
  }

  .top .navbar-right .navbar-nav.links > li > a:hover {
    /* md - stateful.top-anchor-text-#-hover */
  }

  .top .navbar-right .navbar-nav.links > li > a:active {
    /* md - stateful.top-anchor-text-#-active */
  }

  .top .navbar-right .navbar-nav.utility > li > a:hover {
    /* md - stateful.top-utility-anchor-text-#-hover */
  }

  .top .navbar-right .navbar-nav.utility > li > a:active {
    /* md - stateful.top-utility-anchor-text-#-active */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a {
    /* md - stateful.submenu-anchor-text-#-# */
    /* md - stateful.submenu-button */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:hover {
    /* md - stateful.submenu-anchor-text-#-#-hover */
    /* md - stateful.submenu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:active {
    /* md - stateful.submenu-anchor-text-#-#-active */
    /* md - stateful.submenu-button-active */
  }
}

@media (min-width: 1200px) {
  body > .alert a {
    /* lg - home | locator.alert-text */
    /* lg - home | locator.alert-bg-1 */
    /* xs - stateful.alert-text */
    /* xs - stateful.alert-bg-1 */
  }

  body > .alert a:hover {
    /* xs - stateful.alert-text-hover */
    /* xs - stateful.alert-bg-1-hover */
  }

  body > .alert a:active {
    /* xs - stateful.alert-text-active */
    /* xs - stateful.alert-bg-1-active */
  }

  .dropdown-toggle .caret {
    /* lg - home | locator.links-cities-arrow */
  }

  .navbar-collapse .dropdown-toggle .caret {
    /* xs - stateful.menu-arrow */
  }

  .navbar-collapse .dropdown-toggle:hover .caret {
    /* xs - stateful.menu-arrow-hover */
  }

  .navbar-collapse .dropdown-toggle:active .caret {
    /* xs - stateful.menu-arrow-active */
  }

  .navbar-collapse ul.navbar-nav li > .text-center {
    /* xs - stateful.menu-dropdown-top-bg */
  }

  .navbar-collapse ul.navbar-nav li.close > a {
    /* xs - stateful.menu-dropdown-close-bg */
  }

  .navbar-collapse ul.navbar-nav li.close:hover {
    /* xs - stateful.menu-dropdown-close-bg-hover */
  }

  #menu > div > div > ul > li.close.hidden-md.hidden-lg > a:hover {
    /* xs - stateful.menu-dropdown-close-bg-hover */
  }

  #menu > div > div > ul > li.close.hidden-md.hidden-lg > a:active {
    /* xs - stateful.menu-dropdown-close-bg-active */
  }

  .navbar-collapse ul.navbar-nav li.close > a .fa {
    /* xs - stateful.menu-dropdown-close-x */
  }

  .navbar-collapse ul.navbar-nav li.close > a:hover .fa {
    /* xs - stateful.menu-dropdown-close-x-hover */
  }

  .navbar-collapse ul.navbar-nav li.close > a:active .fa {
    /* xs - stateful.menu-dropdown-close-x-active */
  }

  .navbar-collapse .navbar-text {
    /* xs - stateful.top-text */
    /* xs - stateful.menu-bg */
  }

  .navbar-collapse .navbar-text a {
    /* xs - stateful.top-text-link */
  }

  .navbar-collapse .navbar-text a:hover {
    /* xs - stateful.top-text-hover */
  }

  .navbar-collapse .navbar-text a:active {
    /* xs - stateful.top-text-active */
  }

  .top {
    /* lg - home | locator.top-bg-2 */
    /* lg - home | locator.top-bg-1 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .container {
    /* lg - home | locator.top-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .container.phone {
    /* lg - home | locator.xs-top-phone-bg */
  }

  .top .navbar-header.brands .divider {
    /* lg - home | locator.top-brand-divider */
  }

  .top a.phone {
    /* lg - home | locator.xs-top-phone-number */
    /* lg - home | locator.top-phone-number */
    /* lg - home | locator.xs-top-phone-bg */
    /* lg - home | locator.top-phone-bg */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 28px;
    line-height: 28px;
    font-weight: normal;
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .top .navbar-header.brands a.phone:hover,
  .top a.phone:hover {
    /* xs - stateful.xs-top-phone-button-hover */
    /* xs - stateful.xs-top-phone-number-hover */
    /* xs - stateful.top-phone-number-hover */
    /* xs - stateful.top-phone-bg-hover */
  }

  .top .navbar-header.brands a.phone:active,
  .top a.phone:active {
    /* xs - stateful.xs-top-phone-button-active */
    /* xs - stateful.xs-top-phone-number-active */
    /* xs - stateful.top-phone-number-active */
    /* xs - stateful.top-phone-bg-active */
  }

  .top .navbar-right .navbar-nav > li.divider {
    /* lg - home | locator.top-link-divider */
    background: transparent;
    border-color: #cccccc;
    border-width: 1px;
  }

  .top .navbar-right.phone::before {
    /* lg - home | locator.top-phone-divider */
    background: transparent;
    border-color: #cccccc;
    border-width: 1px;
  }

  .top .navbar-right .navbar-nav.utility > li > a {
    /* lg - home | locator.top-utility-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.links > li > a {
    /* lg - home | locator.top-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.links > li > a {
    /* lg - home | locator.top-anchor-text-# */
  }

  .top .navbar-right .navbar-nav.utility > li.divider {
    /* lg - home | locator.top-utility-link-divider */
  }

  .top .navbar-right .navbar-nav.links > li.divider {
    /* lg - home | locator.top-link-divider */
    background: transparent;
    border-color: #cccccc;
    border-width: 1px;
  }

  .top .navbar-right .navbar-text {
    /* lg - home | locator.top-text */
  }

  #menu {
    /* lg - home | locator.menu-bg-1 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .navbar .navbar-toggle {
    /* lg - home | locator.menu-hamburger-bg */
    /* xs - stateful.menu-hamburger-bg */
  }

  .navbar .navbar-toggle:hover {
    /* xs - stateful.menu-hamburger-bg-hover */
  }

  .navbar .navbar-toggle:active {
    /* xs - stateful.menu-hamburger-bg-active */
  }

  .navbar .navbar-toggle:hover .icon-bar {
    /* xs - stateful.menu-hamburger-hover */
  }

  .navbar .navbar-toggle:active .icon-bar {
    /* xs - stateful.menu-hamburger-active */
  }

  .navbar .navbar-toggle .icon-bar {
    /* lg - home | locator.menu-hamburger */
    /* lg - home | locator.menu-hamburger-lines */
  }

  .navbar-collapse {
    /* xs - stateful.menu-dropdown-top-bg */
  }

  .navbar-collapse .container {
    /* lg - home | locator.menu-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .navbar-collapse ul.navbar-nav .open > a,
  .navbar-collapse ul.navbar-nav > li > a {
    /* xs - stateful.menu-anchor-text-# */
    /* xs - stateful.menu-button */
    /* lg - home | locator.menu-anchor-text-1 */
    /* lg - home | locator.menu-button height */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    background: #ffffff;
    min-height: 90px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .navbar-collapse ul.navbar-nav .open > a:hover,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:hover {
    /* xs - stateful.menu-anchor-text-#-hover */
    /* xs - stateful.menu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open > a:focus,
  .navbar-collapse ul.navbar-nav .open > a:active,
  .navbar-collapse ul.navbar-nav .open > a:hover:focus,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus:hover,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:active,
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus {
    /* xs - stateful.menu-anchor-text-#-active */
    /* xs - stateful.menu-button-active */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a {
    /* xs - stateful.submenu-anchor-text-#-# */
    /* xs - stateful.submenu-button */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:hover {
    /* xs - stateful.submenu-anchor-text-#-#-hover */
    /* xs - stateful.submenu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:active {
    /* xs - stateful.submenu-anchor-text-#-#-active */
    /* xs - stateful.submenu-button-active */
  }

  .navbar-collapse ul.navbar-nav.links > li > a {
    /* xs - stateful.top-anchor-text-# */
    /* xs - stateful.top-link-button */
  }

  .navbar-collapse ul.navbar-nav.links > li > a:hover {
    /* xs - stateful.top-anchor-text-#-hover */
    /* xs - stateful.top-link-button-hover */
  }

  .navbar-collapse ul.navbar-nav.links > li > a:active {
    /* xs - stateful.top-anchor-text-#-active */
    /* xs - stateful.top-link-button-active */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a {
    /* xs - stateful.top-utility-anchor-text-# */
    /* xs - stateful.top-utility-link-button */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a:hover {
    /* xs - stateful.top-utility-anchor-text-#-hover */
    /* xs - stateful.top-utility-link-button-hover */
  }

  .navbar-collapse ul.navbar-nav.utility > li > a:active {
    /* xs - stateful.top-utility-anchor-text-#-active */
    /* xs - stateful.top-utility-link-button-active */
  }

  .banner {
    /* lg - home | locator.banner-bg */
  }

  .category-hero {
    /* lg - tag.category-hero-location */
    /* lg - tag.category-hero-bg-1 */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container {
    /* lg - tag.category-hero-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container h3 {
    /* lg - tag.category-hero-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 30px;
    font-weight: normal;
  }

  .category-hero .container ul .btn {
    /* lg - tag.category-hero-anchor-text-# important */
    /* lg - tag.category-hero-button */
    background: #007ac4;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .category-hero .container ul .btn:hover {
    /* xs - stateful.category-hero-anchor-text-hover important */
    /* xs - stateful.category-hero-button-hover */
  }

  .category-hero .container ul .btn:active {
    /* xs - stateful.category-hero-anchor-text-active important */
    /* xs - stateful.category-hero-button-active */
  }

  div.not-found {
    /* lg - 404.404-text */
    /* lg - 404.404-bg-2 */
    /* lg - 404.404-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  div.not-found h1 {
    /* lg - 404.404-header */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 32px;
    line-height: 30px;
    font-weight: normal;
  }

  div.not-found a {
    /* xs - stateful.404-text-link */
  }

  div.not-found a:hover {
    /* xs - stateful.404-text-hover */
  }

  div.not-found a:active {
    /* xs - stateful.404-text-active */
  }

  div.not-found .container {
    /* lg - 404.404-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .header-sm {
    /* lg - home | locator.header-bg-1 */
    background: transparent;
    border-width: 0px;
  }

  .header {
    /* lg - home | locator.header-bg-1 */
    /* lg - home | locator.header-cta-bg-1 */
    background: transparent;
    border-width: 0px;
  }

  .header .container {
    /* lg - home | locator.header-bg-2 */
  }

  .header-sm .container {
    /* lg - home | locator.header-bg-2 */
  }

  .header-sm h1 {
    /* lg - home | locator.header-h1 */
  }

  .header h1,
  .header .col-md-8 h1 {
    /* lg - home | locator.header-h1 */
  }

  .header p,
  .header .col-md-8 p {
    /* lg - home | locator.header-text-# */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 22px;
    line-height: 30px;
    font-weight: normal;
  }

  .header .col-md-8 p a {
    /* md - stateful.header-text-#-link */
  }

  .header .col-md-8 p a:hover {
    /* md - stateful.header-text-#-hover */
  }

  .header .col-md-8 p a:active {
    /* md - stateful.header-text-#-active */
  }

  .header .cta {
    /* lg - home | locator.header-cta-title */
    /* lg - home | locator.header-cta-bg-2 height */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 40px;
    font-weight: normal;
    background: #cc0000;
    min-height: 265px;
    border-width: 0px;
    border-radius: 20px 20px 20px 20px;
  }

  .header .cta strong {
    /* lg - home | locator.header-cta-title */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 40px;
    font-weight: normal;
  }

  .header .cta p {
    /* lg - home | locator.header-cta-text */
    /* lg - home | locator.header-cta-content-1 */
  }

  .header .cta a {
    /* xs - stateful.header-cta-text-link */
  }

  .header .cta a:hover {
    /* xs - stateful.header-cta-text-hover */
  }

  .header .cta a:active {
    /* xs - stateful.header-cta-text-active */
  }

  .header .cta .btn {
    /* lg - home | locator.header-cta-anchor-text important */
    /* lg - home | locator.header-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #007ac4;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .header .cta .btn:hover {
    /* xs - stateful.header-cta-anchor-text-hover important */
    /* xs - stateful.header-cta-button-hover */
  }

  .header .cta .btn:active {
    /* xs - stateful.header-cta-anchor-text-active important */
    /* xs - stateful.header-cta-button-active */
  }

  .heading-more-information .more-information .collapsible-title {
    /* lg - home | locator.content-drawer-text */
  }

  h2 > a.dropdown-toggle {
    /* lg - home | locator.xs-drawer-bg */
    /* lg - home | locator.content-drawer-bg */
  }

  h2 > a.dropdown-toggle:active,
  h2 > a.dropdown-toggle:hover {
    /* xs - stateful.content-drawer-text-active */
    /* xs - stateful.content-drawer-bg-active */
  }

  .container > h2 > a.dropdown-toggle {
    /* lg - home | locator.content-drawer-text */
    /* lg - home | locator.xs-drawer-bg */
    /* lg - home | locator.links-drawer-bg */
  }

  .container > h2 > a.dropdown-toggle:hover,
  .container > h2 > a.dropdown-toggle:active {
    /* xs - stateful.links-?-title-active */
    /* xs - stateful.links-?-drawer-bg-active */
  }

  .locator-drawer {
    /* lg - tag.locator-bg-1 */
    /* lg - tag.list-bg-1 */
  }

  .locator-drawer .container {
    /* lg - tag.list-bg */
    /* lg - tag.list-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .locator-drawer .container h2 > a.dropdown-toggle {
    /* lg - tag.locator-drawer-text */
    /* lg - tag.locator-drawer-bg */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover {
    /* xs - stateful.locator-drawer-text-show-hover */
    /* xs - stateful.locator-drawer-bg-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active {
    /* xs - stateful.locator-drawer-text-show-active */
    /* xs - stateful.locator-drawer-bg-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle .caret {
    /* lg - tag.locator-drawer-arrow */
  }

  .locator {
    /* lg - home | locator.locator-bg-1 */
    /* lg - locality [city].locator-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .locator .container {
    /* lg - home | locator.locator-bg-2 */
    /* lg - locality [city].locator-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .geolocate-input {
    /* lg - home | locator.locator-start-box */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .start-label {
    /* lg - home | locator.locator-start-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
  }

  #start-geolocate .location-holder .btn {
    /* lg - home | locator.locator-start-locate-anchor-text important */
    /* lg - home | locator.locator-start-locate-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geolocate .location-holder .btn:hover {
    /* xs - stateful.locator-start-locate-anchor-text-hover important */
    /* xs - stateful.locator-start-locate-button-hover */
  }

  #start-geolocate .location-holder .btn:active {
    /* xs - stateful.locator-start-locate-anchor-text-active important */
    /* xs - stateful.locator-start-locate-button-active */
  }

  #start-geolocate .geolocate-error-wrapper {
    /* xs - messages.locator-error-text */
    /* xs - messages.locator-error-bg */
  }

  #start-geocoder {
    /* lg - home | locator.locator-start-divider */
    background: transparent;
    border-color: #bbbbbb;
    border-width: 2px;
  }

  #start-geocoder input {
    /* lg - home | locator.locator-start-search-field */
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geocoder input::placeholder {
    /* lg - home | locator.locator-start-search-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  #start-geocoder .geocoder-pin-far-right .btn-search {
    /* lg - home | locator.locator-start-search-icon color */
    /* lg - home | locator.locator-start-search-button */
    color: #ffffff;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:hover {
    /* xs - stateful.locator-start-search-button-hover */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:hover::before {
    /* xs - stateful.locator-start-search-icon-hover */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:active {
    /* xs - stateful.locator-start-search-button-active */
  }

  #start-geocoder .geocoder-pin-far-right .btn-search:active::before {
    /* xs - stateful.locator-start-search-icon-active */
  }

  #location-list {
    /* lg - locality [city].locator-sm-xs-bg-1 */
    /* lg - locality [city].list-location-bg-inactive */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #location-list.error {
    /* xs - messages.locator-sm-xs-bg-1 */
  }

  #location-list p.no-locations {
    /* xs - messages.list-error-text */
    /* xs - messages.list-error-bg */
  }

  #location-list .telephone a:hover {
    /* xs - stateful.location-detail-phone-number-hover */
  }

  #location-list .telephone a:active {
    /* xs - stateful.location-detail-phone-number-active */
  }

  #list-panel .place {
    /* lg - locality [city].list-location-address */
    /* lg - locality [city].locator-sm-xs-bg-2 */
    /* lg - locality [city].list-location-bg-inactive */
    /* lg - locality [city].list-location-hr border */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #list-panel .place.active {
    /* lg - locality [city].list-location-bg-active */
    background: #eeeeee;
  }

  #list-panel .place.active:hover {
    /* lg - locality [city].list-location-city-state-zip */
    /* lg - locality [city].list-location-bg-active */
    /* lg - locality [city].list-location-hr border */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    background: #eeeeee;
  }

  #list-panel .place:hover {
    /* lg - locality [city].list-location-city-state-zip */
    /* lg - locality [city].list-location-bg-active */
    /* lg - locality [city].list-location-hr border */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    background: #eeeeee;
  }

  #list-panel .place strong.name a {
    /* lg - locality [city].list-location-name */
    /* lg - locality [city].location-name */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
  }

  #list-panel .place strong.name a:hover {
    /* xs - stateful.location-name-hover */
  }

  #list-panel .place strong.name a:active {
    /* xs - stateful.location-name-active */
  }

  #list-panel .place .hours {
    /* lg - locality [city].list-location-hours-today-hours */
    /* lg - locality [city].location-hours-today */
  }

  #list-panel .place .hours dt {
    /* lg - locality [city].list-location-hours-today */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
  }

  #list-panel .place .hours dd a {
    /* xs - stateful.list-location-special-hours-today */
  }

  #list-panel .place .hours dd a:hover {
    /* xs - stateful.list-location-special-hours-today-hover */
  }

  #list-panel .place .hours dd a:active {
    /* xs - stateful.list-location-special-hours-today-active */
  }

  #list-panel .place .btn {
    /* lg - locality [city].list-location-cta-anchor-text important */
    /* lg - locality [city].list-location-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #list-panel .place .btn:hover {
    /* xs - stateful.list-location-cta-anchor-text-hover important */
    /* xs - stateful.list-location-cta-button-hover */
  }

  #list-panel .place .btn:active {
    /* xs - stateful.list-location-cta-anchor-text-active important */
    /* xs - stateful.list-location-cta-button-active */
  }

  #location-list a {
    /* lg - locality [city].list-location-secondary-anchor-text */
  }

  #location-list a.list-location-phone-number {
    /* lg - locality [city].list-location-phone-number */
    color: #007ac4;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
  }

  #location-list a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #location-list a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  #list-panel .icons a {
    /* lg - locality [city].locator-icon-map-anchor-text */
    /* lg - locality [city].xs-locator-icon-anchor-text-# */
  }

  #list-panel .icons a:hover {
    /* xs - stateful.xs-locator-icon-anchor-text-#-hover */
  }

  #list-panel .icons a:active {
    /* xs - stateful.xs-locator-icon-anchor-text-#-active */
  }

  #list-panel .pager {
    /* lg - locality [city].locator-pager-text-bg */
    /* lg - locality [city].locator-pager-left-bg radius */
  }

  #list-panel .pager li.col-xs-6 {
    /* lg - locality [city].locator-pager-text */
  }

  #list-panel .pager a {
    /* lg - locality [city].locator-pager-left height */
    /* lg - locality [city].locator-pager-left-bg height */
  }

  #list-panel .pager a:hover {
    /* xs - stateful.location-cta-anchor-text-hover */
    /* xs - stateful.locator-pager-left-bg-hover */
  }

  #list-panel .pager a:active {
    /* xs - stateful.location-cta-anchor-text-active */
    /* xs - stateful.locator-pager-left-bg-active */
  }

  #list-panel .pager .disabled a {
    /* xs - stateful.locator-pager-left-disabled */
    /* xs - stateful.locator-pager-left-bg-disabled */
  }

  #list-panel .place .links a {
    /* lg - locality [city].list-location-anchor-text-# */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
  }

  #list-panel .place .links a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #list-panel .place .links a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  #list-panel .place .secondary a {
    /* lg - locality [city].list-location-secondary-anchor-text */
  }

  #list-panel .place .secondary a:hover {
    /* xs - stateful.list-location-secondary-anchor-text-hover */
  }

  #list-panel .place .secondary a:active {
    /* xs - stateful.list-location-secondary-anchor-text-active */
  }

  .sk-circle .sk-child::before {
    /* xs - messages.loading-animation-color background */
  }

  #map-panel-holder .mapboxgl-popup-content {
    /* lg - locality [city].tooltip-location-address */
    /* lg - locality [city].tooltip-bubble */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 11px;
    line-height: 14px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 8px 8px 8px 0px 0px 0px 8px;
  }

  #map-panel-holder .mapboxgl-popup-content a {
    /* lg - locality [city].tooltip-location-name */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 11px;
    line-height: 16px;
    font-weight: normal;
  }

  #map-panel-holder .mapboxgl-popup-content a:hover {
    /* xs - stateful.tooltip-location-name-hover */
  }

  #map-panel-holder .mapboxgl-popup-content a:active {
    /* xs - stateful.tooltip-location-name-active */
  }

  #map-panel-holder .mapboxgl-popup-content dt {
    /* lg - locality [city].tooltip-location-hours-today */
  }

  #map .mapboxgl-popup-close-button::before {
    /* lg - locality [city].tooltip-close */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
  }

  #map .mapboxgl-popup-close-button:hover::before {
    /* xs - stateful.tooltip-close-hover */
  }

  #map .mapboxgl-popup-close-button:active::before {
    /* xs - stateful.tooltip-close-active */
  }

  #location-search {
    /* lg - locality [city].locator-search-bg height */
    /* lg - locality - map shown.locator-search-bg */
  }

  #location-search .mapboxgl-ctrl-geocoder input {
    /* lg - locality [city].locator-search-field */
    /* lg - locality - map shown.locator-search-field */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  #location-search .mapboxgl-ctrl-geocoder input::placeholder {
    /* lg - locality [city].locator-search-placeholder-text */
    /* lg - locality - map shown.locator-search-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  #location-search .btn-search {
    /* lg - locality [city].locator-search-button height */
    /* lg - locality - map shown.locator-search-button height */
    /* lg - locality [city].locator-search-icon color */
    /* lg - locality - map shown.locator-search-icon color */
    background: #cc0000;
    min-height: 50px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    color: #ffffff;
  }

  #location-search .btn-search:hover {
    /* xs - stateful.locator-start-search-icon-hover important */
    /* xs - stateful.locator-start-search-button-hover */
  }

  #location-search .btn-search:active {
    /* xs - stateful.locator-start-search-icon-active important */
    /* xs - stateful.locator-start-search-button-active */
  }

  body > .location {
    /* lg - location.locator-sm-xs-bg-1 */
    /* lg - location.locator-bg-1 */
  }

  body > .location .container {
    /* lg - location.locator-sm-xs-bg-2 */
  }

  body > .location .container > .row > .col-md-5 {
    /* lg - location.locator-sm-xs-bg-2 */
  }

  body > .location .location-detail {
    /* lg - location.location-detail-address */
    /* lg - location.location-detail-bg height */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    background: #ffffff;
    min-height: 560px;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  body > .location .location-detail .additional-info {
    /* lg - location.location-detail-hr */
  }

  body > .location .location-detail .btn {
    /* lg - locality [city].list-location-cta-anchor-text important */
    /* lg - locality [city].list-location-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  body > .location .location-detail .btn:hover {
    /* xs - stateful.location-cta-anchor-text-hover important */
    /* xs - stateful.location-cta-button-hover */
  }

  body > .location .location-detail .btn:active {
    /* xs - stateful.location-cta-anchor-text-active important */
    /* xs - stateful.location-cta-button-active */
  }

  body > .location .location-detail strong.name {
    /* lg - location.location-detail-name */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 27px;
    font-weight: normal;
  }

  body > .location .stars .star-top {
    /* lg - location.rating-stars color */
  }

  body > .location .stars .star-bottom {
    /* lg - location.rating-stars color */
  }

  body > .location a {
    /* lg - location.location-detail-anchor-text-# */
  }

  body > .location a:hover {
    /* xs - stateful.location-detail-anchor-text-#-hover */
  }

  body > .location a:active {
    /* xs - stateful.location-detail-anchor-text-#-active */
  }

  body > .location .telephone a {
    /* lg - location.location-detail-phone-number */
    color: #007ac4;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 21px;
    font-weight: normal;
  }

  body > .location .telephone a:hover {
    /* xs - stateful.location-detail-phone-number-hover */
  }

  body > .location .telephone:active a:active {
    /* xs - stateful.location-detail-phone-number-active */
  }

  body > .location .location-detail .telephone a::before {
    /* lg - location.location-detail-phone-number color */
    color: #007ac4;
  }

  body > .location .location-detail .telephone a:hover::before {
    /* xs - stateful.location-detail-phone-number-hover color */
  }

  body > .location .location-detail .telephone a:active::before {
    /* xs - stateful.location-detail-phone-number-active color */
  }

  body > .location .location-detail .hours dt,
  body > .location .location-detail .hours dd {
    /* lg - location.location-detail-hours */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
  }

  body > .location .location-detail .hours .special::after,
  body > .location .location-detail .special-hours .special::before {
    /* lg - location.location-detail-modified-hours color */
  }

  body > .location .location-detail .special-hours strong {
    /* lg - location.location-detail-modified-hours-header */
  }

  body > .location .location-detail .special-hours dt,
  body > .location .location-detail .special-hours dd {
    /* lg - location.location-detail-modified-hours */
  }

  body > .location .icons a {
    /* lg - location.locator-icon-map-anchor-text */
    /* lg - location.xs-locator-icon-anchor-text-# */
  }

  body > .location .icons a:hover {
    /* xs - stateful.xs-locator-icon-anchor-text-#-hover */
  }

  body > .location .icons a:active {
    /* xs - stateful.xs-locator-icon-anchor-text-#-active */
  }

  .events {
    /* lg - location.events-text-#-# */
    /* lg - location.events-bg-1 */
  }

  .events .container {
    /* lg - location.events-bg-2 */
  }

  .events .container > h2 {
    /* lg - location.events-title-# */
  }

  .events p a {
    /* xs - stateful.events-text-#-link */
  }

  .events p a:hover {
    /* xs - stateful.events-text-#-hover */
  }

  .events p a:active {
    /* xs - stateful.events-text-#-active */
  }

  #events .event {
    /* lg - location.event-text */
    /* lg - location.event-bg */
  }

  #events .event h3,
  #events .event strong {
    /* lg - location.event-price */
  }

  #events .event .event-info > a,
  #events .event h3 > a {
    /* lg - location.event-title */
  }

  #events .event .event-info > a:hover,
  #events .event .event-info > h3 a:hover {
    /* xs - stateful.event-title-hover */
  }

  #events .event .event-info > a:active,
  #events .event .event-info > h3 a:active {
    /* xs - stateful.event-title-active */
  }

  #events .event-performer-info a {
    /* xs - stateful.event-person-link */
  }

  #events .event-performer-info a:hover {
    /* xs - stateful.event-person-hover */
  }

  #events .event-performer-info a:active {
    /* xs - stateful.event-person-active */
  }

  .events .btn {
    /* lg - location.event-cta-anchor-text important */
    /* lg - location.event-cta-button */
  }

  .events .btn:hover {
    /* xs - stateful.event-cta-anchor-text-hover important */
    /* xs - stateful.event-cta-button-hover */
  }

  .events .btn:active {
    /* xs - stateful.event-cta-anchor-text-active important */
    /* xs - stateful.event-cta-button-active */
  }

  .show-more .events-show-more {
    /* lg - location.events-view-more */
    /* lg - location.events-view-more-bg */
  }

  .show-more .events-show-more:hover {
    /* xs - stateful.events-view-more-hover */
    /* xs - stateful.events-view-more-bg-hover */
  }

  .show-more .events-show-more:active {
    /* xs - stateful.events-view-more-active */
    /* xs - stateful.events-view-more-bg-active */
  }

  .show-more .events-show-more i.fa {
    /* lg - location.events-view-more-arrow */
  }

  .show-more .events-show-more:hover i.fa {
    /* xs - stateful.events-view-more-hover */
  }

  .show-more .events-show-more:active i.fa {
    /* xs - stateful.events-view-more-active */
  }

  .offers-grid {
    /* lg - home | locator.site-offers-bg-1 */
    /* lg - location.site-offers-bg-2 */
    /* lg - location.location-offers-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container {
    /* lg - home | locator.site-offers-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .location-offers-grid .container {
    /* lg - location.location-offers-bg-2 */
  }

  .offers-grid .container h2 {
    /* lg - home | locator.site-offers-title */
    /* lg - location.location-offers-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 40px;
    line-height: 44px;
    font-weight: normal;
  }

  .offers-grid .container p {
    /* lg - home | locator.site-offers-text */
    /* lg - location.location-offers-text */
  }

  .offers-grid .container p a {
    /* xs - stateful.site-offers-text-link */
  }

  .offers-grid .container p a:hover {
    /* xs - stateful.site-offers-text-hover */
  }

  .offers-grid .container p a:active {
    /* xs - stateful.site-offers-text-active */
  }

  .offers-grid .container .offers-grid-item {
    /* lg - home | locator.site-offer-bg */
    /* lg - location.location-offer-bg */
    background: #f2f2f2;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-title {
    /* lg - home | locator.site-offer-title */
    /* lg - location.location-offer-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info a:hover .offer-title {
    /* xs - stateful.site-offer-title-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info a:active .offer-title {
    /* xs - stateful.site-offer-title-link-active */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-text {
    /* lg - home | locator.site-offer-text */
    /* lg - location.location-offer-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-price a {
    /* lg - home | locator.site-offer-price */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button {
    /* lg - home | locator.site-offer-cta-anchor-text important */
    /* lg - home | locator.site-offer-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button:hover {
    /* xs - stateful.site-offer-cta-anchor-text-hover important */
    /* xs - stateful.site-offer-cta-button-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-cta-button:active {
    /* xs - stateful.site-offer-cta-anchor-text-active important */
    /* xs - stateful.site-offer-cta-button-active */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-expires {
    /* lg - home | locator.site-offer-expires */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action {
    /* lg - home | locator.site-offer-secondary-action */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action:hover {
    /* xs - stateful.site-offer-secondary-action-hover */
  }

  .offers-grid .container .offers-grid-item .offer-info .offer-secondary-action:active {
    /* xs - stateful.site-offer-secondary-action-active */
  }

  .offers-grid .container .offers-grid-item.image-only {
    /* lg - home | locator.site-offers-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offer-more {
    /* lg - home | locator.site-offers-view-more-bg */
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .offers-grid .container .offer-more .offer-view-more {
    /* lg - home | locator.site-offers-view-more */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
  }

  .offers-grid .container .offer-more .offer-view-more:hover {
    /* xs - stateful.site-offers-view-more-hover */
    /* xs - stateful.site-offers-view-more-bg-hover */
  }

  .offers-grid .container .offer-more .offer-view-more:active {
    /* xs - stateful.site-offers-view-more-active */
    /* xs - stateful.site-offers-view-more-bg-active */
  }

  .offers-grid .container .offer-more .offer-view-more i.fa {
    /* lg - home | locator.site-offers-view-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .offers-grid .container .offer-more .offer-view-more:hover i.fa {
    /* xs - stateful.site-offers-view-more-arrow-hover */
  }

  .offers-grid .container .offer-more .offer-view-more:active i.fa {
    /* xs - stateful.site-offers-view-more-arrow-active */
  }

  .offers-grid .container .panel {
    /* xs - messages.site-offers-no-offers */
    /* xs - messages.site-offers-no-offers-bg */
  }

  .reviews {
    /* lg - location.reviews-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .container {
    /* lg - location.reviews-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .container > h2 {
    /* lg - location.reviews-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 39px;
    font-weight: normal;
  }

  .reviews .container > p {
    /* lg - location.reviews-text */
  }

  .reviews p a {
    /* xs - stateful.reviews-text-link */
  }

  .reviews p a:hover {
    /* xs - stateful.reviews-text-hover */
  }

  .reviews p a:active {
    /* xs - stateful.reviews-text-active */
  }

  .reviews .panel {
    /* lg - location.rating-statistics */
    /* lg - location.rating-bg */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    background: #eeeeee;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .panel .panel-heading .panel-title {
    /* lg - location.rating-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 21px;
    line-height: 24px;
    font-weight: normal;
  }

  .reviews .btn {
    /* lg - location.review-submit-anchor-text important */
    /* lg - location.review-submit-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 16px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .btn:hover {
    /* xs - stateful.review-submit-anchor-text-hover important */
    /* xs - stateful.review-submit-button-hover */
  }

  .reviews .btn:active {
    /* xs - stateful.review-submit-anchor-text-active important */
    /* xs - stateful.review-submit-button-active */
  }

  .reviews #reviews .panel {
    /* lg - location.review-bg */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews #reviews .panel .panel-heading .panel-title {
    /* lg - location.review-title */
  }

  .reviews #reviews .panel .panel-heading p {
    /* lg - location.review-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }

  .reviews .panel .panel-body.no-reviews {
    /* xs - messages.reviews-no-reviews */
    /* xs - messages.reviews-no-reviews-bg */
  }

  .reviews #reviews hr {
    /* lg - location.review-divider-line */
    background: transparent;
    border-color: #979797;
    border-width: 1px;
  }

  .reviews .reviews-show-more {
    /* lg - location.reviews-view-more */
    /* lg - location.reviews-view-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .reviews .reviews-show-more:hover {
    /* xs - stateful.reviews-view-more-hover */
    /* xs - stateful.reviews-view-more-bg-hover */
  }

  .reviews .reviews-show-more:active {
    /* xs - stateful.reviews-view-more-active */
    /* xs - stateful.reviews-view-more-bg-active */
  }

  .reviews .reviews-show-more i.fa {
    /* lg - location.reviews-view-more-arrow */
  }

  .reviews .aggregate .stars .star-top,
  .reviews .aggregate .stars .star-bottom {
    /* lg - location.rating-stars color */
  }

  .reviews #reviews .stars .star-top,
  .reviews #reviews .stars .star-bottom {
    /* lg - location.review-stars */
  }

  #content .content {
    /* lg - home | locator.content-bg-2 */
    /* lg - home | locator.content-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .content .container {
    /* lg - home | locator.content-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .content .container p {
    /* lg - home | locator.content-text-#-# */
  }

  .content .container p a {
    /* xs - stateful.content-text-#-#-link */
  }

  .content .container p a:hover {
    /* xs - stateful.content-text-#-#-hover */
  }

  .content .container p a:active {
    /* xs - stateful.content-text-#-#-active */
  }

  .content .container h1,
  .content .container h2 {
    /* lg - home | locator.content-title-#-# */
    /* lg - home | locator.content-title-# */
  }

  .content .container .btn-danger {
    /* lg - home | locator.content-cta-anchor-text-# important */
    /* lg - home | locator.content-cta-button */
  }

  .content .container .btn-danger:hover {
    /* xs - stateful.content-cta-anchor-text-#-hover important */
    /* xs - stateful.content-cta-button-hover */
  }

  .content .container .btn-danger:active {
    /* xs - stateful.content-cta-anchor-text-#-active important */
    /* xs - stateful.content-cta-button-active */
  }

  #content .legal {
    /* lg - home | locator.content-legal-bg-1 */
  }

  #content .legal .container {
    /* lg - home | locator.content-legal-bg-2 */
  }

  #content .legal .container .content-legal-text {
    /* lg - home | locator.content-legal-text-# */
    /* lg - home | locator.content-legal-hr border */
  }

  #content .legal .container .content-legal-text a {
    /* xs - stateful.content-legal-text-#-link */
  }

  #content .legal .container .content-legal-text a:hover {
    /* xs - stateful.content-legal-text-#-hover */
  }

  #content .legal .container .content-legal-text a:active {
    /* xs - stateful.content-legal-text-#-active */
  }

  .body-cta {
    /* lg - home | locator.body-cta-bg-1 */
    background: transparent;
    border-width: 0px;
  }

  .body-cta .container {
    /* lg - home | locator.body-cta-bg-2 */
  }

  .body-cta h2 {
    /* lg - home | locator.body-cta-title */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 40px;
    line-height: 44px;
    font-weight: normal;
  }

  .body-cta p {
    /* lg - home | locator.body-cta-text */
  }

  .body-cta p a {
    /* xs - stateful.body-cta-text-link */
  }

  .body-cta p a:hover {
    /* xs - stateful.body-cta-text-hover */
  }

  .body-cta p a:active {
    /* xs - stateful.body-cta-text-active */
  }

  .body-cta .btn-danger {
    /* lg - home | locator.body-cta-anchor-text important */
    /* lg - home | locator.body-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #007ac4;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .body-cta .btn-danger:hover {
    /* xs - stateful.body-cta-anchor-text-hover */
    /* xs - stateful.body-cta-button-hover */
  }

  .body-cta .btn-danger:active {
    /* xs - stateful.body-cta-anchor-text-active */
    /* xs - stateful.body-cta-button-active */
  }

  .categories {
    /* lg - home | locator.categories-bg-1 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container {
    /* lg - home | locator.categories-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container h2 {
    /* lg - home | locator.categories-title-3x */
    /* lg - home | locator.categories-title-4x */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 36px;
    line-height: 39px;
    font-weight: normal;
  }

  .categories .container p {
    /* lg - home | locator.categories-text */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
  }

  .categories .container p a {
    /* xs - stateful.categories-text-link */
  }

  .categories .container p a:hover {
    /* xs - stateful.categories-text-hover */
  }

  .categories .container p a:active {
    /* xs - stateful.categories-text-active */
  }

  .categories .container .categories-grid.grid-4 .category-item a > span,
  .categories .container .categories-grid .category-item a > span {
    /* lg - home | locator.category-name */
    /* lg - home | locator.category-name-bg */
    color: #ffffff;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 23px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .categories .container .categories-grid .category-item a:hover > span {
    /* xs - stateful.category-name-hover */
    /* xs - stateful.category-name-bg-hover */
  }

  .categories .container .categories-grid .category-item a:active > span {
    /* xs - stateful.category-name-active */
    /* xs - stateful.category-name-bg-active */
  }

  .secondary-content {
    /* lg - home | locator.secondary-content-text-#-# */
    /* lg - home | locator.content-bg-1 */
    /* lg - locality [city].secondary-content-text-#-# */
    /* lg - locality [city].content-bg-2 */
    /* lg - locality [city].content-bg-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content-drawer .more-information .collapsible-title {
    /* lg - home | locator.secondary-content-drawer-text */
    /* lg - locality [city].secondary-content-drawer-text */
  }

  .secondary-content .container {
    /* lg - home | locator.content-bg-2 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content h2 {
    /* lg - locality [city].secondary-content-title-# */
    /* lg - home | locator.secondary-content-title-# */
  }

  .secondary-content p {
    /* lg - locality [city].secondary-content-text-#-# */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
  }

  .secondary-content p a {
    /* xs - stateful.secondary-content-text-#-#-link */
  }

  .secondary-content p a:hover {
    /* xs - stateful.secondary-content-text-#-#-hover */
  }

  .secondary-content p a:active {
    /* xs - stateful.secondary-content-text-#-#-active */
  }

  .secondary-content .btn-danger {
    /* lg - home | locator.secondary-content-cta-anchor-text-# important */
    /* lg - home | locator.secondary-content-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .secondary-content .btn-danger:hover {
    /* xs - stateful.secondary-content-cta-anchor-text-hover important */
    /* xs - stateful.secondary-content-cta-button-hover */
  }

  .secondary-content .btn-danger:active {
    /* xs - stateful.secondary-content-cta-anchor-text-active important */
    /* xs - stateful.secondary-content-cta-button-active */
  }

  .hasPOS,
  .availableFrom,
  .areaServed,
  .makesOffer,
  .containsPlace {
    /* lg - home | locator.links-bg-1 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .container,
  .availableFrom .container,
  .areaServed .container,
  .makesOffer .container,
  .containsPlace .container {
    /* lg - home | locator.links-bg-2 */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .list-group-item a,
  .availableFrom .list-group-item a,
  .areaServed .list-group-item a,
  .makesOffer .list-group-item a,
  .containsPlace .list-group-item a {
    /* lg - home | locator.links-cities-anchor-text-1 */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 32px;
    font-weight: normal;
  }

  .hasPOS .list-group-item a:hover,
  .availableFrom .list-group-item a:hover,
  .areaServed .list-group-item a:hover,
  .makesOffer .list-group-item a:hover,
  .containsPlace .list-group-item a:hover {
    /* xs - stateful.links-?-hover */
  }

  .hasPOS .list-group-item a:active,
  .availableFrom .list-group-item a:active,
  .areaServed .list-group-item a:active,
  .makesOffer .list-group-item a:active,
  .containsPlace .list-group-item a:active {
    /* xs - stateful.links-?-active */
  }

  .hasPOS .location-nearby .location-nearby-name,
  .availableFrom .location-nearby .location-nearby-name {
    /* lg - locality [city].location-nearby-name */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-name:hover,
  .availableFrom .location-nearby .location-nearby-name:hover {
    /* xs - stateful.location-nearby-name-hover */
  }

  .hasPOS .location-nearby .location-nearby-name:active,
  .availableFrom .location-nearby .location-nearby-name:active {
    /* xs - stateful.location-nearby-name-active */
  }

  .hasPOS .location-nearby .location-nearby-address,
  .hasPOS .location-nearby .location-nearby-directions,
  .availableFrom .location-nearby .location-nearby-address,
  .availableFrom .location-nearby .location-nearby-directions {
    /* lg - locality [city].location-nearby-address */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-directions,
  .availableFrom .location-nearby .location-nearby-directions {
    /* lg - locality [city].location-nearby-directions */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-directions:hover,
  .availableFrom .location-nearby .location-nearby-directions:hover {
    /* xs - stateful.location-nearby-directions-hover */
  }

  .hasPOS .location-nearby .location-nearby-directions:active,
  .availableFrom .location-nearby .location-nearby-directions:active {
    /* xs - stateful.location-nearby-directions-active */
  }

  .hasPOS .location-nearby .location-nearby-phone-number,
  .availableFrom .location-nearby .location-nearby-phone-number {
    /* lg - locality [city].location-nearby-phone-number */
    color: #007ac4;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 20px;
    line-height: 17px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-phone-number:hover,
  .availableFrom .location-nearby .location-nearby-phone-number:hover {
    /* xs - stateful.location-nearby-phone-number-hover */
  }

  .hasPOS .location-nearby .location-nearby-phone-number:active,
  .availableFrom .location-nearby .location-nearby-phone-number:active {
    /* xs - stateful.location-nearby-phone-number-active */
  }

  .hasPOS .location-nearby .location-nearby-hours strong,
  .availableFrom .location-nearby .location-nearby-hours strong {
    /* lg - locality [city].location-nearby-hours-title */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .location-nearby-hours dt,
  .hasPOS .location-nearby .location-nearby-hours dd,
  .availableFrom .location-nearby .location-nearby-hours dt,
  .availableFrom .location-nearby .location-nearby-hours dd {
    /* lg - locality [city].location-nearby-hours */
    color: #666666;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
  }

  .hasPOS .location-nearby .btn,
  .availableFrom .location-nearby .btn {
    /* lg - locality [city].location-nearby-cta-anchor-text important */
    /* lg - locality [city].location-nearby-cta-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .location-nearby .btn:hover,
  .availableFrom .location-nearby .btn:hover {
    /* xs - stateful.location-nearby-cta-anchor-text-hover important */
    /* xs - stateful.location-nearby-cta-button-hover */
  }

  .hasPOS .location-nearby .btn:active,
  .availableFrom .location-nearby .btn:active {
    /* xs - stateful.location-nearby-cta-anchor-text-active important */
    /* xs - stateful.location-nearby-cta-button-active */
  }

  .availableFrom .links-locations-show-more {
    /* lg - locality [city].links-locations-show-more */
    /* lg - locality [city].links-locations-show-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .availableFrom .links-locations-show-more .fa {
    /* lg - locality [city].links-locations-show-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .availableFrom .links-locations-show-more:hover {
    /* xs - stateful.links-locations-show-more-hover important */
    /* xs - stateful.links-locations-show-more-bg-hover */
  }

  .availableFrom .links-locations-show-more:active {
    /* xs - stateful.links-locations-show-more-active important */
    /* xs - stateful.links-locations-show-more-bg-active */
  }

  .hasPOS .links-locations-show-more {
    /* lg - location.links-locations-show-more */
    /* lg - location.links-locations-show-more-bg */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-color: #999999;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .hasPOS .links-locations-show-more .caret {
    /* lg - location.links-locations-show-more-arrow */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 15px;
    font-weight: normal;
  }

  .hasPOS .links-locations-show-more:hover {
    /* xs - stateful.links-locations-show-more-hover important */
    /* xs - stateful.links-locations-show-more-bg-hover */
  }

  .hasPOS .links-locations-show-more:active {
    /* xs - stateful.links-locations-show-more-active important */
    /* xs - stateful.links-locations-show-more-bg-active */
  }

  .email-signup {
    /* lg - home | locator.email-signup-text */
    /* lg - home | locator.email-signup-bg-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .container {
    /* lg - home | locator.email-signup-bg-2 */
    background: transparent;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel {
    /* lg - home | locator.email-signup-box */
    background: #eeeeee;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel a {
    /* xs - stateful.email-signup-text-link */
  }

  .email-signup .panel a:hover {
    /* xs - stateful.email-signup-text-hover */
  }

  .email-signup .panel a:active {
    /* xs - stateful.email-signup-text-active */
  }

  .email-signup .panel .panel-heading h3 {
    /* lg - home | locator.email-signup-title */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
  }

  .email-signup .panel .panel-body form input {
    /* lg - home | locator.email-signup-text */
    /* lg - home | locator.email-signup-field */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel .panel-body form input::placeholder {
    /* lg - home | locator.email-signup-placeholder-text */
    color: #bbbbbb;
    background: transparent;
    border-width: 0px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  .email-signup .panel .panel-body form .btn,
  .email-signup .panel .panel-body .form .btn {
    /* lg - home | locator.email-signup-anchor-text important */
    /* lg - home | locator.email-signup-button */
    color: #ffffff !important;
    background: transparent;
    border-width: 0px;
    font-family: Ultramagnetic;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
    background: #cc0000;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .email-signup .panel .panel-body form .btn:hover,
  .email-signup .panel .panel-body .form .btn:hover {
    /* xs - stateful.email-signup-anchor-text-hover important */
    /* xs - stateful.email-signup-button-hover */
  }

  .email-signup .panel .panel-body form .btn:active,
  .email-signup .panel .panel-body .form .btn:active {
    /* xs - stateful.email-signup-anchor-text-active important */
    /* xs - stateful.email-signup-button-active */
  }

  .footer {
    /* lg - home | locator.footer-bg-1 */
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .footer .container {
    /* lg - home | locator.footer-copyright */
    /* lg - home | locator.footer-bg-2 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
    background: #ffffff;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
  }

  .footer .social-text a {
    /* xs - stateful.footer-social-text-link */
  }

  .footer .social-text a:hover {
    /* xs - stateful.footer-social-text-hover */
  }

  .footer .social-text a:active {
    /* xs - stateful.footer-social-text-active */
  }

  .footer ol.breadcrumb > li,
  .footer ol.breadcrumb > li,
  .footer ol.breadcrumb > li.active {
    /* lg - home | locator.footer-breadcrumbs */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
  }

  .footer ol.breadcrumb > li a {
    /* lg - home | locator.footer-breadcrumbs-x */
  }

  .footer ol.breadcrumb > li a:hover {
    /* xs - stateful.footer-breadcrumbs-hover */
  }

  .footer ol.breadcrumb > li a:active {
    /* xs - stateful.footer-breadcrumbs-active */
  }

  .footer ol.breadcrumb > li::after {
    /* lg - home | locator.footer-breadcrumbs-divider gradient-slash */
  }

  .footer .navbar-nav.social-text {
    /* lg - home | locator.footer-social-text */
  }

  .footer .navbar-nav.social-icons > li a {
    /* lg - home | locator.footer-social-facebook */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 40px;
    line-height: 40px;
    font-weight: normal;
  }

  .footer .navbar-nav.social-icons > li a:hover {
    /* xs - stateful.footer-social-#-hover */
  }

  .footer .navbar-nav.social-icons > li a:active {
    /* xs - stateful.footer-social-#-active */
  }

  .footer .nav > li a {
    /* lg - home | locator.footer-menu-anchor-text-# */
  }

  .footer .menu .navbar-nav > li a:hover {
    /* xs - stateful.footer-menu-anchor-text-#-hover */
  }

  .footer .menu .navbar-nav > li a:active {
    /* xs - stateful.footer-menu-anchor-text-#-active */
  }

  .footer .nav > li.divider {
    /* lg - home | locator.footer-menu-divider gradient-line */
  }

  .footer hr {
    /* lg - home | locator.footer-social-hr */
    /* lg - home | locator.footer-social-divider */
  }

  .footer .columns {
    /* lg - home | locator.footer-menu-hr */
  }

  .footer .columns .navbar-nav strong {
    /* lg - home | locator.footer-menu-header-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
  }

  .footer .columns .navbar-nav > li a {
    /* lg - home | locator.footer-menu-anchor-text-1-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 15px;
    font-weight: normal;
  }

  .footer .columns .navbar-nav > li a:hover {
    /* xs - stateful.footer-menu-anchor-text-#-#-hover */
  }

  .footer .columns .navbar-nav > li a:active {
    /* xs - stateful.footer-menu-anchor-text-#-#-active */
  }

  .footer .menu ul > li::after,
  .footer ul.disclaimers > li::after {
    /* lg - home | locator.footer-disclaimers-divider gradient-line */
  }

  .footer .disclaimers > li a {
    /* lg - home | locator.footer-disclaimers-anchor-text-# */
    /* lg - home | locator.footer-disclaimers-anchor-text-1 */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
  }

  .footer .disclaimers > li a:hover {
    /* xs - stateful.footer-disclaimers-anchor-text-#-hover */
  }

  .footer .disclaimers > li a:active {
    /* xs - stateful.footer-disclaimers-anchor-text-#-active */
  }

  .footer .navbar-text > p {
    /* lg - home | locator.footer-legal */
  }

  .footer .navbar-text.legal {
    /* lg - home | locator.footer-copyright */
    color: #333333;
    background: transparent;
    border-width: 0px;
    font-family: Avenir;
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
  }

  .footer .navbar-text.legal a {
    /* xs - stateful.footer-copyright-link */
  }

  .footer .navbar-text.legal a:hover {
    /* xs - stateful.footer-copyright-hover */
  }

  .footer .navbar-text.legal a:active {
    /* xs - stateful.footer-copyright-active */
  }

  .footer .navbar-text.legal p a {
    /* xs - stateful.footer-legal-link */
  }

  .footer .navbar-text.legal p a:hover {
    /* xs - stateful.footer-legal-hover */
  }

  .footer .navbar-text.legal p a:active {
    /* xs - stateful.footer-legal-active */
  }

  .team .container {
    /* lg - location.team-bg-2 */
  }



  .team .container .team-more .team-view-more {
    /* xs - stateful.team-view-more */
    /* xs - stateful.team-view-more-bg */
  }

  .team .container .team-more .team-view-more:hover {
    /* xs - stateful.team-view-more-hover */
    /* xs - stateful.team-view-more-bg-hover */
  }

  .team .container .team-more .team-view-more:active {
    /* xs - stateful.team-view-more-active */
    /* xs - stateful.team-view-more-bg-active */
  }

  .team .container .team-more .team-view-more i.fa {
    /* xs - stateful.team-view-more-arrow */
  }

  .team .container .team-more .team-view-more:hover i.fa {
    /* xs - stateful.team-view-more-arrow-hover */
  }

  .team .container .team-more .team-view-more:active i.fa {
    /* xs - stateful.team-view-more-arrow-active */
  }

  .team .container .team-title {
    /* lg - location.team-title-# */
  }

  .team .container .team-text {
    /* lg - location.team-text-#-# */
  }

  .team .container .team-grid .team-item {
    /* lg - location.team-member-bg */
  }

  .team .container .team-grid .team-item .team-picture {
    /* lg - location.team-member-image */
  }

  .team .container .team-grid .team-item .team-name {
    /* lg - location.team-member-name */
  }

  .team .container .team-grid .team-item .team-job-title {
    /* lg - location.team-member-title */
  }

  .team .container .team-grid .team-item .team-work-location {
    /* lg - location.team-member-location */
  }

  .team .container .team-grid .team-item .team-description {
    /* lg - location.team-member-description */
  }

  .team .container .team-grid .team-item .team-telephone {
    /* lg - location.team-member-additional-info */
  }

  h2 > a.dropdown-toggle {
    /* lg - home | locator.links-cities-title */
    color: #cc0000;
    background: transparent;
    border-width: 0px;
    font-family: FontAwesome;
    font-size: 26px;
    line-height: 33px;
    font-weight: normal;
  }

  .navbar-collapse.in .menus,
  .navbar.collapsing .menus {
    /* xs - stateful.menu-dropdown-top-bg */
  }



  .dropdown-toggle:hover .caret {
    /* sm - stateful.links-?-arrow-hover */
  }

  .dropdown-toggle:active .caret {
    /* sm - stateful.links-?-arrow-active */
  }

  .container > h2 > a.dropdown-toggle:hover {
    /* sm - stateful.links-?-title-hover */
  }

  .container > h2 > a.dropdown-toggle:active {
    /* sm - stateful.links-?-title-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover {
    /* sm - stateful.locator-drawer-text-hover */
    /* sm - stateful.list-bg-2-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:hover .caret {
    /* sm - stateful.locator-drawer-arrow-hover */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active {
    /* sm - stateful.locator-drawer-text-active */
    /* sm - stateful.list-bg-2-active */
  }

  .locator-drawer .container h2 > a.dropdown-toggle:active .caret {
    /* sm - stateful.locator-drawer-arrow-active */
  }

  .team {
    /* lg - location.team-bg-1 */
  }

  .top .navbar-right p.utility a {
    /* md - stateful.top-text-link */
  }

  .top .navbar-right p.utility a:hover {
    /* md - stateful.top-text-hover */
  }

  .top .navbar-right p.utility a:active {
    /* md - stateful.top-text-active */
  }

  .top .navbar-right .navbar-nav.links > li > a:hover {
    /* md - stateful.top-anchor-text-#-hover */
  }

  .top .navbar-right .navbar-nav.links > li > a:active {
    /* md - stateful.top-anchor-text-#-active */
  }

  .top .navbar-right .navbar-nav.utility > li > a:hover {
    /* md - stateful.top-utility-anchor-text-#-hover */
  }

  .top .navbar-right .navbar-nav.utility > li > a:active {
    /* md - stateful.top-utility-anchor-text-#-active */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a {
    /* md - stateful.submenu-anchor-text-#-# */
    /* md - stateful.submenu-button */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:hover {
    /* md - stateful.submenu-anchor-text-#-#-hover */
    /* md - stateful.submenu-button-hover */
  }

  .navbar-collapse ul.navbar-nav .open .dropdown-menu > li > a:active {
    /* md - stateful.submenu-anchor-text-#-#-active */
    /* md - stateful.submenu-button-active */
  }
}