.service-area {
  color: #0f0;
  font-size: 14px;
  line-height: 21px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
}

.service-area h1 {
  color: #0f0;
  padding-top: 0px;
  margin-top: 0px;
}

.service-area .header-h1 {
  padding-top: 0px;
  margin-top: 0px;
}

.service-area .container {
  background: green;
  padding-top: 0px;
}

.service-area .container > .row {
  height: 100%;
  min-width: 100% ;
  border: 0px ;
 }

.service-area .col-md-8,
.service-area .col-md-4 {
  height: 200px;
  vertical-align: middle;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%
}

.service-area .header-detail-below {
  min-width: 100%;
  padding-top: 15px;
  padding-left: 0px;
  padding-right: 0px;
}

.service-area .header-detail-below .container {
  width: 100% ;
  padding: 0px;
  padding-left: 0px;
  padding-bottom: 20px;
}

.service-area .header-detail {
  padding: 20px 20px 0px 20px;
  margin-bottom: 20px;
  height: 100%;
}

.service-area .header-detail-cta,
.service-area .header-detail {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}

.service-area .header-button-container {
  margin-top: 10px;

  @media (min-width: 768px) {
    margin: 15px 0;
  }
}

.service-area .cta {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}
.service-area .header-detail-cta {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  min-height: 100%;
  align-self: center;
}

.service-area .header-detail .image {
  padding-top: 0px;
  padding-bottom: 20px;
}

.service-area .header-detail .image img {
  max-width: 100%;
}

.service-area .header-detail .container {
  padding: 20px;
  background: #ffffff;
  text-align: center;
  border: 2px solid #0f0;

  @media (max-width: 991px) {
    width: 100% !important;
  }
}

.service-area .header-detail-cta .container {
  text-align: center;
  width: 100% !important;
  height: 100%;
  justify-content: center;
}

.service-area .header-detail-cta .container .cta {
  display: flex;
  align-items: center;
}

.service-area .header-detail-cta .container .cta .header-cta {
  width: 100%;
}

.service-area .header-cta-button {
  padding-top: 12px;
  height: 50px;
}

.service-area .map .container .location-detail .image {
  margin-bottom: 15px;
}

.service-area .hours dt {
  display: inline;
}

.service-area .hours dt:after {
  content: ":";
  display: inline;
}

.service-area .hours dd {
  display: inline;
}

.service-area .hours dd:after {
  content: " ";
  display: block;
}

@media (max-width: 767px) {
  .service-area .col-md-8 {
    text-align: center;
    width: 100%;
  }

  .service-area .col-md-4.header-detail {
    padding: 30px 10px 0;
  }

  .service-area h1 {
    padding-top: 0px;
  }
}

@media (max-width: 991px) { 
  .service-area h1 {
    padding-top: 5px;
  }

  .service-area .col-md-8,
  .service-area .col-md-4 {
    padding-top: 15px;
  }
  .service-area .header-detail-cta {
    max-height: 586px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .service-area h1 {
    padding-top: 15px;
    margin-top: 0px;
  }
  
  .service-area .header-detail-cta {
    position: relative;
    float: right;
  }

  .service-area .header-detail {
    padding: 0px;
  }

  .service-area .header-detail {
    padding: 20px 20px 0px 20px;
    float: left;
    width: 50% ;
  }
  
  .service-area .header-detail .container{
    float: right;
    width: 340px;
    max-width: 340px;
  }

  .service-area .col-md-8 {
    @media (min-width: 992px) {
      float: left;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-area {
    text-align: center;
  }

  .service-area .col-md-8 {
    width: 50%;
    float: left;
    text-align: left;
    padding-left: 25px;
  }

  .service-area .col-md-8.full-width {
    width: 100%;
    float: none;
    text-align: center;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .service-area .header-detail-cta {
    position: relative;
    flex: 0 0 350px;
    padding: 0;
    min-height: 0;
    height: auto;
    max-height: none;
    display: flex;
    float: none;
    width: auto;
    align-self: stretch;
  }

  .service-area > .container > .row {
    display: flex;
    height: auto;
  }
  
  .service-area .header-detail {
    padding: 20px 30px 0px 20px;
  }

  .service-area .header-detail .container {
    width: 390px;
    max-width: none;
    min-height: 220px;
    float: none;
  }

  .service-area .col-md-8 {
    flex-grow: 1;
    width: auto;
  }

  .service-area h1 {
    padding-top: 32px;
    margin-top: 0px;
  }

  .service-area .header-button-container .btn {
    margin-top: 4px;
  }
}

@media (min-width: 1200px) { 
  .service-area .header-detail-cta {
    flex:  0 0 390px;
  }

  .service-area h1 {
    padding-top: 42px;
    margin-top: 0px;
  }

  .service-area .header-detail .container {
    width: 370px;
  }
}

