/**
 *
 */

body {
  min-width: 320px;
  font-family: var(--font-text);
  font-size: 14px;
}

a {
  color: var(--xs-link-color);
  text-decoration: underline;

  &.dropdown-toggle {
    text-decoration: none;
  }
}

h1,
h2,
h3 {
  font-family: var(--font-heading);
}

.navbar-brand {
  background: var(--brand-1);

  &.brand-2 {
    background: var(--brand-2);
  }

  &.brand-3 {
    background: var(--brand-3);
  }
}

.btn {
  text-decoration: none;
  text-transform: uppercase;
  border: var(--border-width) solid var(--xs-button-border);
  padding: 1px 39px 3px;
  font-size: 16px;
  line-height: 36px;
  border-radius: 5px;
  white-space: normal;
  font-weight: bold;
}

.btn-default,
.btn-danger {
  background: var(--xs-button-background);
  color: var(--xs-button-color) !important;

  &:hover {
    background: var(--xs-button-hover-background);
    border-color: var(--xs-button-hover-border);
    color: var(--xs-button-hover-color) !important;
  }
}

@media (max-width: 767px) {
  .container {
    > h2 {
      padding: 0;
      margin: 0;

      > a.dropdown-toggle {
        margin: 0 -15px;
        padding: 16px 20px;
      }
    }
  }

  h2 > a.dropdown-toggle {
    display: block;
    border-top: 1px solid var(--xs-drawer-color);
    background: var(--xs-drawer-background);
    color: var(--xs-drawer-color);
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    line-height: 1;

    &::after {
      content: " ";
      display: block;
      clear: both;
    }

    .collapsible-title {
      display: block;
      float: left;
      width: calc(100% - 35px);
    }

    .caret {
      float: right;
      margin-right: 25px;
    }
  }
}

.dropdown-toggle {
  .caret {
    display: inline;
    border: 0;

    &::before {
      font-family: 'FontAwesome';
      content: "\f077";
    }

    @media (min-width: 768px) {
      color: var(--sm-drawer-caret-color);
    }
  }

  &.collapsed {
    .caret::before {
      content: "\f078";
    }
  }
}

.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

/* Must be declared separately */
::-webkit-input-placeholder {
  color: var(--xs-placeholder-color);
  opacity: 1;
}

:-moz-placeholder {
  color: var(--xs-placeholder-color);
  opacity: 1;
}

::-moz-placeholder {
  color: var(--xs-placeholder-color);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--xs-placeholder-color);
  opacity: 1;
}