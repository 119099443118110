.infolist {
  background-color: #0f0;
}


.infolist h2 {
  padding-top: 0px;
  margin-top: 0px;
  line-height: 22px;
}
@media (min-width: 576px) and (max-width: 768px) {
  .infolist p {
    padding-top: 10px;
  }
}

.infolist .container {
  background-color: green;
  color: #0f0;
  padding: 40px 30px;
}



.infolist .container .group-items-title {

    padding-bottom: 5px;
    counter-reset: item;
    color: #000000;
    font-family: "Roboto Slab";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
    color: #0f0;
    margin-bottom: 13px;
    border-bottom:1px solid #0f0;

}

.infolist .container ul {
  list-style: none;
  margin-top: 20px; 
  @media (min-width: 576px) { 
    margin-top: 40px; 
  }
}

.infolist .container ul li.group-item {
  counter-increment: item;
  margin-left: 50px;
  margin-bottom: 25px;
}

.infolist .container ul li.group-item:first-child {
    margin-top: 25px;
}

.infolist .container ul li.group-item div.item-title {
  display: table;
  height: 26px;
  color: #0f0;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 5px;
}

.infolist .container ul li.group-item p {
  color: #0f0;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
}

.infolist .container ul li.group-item:before {
  display: table;
  position: absolute;
  content: counter(item, decimal);
  background-color: #0f0;
  color: green;
  font-size: 16px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  line-height: 36px !important;
  text-align: center;
  margin-left: -45px;
  margin-top: -5px;
  font-weight: bold;
}



@media (max-width: 1200px) { 
  .infolist .container ul {
    padding-left: 20px !important;
  }
}

@media (max-width: 987px) {
  .infolist .container {
    background-color: green;
    color: #0f0;
    padding: 40px 15px;
  }
}

@media (max-width: 575.98px) {
  .infolist .container {
    background-color: green;
    color: #0f0;
    padding: 20px 20px;
  }

  .infolist .container h2 {
    font-family: "Roboto Slab";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 5px;
  }

  .infolist .container p {
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .infolist .container .group-items-title {
    margin-top: 20px;
    font-family: "Roboto Slab";
    font-size: 17px;
    letter-spacing: 0;
    line-height: 21px;
  }


  .infolist .container ul {
    padding-left: 0px !important;
  }

  .infolist .container ul li.group-item:before {
    max-width: 30px;
    max-height: 30px;
    line-height: 30px !important;
    line-height: 30px;
    margin-left: -40px;
    margin-top: -0px;
  }

  .infolist .container ul li.group-item div.item-title {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }

  .infolist .container ul li.group-item p {
    font-family: "Open Sans";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 19px;
  }

  .infolist .container {
    padding-bottom: 0px;
  }

  .infolist .container ul li:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .infolist .container {
    padding-bottom: 4px;
  }

}

@media (min-width: 576px) {
  .infolist .container ul li:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .infolist .container {
    padding-bottom: 34px;
  }
}

@media (max-width: 767px) {
  .infolist .container p {
    padding-top: 0px;
  }

  .infolist .container ul li.group-item div.item-title {
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .infolist .container .group-items-title {
    font-size: 26px;
    line-height: 30px;
  }
}

.infolist .container ul li.group-item div.item-title-not-clickable {
  text-decoration: none;
  cursor: auto;
}

.infolist .container ul li.group-item-not-numbers:before {
  display: table;
  content: " ";
  position: absolute;
  background-color: #0f0;
  color: green;
  font-size: 16px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  line-height: 36px;
  text-align: center;
  margin-left: -35px;
  margin-top: 5px;
  font-weight: bold;
}


