.iframe {
	background: var(--primary);
	color: var(--text);
}


.iframe .panel {
  text-align: center;
  background-color:transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  box-shadow: none;
}


.iframe h2 {
  margin-top: 0px;
}

.iframe .container {
  padding: 20px;
}

.iframe p {
  margin-bottom: 20px;
  margin-top: 6px;
}

@media (min-width: 768px) {
  .iframe .container {
    padding-top: 30px;
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .iframe iframe {
    max-width: 720px;
  }

}

@media (min-width: 992px) {
  .iframe .container {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
  }


  .iframe p {
    margin-bottom: 30px;
  }

  .iframe iframe {
    max-width: 910px;
  }
}  