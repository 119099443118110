.app {
  background-color: green;
  text-align: center;

  .btn {
    text-transform: none;
    padding: 14px 10px;
    min-width: 160px;
    min-height: 50px;
  }

  .container {
    border-style: solid;
  }

  @media (max-width: 767px) {
    .container {
      padding-bottom: 10px;
      padding-top: 30px;
    }

    .app-body p {
      margin-bottom: 14px;
    }

    .btn {
      margin: 0 auto 20px;
    }

    .media-holder {
      max-width: 290px;
      float: none;
      margin: auto;
    }

    .app-center-holder {
      max-width: 200px;
      margin: auto;
      float: none;
    }

    .app-right-holder {
      max-width: 200px;
      margin: auto;
      float: none;
    }

    .section.with-center.no-right.no-media {
      .container {
        padding-top: 10px;
      }
    }
  }

  @media (min-width: 768px) {
    border-style: solid;

    .section.with-center.with-media.no-right {
      .container > .row {
        padding-top: 15px;

        .media-holder {
          padding: 0 0 0 10px;
        }

        .app-body {
          padding-left: 20px;

          .app-center-holder {
            padding: 0 0 0 8px;
          }

          .btn {
            margin: 6px 8px;
            padding: 14px 5px;
            min-width: 150px;
          }
        }

        h2 {
          margin-top: 10px;
        }

        p {
          padding: 0 20px;
        }
      }

      .container .app-center-holder {
        .btn {
          margin: 6px 9px;
          padding: 14px 5px;
          min-width: 150px;
        }
      }
    }

    .section.with-center.no-media {
      .app-body {
        padding-left: 3px;
      }

      .app-center-holder {
        .btn {
          margin: 6px 13px;
          max-width: 150px;
        }
      }
    }

    .app-center-holder {
      .btn {
        max-width: 150px;
      }
    }

    .section.no-center {
      .row {
        padding-top: 10px;

        .app-body {
          padding-top: 25px;
        }

        .media-holder {
          padding-top: 0;
          padding-left: 10px;
        }

        .app-right-holder {
          padding-top: 12px;
          padding-left: 0;
        }
      }
    }

    .app-body h2 {
      margin-bottom: 4px;
      margin-top: 5px;
    }

    .container > .row {
      padding: 20px 10px;
    }

    .section.with-center.with-media.with-right {
      .container > .row {
        padding-bottom: 30px;
      }
    }

    .media-holder {
      min-width: 175px;
      padding-left: 10px;
      padding-top: 10px;
    }

    .app-right-holder {
      padding-top: 30px;

      .btn {
        margin: 10px 0;
      }
    }

    .section.no-center.with-media.with-right {
      .container > .row {
        padding: 13px 0;

        .app-body {
          padding-top: 22px;
        }

        .app-right-holder {
          padding-top: 17px;
        }
      }
    }

    @media (max-width: 991px) {
      .app-body {
        padding-right: 5px;
        padding-left: 20px;
      }

      .app-right-holder {
        padding-left: 0;
      }
    }
  }

  @media (min-width: 992px) {
    .section.no-center.with-media.with-right {
      .container > .row {
        padding: 8px 0;

        .media-holder {
          padding-left: 11px;
          margin-right: -5px;
        }

        .app-body {
          padding: 0;

          h2 {
            margin-top: 45px;
          }

          p {
            padding: 0;
          }
        }

        .app-right-holder {
          padding: 15px 30px 0 0;
        }
      }
    }

    .section.with-center.with-media.no-right {
      .container > .row {
        padding: 8px 0;

        .media-holder {
          padding-left: 0;
        }

        .app-body {
          padding: 0 25px 0 0;

          h2 {
            margin-top: 15px;
          }

          p {
            padding: 0;
          }

          .app-center-holder {
            padding: 0 20px;
          }

          .btn {
            margin: 5px 13px;
            min-width: 160px;
          }
        }
      }
    }

    .section.with-center.with-media.with-right {
      .container > .row {
        padding: 8px 0;

        .media-holder {
          padding-top: 0;
          margin-right: -5px;
        }

        .app-right-holder {
          padding: 15px 30px 0 0;
        }

        .app-body {
          margin-top: 12px;
          padding: 0;

          p {
            margin-bottom: 15px;
          }
        }
      }
    }

    .section.with-center.no-right.no-media {
      .app-body {
        padding: 0;

        h2 {
          padding-right: 5px;
        }

        p {
          padding-right: 5px;
        }
      }

      .btn {
        margin: 6px 15px;
      }
    }

    .app-right-holder {
      max-width: 190px;
      float: right;
    }

    .app-body {
      min-width: 520px;
    }
  }

  @media (min-width: 1200px) {
    .section.with-center.with-media.with-right {
      .container > .row {
        padding: 0;
        margin: 8px 0 8px 45px;

        .media-holder {
          margin-right: 0;
          margin-left: 32px;
          padding: 0;
          max-width: 200px;
        }

        .app-right-holder {
          margin: 15px 69px 0 0;
          padding: 0;
        }

        .app-body {
          min-width: 585px;
          margin-top: 12px;
          padding: 0 10px;
          margin-left: 10px;
        }
      }
    }

    .section.no-center.with-media.with-right {
      .container > .row {
        padding: 8px 70px 8px 60px;

        .app-body {
          min-width: 0;
          margin-left: 17px;
        }
      }
    }

    .section.with-center.no-right.no-media {
      .container {
        padding-top: 11px;
        padding-bottom: 10px;
      }
    }

    .section.with-center.with-media.no-right {
      .container > .row {
        padding: 8px 0 8px 40px;

        .media-holder {
          padding-left: 20px;
        }

        .app-body {
          padding: 0 35px 0 0;

          h2 {
            margin-bottom: 5px;
            margin-right: 30px;
          }

          .app-center-holder {
            padding: 0 33px 0 0;
          }

          .btn {
            margin: 5px 13px;
          }
        }
      }
    }

    .container > .row {
      padding: 8px 90px;
    }
  }
}
