:root {
  --btn-width: 60px;
}

#location-search {
  padding: 13px;
  background: var(--xs-search-background);
  border: var(--border-width) solid var(--xs-search-border);

  @media (min-width: 768px) {
    padding: 8px 18px;
  }

  @media (min-width: 992px) {
    padding: 18px 22px 18px 24px;
  }

  @media (min-width: 1200px) {
    padding: 18px 107px 18px 109px;
  }

  .mapboxgl-ctrl-geocoder {
    border-radius: 0;
    box-shadow: none;
    text-align: left;
    position: relative;
    width: 100%;
    max-width: none;
    min-width: 0;
    background: transparent;

    &::before {
      display: none;
      line-height: 50px;
      font-size: 16px;
      color: var(--sm-search-color);
      position: absolute;
      text-align: center;
      font-weight: 500;
      width: 144px;
      left: -144px;
    }

    &::after {
      clear: both;
      content: " ";
      display: block;
    }

    input {
      height: 50px;
      font-size: 13px;
      font-family: 'Verdana';
      padding: 15px;
      width: calc(100% - var(--btn-width));
      display: block;
      float: left;
      border: 1px solid var(--xs-search-input-border);
      border-right: 0;
      background: var(--xs-search-input-background);

      &::-ms-clear {
        display: none;
      }
    }

    .geocoder-pin-right {
      position: relative;
      display: inline-block;

      .geocoder-icon-close {
        top: -18px;
        left: -25px;
        display: none;
      }

      .geocoder-icon-loading {
        top: -18px;
        right:  8px;
      }
    }

    > div {
      display: inline-block;
    }
  }

  .suggestions {
    width: calc(100% - 56px - 2 * var(--border-width));
  }

  .btn-search {
    background: var(--xs-search-button-background);
    color: var(--xs-search-button-color);
    width: var(--btn-width);
    font-family: OpenSans, 'Open Sans', Arial, sans-serif;
    border-radius: 0 10px 10px 0;
    border: var(--border-width) solid var(--xs-search-button-border);
    font-size: 0;
    padding: 10px 16px 12px 17px;
    line-height: 0;

    &::before {
      font-family: FontAwesome;
      content: '\f002';
      font-size: 24px;
      line-height: 24px;
      display: inline-block;
    }

    &:hover {
      background: var(--xs-search-button-hover-background);
      border-color: var(--xs-search-button-hover-border);
      color: var(--xs-search-button-hover-color);
    }
  }
}

