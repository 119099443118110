/*
 * Custom CSS for client specific overrides
 */

/*
 *root.css
 */

@font-face {
  font-family: 'ultramagnetic-light';
  src: url("/assets/dist/fonts/UltraMagneticLight.eot");
  src: url("/assets/dist/fonts/UltraMagneticLight.eot?#iefix") format("embedded-opentype"), url("/assets/dist/fonts/UltraMagneticLight.ttf") format("truetype"), url("/assets/dist/fonts/UltraMagneticLight.woff2") format("woff2"), url("/assets/dist/fonts/UltraMagneticLight.woff") format("woff");
}

@font-face {
  font-family: Ultramagnetic;
  src: url("/assets/dist/fonts/UltraMagneticLight.eot");
  src: url("/assets/dist/fonts/UltraMagneticLight.eot?#iefix") format("embedded-opentype"), url("/assets/dist/fonts/UltraMagneticLight.ttf") format("truetype"), url("/assets/dist/fonts/UltraMagneticLight.woff2") format("woff2"), url("/assets/dist/fonts/UltraMagneticLight.woff") format("woff");
}

body {
  font-family: Arial, sans-serif;
}

body div.legal div ul li::marker {
  color: transparent;
}

.btn {
  border-radius: 0;
}

.email-signup {
margin-top: 30px;
}


@media (max-width: 767px) {
  h2 > a.dropdown-toggle {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 21px;
  }
}

/*
 * 404.hbs
 */
 
 div.not-found {
  h1 {
    font: 300 32px/30px 'ultramagnetic-light';
  }
  
  p {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }
 }

/*
 *alert.css
 */

body > .alert {
  background: #cc0000;
  color: #FFFFFF;
      font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 16px;


  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }

  #alert .item {
    padding: 21px 20px 19px;
  }
}

/*
 *body-cta.css
 */

.body-cta {
  background: url('/assets/dist/images/fcac-hwbg-cfna-des.jpg');
  .container {
    padding: 54px 50px;
    background: transparent !important;

    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 52px 30px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 43px 25px;
    }

    @media (max-width: 767px) {
      padding: 20px;
      text-align: center;
    }
  }

  h2 {
    font: 300 24px/27px 'ultramagnetic-light';
    text-transform: uppercase;
    text-shadow: 0 0 6px rgba(0,0,0,0.5);

    @media (min-width: 768px) {
      font-size: 26px;
      line-height: 34px;
      margin: 0;
      padding: 4px 0;
    }

    @media (min-width: 992px) {
      font-size: 26px;
      line-height: 34px;
      padding: 4px 0;
      margin: 0;
    }

    @media (min-width: 1200px) {
      font-size: 26px;
      line-height: 34px;
      margin: 0;
      padding: 6px 0;
    }
  }

  .btn-danger {
    border-radius: 0;
    border-width: 1px;
    font: 300 20px/20px "ultramagnetic-light";
    margin-top: -6px;
    padding: 20px 40px;
    width: 100%;

    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 20px 10px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 20px;
      margin: 0;
      white-space: normal;
    }

    @media (max-width: 767px) {
      padding: 20px;
      margin: 0;
    }
  }
}

/*
 * categories.css
 */

.category-hero img {
  @media (min-width: 768px) {
    min-height: 264px;
  }
}

.category-hero img {
  @media (min-width: 992px) {
    min-height: 330px;
  }
}

.category-hero img {
  @media (min-width: 1200px) {
    min-height: 440px;
  }
}

.category-hero img {
  @media (max-width: 767px) {
    min-height: 154px;
  }
}

.categories {
  .container {
    padding: 40px 30px;

    h2 {
      font: 300 36px/39px 'ultramagnetic-light';

      @media (max-width: 767px) {
        font-size: 21px;
        line-height: 24px;
      }
    }

    p {
      font: normal 18px/26px Avenir, Helvetica, Arial, sans-serif;;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
      }
    }



    .categories-grid {
      .category-item {
        a > span {
          font: 300 20px/23px 'ultramagnetic-light';
          text-transform: uppercase;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 21px;
          }
          @media (min-width: 768px) {
            font-size: 22px;
            line-height: 25px;
          }
          @media (min-width: 992px) {
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

/*
 *category-hero.css
 */
.category-hero {
  .container {
    h3 {
      display: inline-block;
      font: 300 36px/30px 'ultramagnetic-light';

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 27px;
      }
    }

    .location {
      display: inline-block;
      font: normal 16px/20px Avenir, Helvetica, Arial, sans-serif;

      @media (min-width: 768px) {
        margin-left: 20px;
      }
    }

    ul {
      li {
        &:first-child {
          .btn {
            background: var(--contrast);
          }
        }

        .btn {
          background: var(--contrast-text);
          font: 300 16px/21px 'ultramagnetic-light';
          margin: 0 0 5px;
          width: 100%;

          @media (min-width: 768px) {
            padding: 10px;
            font-size: 13px;
            width: 220px;
          }

          @media (min-width: 992px) {
            width: 280px;
            font-size: 16px;
          }

          @media (min-width: 1200px) {
            padding: 19px 10px 18px;
          }
        }
      }
    }

  .button {
    @media (min-width: 768px) {
      width: 270px;
    }

    @media (min-width: 992px) {
      width: 340px;
    }

    @media (min-width: 1200px) {
    }
  }

  .image {
    @media (min-width: 768px) {
      width: 480px;
    }

    @media (min-width: 992px) {
      width: 600px;
    }

    @media (min-width: 1200px) {
      width: 800px;
    }
  }

  }
}

/*
 *content.css
 */

#content > div.content > div > p:nth-child(2) {
  padding-bottom: 30px;
}

.content {
  .container {

    h1,
    h2 {
      font: 300 21px/24px "ultramagnetic-light";

      @media (min-width: 768px) {
        font-size: 36px;
        line-height: 39px;
      }
    }

    p {
      font: normal 18px/26px Avenir, Helvetica, Arial, sans-serif;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
      }

      & + h2 {
        margin-top: 50px;

        @media (min-width: 992px) and (max-width: 1199px) {
          margin-top: 40px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin-top: 30px;
        }

        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }
    }
  }
}


#content .legal {
  display: none;
}
/*
 * email-signup.css
 */


.email-signup {
  .panel {
    .panel-heading {
      h3 {
        font: 300 16px/19px "ultramagnetic-light";

        @media (min-width: 768px) {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }

    .panel-body {
      line-height: 18px;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      .form {

        .btn {
          padding: 14px 30px 14px 31px;
          font: 300 18px/24px "ultramagnetic-light" !important;
        }

        .unsubscribe-link {
          max-width: 100%;
          margin: 0 auto;
          padding: 5px 0 0;
          text-align: center;

          @media (min-width: 768px) {
            max-width: 78%;
            text-align: left;
          }

          @media (min-width: 992px) {
            max-width: 58%;
          }

          @media (min-width: 1200px) {
            max-width: 59%;
          }

          a {
            font: 11px/12px "arial";
          }
          
        }
      }
    }
  }
}

/*
 * app.css
 */

 .app {
  background-color: #E5E5E5;
  border: none;
 }

 .app .container {
  border: none;
 }

 .app .container .row {
  @media (min-width: 768px) {
    padding: 0 0 10px 0;
  }
 }

 .app .app-body h2 {
  @media (max-width: 991px){
  font-family: Ultramagnetic;
  font-size: 25px;
  line-height: 30px;
  font-weight: 300;
  max-width: 440px;
  float: right;
  margin-left: 70px;
    }
  }

  .app .app-body h2 {
    @media (max-width: 768px) {
      padding-top: 15px;
      padding-bottom: 10px;
      max-width: 208px;
      text-align: center;
      margin: auto;
      margin-right: auto;
    }
  }

 .app .app-body h2 {
  @media (min-width: 1200px){
  font-family: Ultramagnetic;
  font-size: 30px;
  line-height: 32px;
  font-weight: 300;
  max-width: 805px;
  float: right;
    }
  }

  .app .section.with-center.with-media.no-right .container .row h2 {
    @media (min-width: 768px){
      margin-left: 100px;
    }
  }



  .app .section.with-center.with-media.no-right .container .row .media-holder {
    @media (min-width: 1200px) {
      margin-left: -20px;
      padding: 0;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .media-holder {
    @media (min-width: 992px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .media-holder {
    @media (max-width: 991px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .app-body p {
    @media (max-width: 991px) {
      max-width: 470px;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
          font-family: Avenir, Helvetica, Arial, sans-serif;
      float: right;
      margin-left: 80px;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .app-body p {
    @media (max-width: 768px){
      float: none;
      display: inline-block;
      margin: auto;
      margin-bottom: 15px;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .app-body .app-center-holder {
    @media (min-width: 992px) {
      margin-left: 55px;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .app-body .app-center-holder {
    @media (max-width: 991px) {
      margin-left: 25px;
      padding-left: 50px;
      padding-top: 10px;
    }
  }
  .app .section.with-center.with-media.no-right .container .row .app-body .app-center-holder {
    @media (max-width: 768px) {
      margin: auto;
      padding: 0;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .app-body p {
    @media (min-width: 992px) {
      max-width: 640px;
      font-size: 15px;
      font-weight: 300;
          font-family: Avenir, Helvetica, Arial, sans-serif;
      line-height: 20px;
      margin-left: 80px;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .app-body p {
    @media (min-width: 1200px) {
      max-width: 805px;
      font-size: 15px;
      font-weight: 300;
          font-family: Avenir, Helvetica, Arial, sans-serif;
      line-height: 20px;
    }
  }

  .row .app-body h2 {
    float: none;
    text-transform: uppercase;
  }

  a.btn.btn-danger.app-center-button:first-child {
    @media (max-width: 767px) {
      padding-top: 15px !important;
      margin-left: 25px !important;
    }
  }

  a.btn.btn-danger.app-center-button:first-child {
    @media (max-width: 991px) {
      margin-right: 10px !important;
    }
  }

  a.btn.btn-danger.app-center-button:nth-child(2) {
    background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/app-store-download.png");
    background-color: transparent;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  a.btn.btn-danger.app-center-button:nth-child(3) {
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }

  a.btn.btn-danger.app-center-button:nth-child(3) {
    @media (max-width: 991px) {
      margin-left: -15px;
    }
  }

  a.btn.btn-danger.app-center-button:nth-child(3) {
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }

  a.btn.btn-danger.app-center-button:nth-child(3) {
    background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/google-play-button.png");
    background-color: transparent;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .app .section.with-center.with-media.no-right .container .row .app-body .btn {
    text-transform: uppercase;
    font-weight: 300;
    font-family: Ultramagnetic;
  }

  .app .section.with-center.with-media.no-right .container .row .app-body .btn{
    @media (max-width: 991px) {
      margin: -2px -4px;
      max-height: 38px !important;
      padding-top: 10px;
    }
  }

  .app .section.with-center.with-media.no-right .container .row .app-body .btn{
    @media (max-width: 768px) {
      margin: 10px 10px;
    }
  }



  .app .section.with-center.no-right.no-media .app-body p {
    max-width: 805px;
    float: right;
  }

  .app .btn {
    @media (max-width: 991px) {
      min-height: 38px;
      width: 130px;
      margin: -5px;
    }
  }

  .app .btn {
    @media (max-width: 768px) {
      min-height: 50px;
      width: 170px;
      margin: 5px 5px;
    }
  }

  .btn {
    line-height: 18px;
  }

/*
 * footer-breadcrumbs.css
 */
.footer {
  ol.breadcrumb {
    @media (min-height: 992px) {
      font-size: 16px;
    }
  }
}

/*
 *footer-columnar.css
 */

.footer {
  &.columnar {
    .container {
      .navbar-brand {
        @media (min-width: 992px) {
          margin-top: 0;
        }
      }
    }
  }
}

/*
 *footer-social.css
 */
.footer {
  .navbar-nav {
    &.social-icons {
      @media (min-width: 992px) {
        padding: 0;
        margin-top: 10px;
      }
    }
  }
}

/*
 *footer.css
 */

.footer {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  
  .container {
    .navbar-brand {
      height: 55px;
      width: 49px;
      background-repeat: no-repeat;
      background-position: right center;

      @media (max-width: 767px) {
        margin: 60px auto 0;
      }
      @media (max-width: 480px) {
        height: 45px;
        width: 40px;
      }
    }
  }

  .navbar-text {
    margin: 20px 0 0;
    &.legal {
      float: none;
      @media (max-width: 767px) {
        margin: 0 0 20px;
        border-bottom: 1px solid var(--xs-footer-rule-color);
        padding: 10px;
      }
    }
  }
}

/*
 *header-cta.css
 */

.header {
  .col-md-4 {
    @media (min-width: 992px) {
      width: 460px;
    }

    @media (min-width: 1200px) {
      width: 510px;
    }
  }

  .cta {
    border-radius: 20px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin: 20px 0;
    }

    @media (min-width: 768px) {
      margin-top: 30px;
    }

    @media (min-width: 992px) {
      margin-top: 65px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    }

    .header-cta {
      padding: 0;
      text-align: center;



      @media (min-width: 992px) {
        text-align: left;
        margin-top: -20px;

        background: url('/assets/dist/images/hero-cta.png') no-repeat bottom left;
        background-size: contain;
        padding: 52px 20px 33px 260px;
      }

      @media (min-width: 1200px) {
        padding: 68px 15px 65px 320px;
        margin-top: -30px;
      }
    }

    strong {
      font-family: "ultramagnetic-light";
      font-size: 30px;
      line-height: 34px;
      text-transform: uppercase;

      @media (min-width: 768px) {
        font-size: 30px;
        line-height: 34px;
      }

      @media (min-width: 992px) {
        font-size: 36px;
        line-height: 40px;
      }
    }

    .btn {
      background: var(--contrast-text);
      border: 1px solid var(--xs-button-border);
      border-radius: 0;
      padding: 20px 25px;
      font-family: "ultramagnetic-light";
      font-size: 20px;
      font-weight: 300;
      margin-top: 10px;

      @media (min-width: 768px) {
        width: 160px;
      }

      @media (min-width: 992px) {
        margin-top: 20px;
      }

      &:hover {
        background: var(--contrast-text);
      }
    }
  }
}

/*
 *header.css
 */

.header-sm {
  h1 {
    font: 300 18px/21px 'ultramagnetic-light';
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 26px;
      line-height: 32px;
      padding: 20px 15px;
    }
  }
}

.header {
  
  @media (min-width: 992px) {
    background-color: transparent;
    background-image: var(--md-header-background);
    background-repeat: no-repeat;
    background-size: cover;
  }
  

  &.no-cta {
    background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/fcac-hero-md-lg.jpg");
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    min-height:440px;
    color: #fff;

    .col-xs-12 {
      padding: 20px 30px 30px;

      h1 {
        color: #FFFFFF;
        font-family: Ultramagnetic;
        font-size: 50px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 55px;
        text-shadow: 0 0 6px  rgba(0,0,0,0.6); 
        max-width:600px;
        text-transform:uppercase; 
        margin-bottom:20px;   

       
      }

      

      p {
        
        color: #FFFFFF;
        font-family: Avenir, Arial, Helvetica, sans-serif;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 30px;
        text-shadow: 0 0 6px rgba(0,0,0,0.6);
        max-width:600px;
        
      
      }

       .header-anchor-button {
        box-sizing: border-box;
        height: 60px;
        width: 300px;
        background-color: #D22125;
        color: #fff;
        border: 0px solid;
        border-radius: 0px;
        font-family: Ultramagnetic;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        padding: 13px 0;
        margin-right: 20px;
        margin-top: 51px;
      }

      



    }

    
  }

  .container {
    background-color: var(--sm-header-container-background);

    @media (min-width: 992px) {
      background-color: var(--md-header-container-background);
    }
  }

  .col-md-8 {
    @media (min-width: 992px) {
      width: 473px;
    }

    @media (min-width: 1200px) {
      width: 660px;
    }

    h1 {
      font-family: "ultramagnetic-light";
      font-weight: 300;
      text-transform: uppercase;
      text-shadow: 0 0 6px rgba(0,0,0,1);

      @media (min-width: 992px) {
        font-size: 42px;
        line-height: 46px;
      }

      @media (min-width: 1200px) {
        font-size: 50px;
        line-height: 55px;
      }
    }

    p {
      text-shadow: 0 0 6px rgba(0,0,0,1);
      font-family: Avenir, Helvetica, Arial, sans-serif;

      @media (min-width: 992px) {
        font-size: 18px;
        line-height: 24px;
      }

      @media (min-width: 1200px) {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}

.header {

  background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/fcac-hero-md-lg.jpg");
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    min-height:440px;
    color: #fff;
}

.header-tag-wrapper {
  @media (min-width: 320px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .col-md-8, .col-xs-12, .header-sm{ 
    background: #FFFFFF;
    min-width: 100%; 
    max-height: 153px;
    padding: 0px;

    h1 {
      color: #333333;
      font-family: "ultramagnetic-light";
      font-weight: 300;
      text-shadow:none;
      @media (min-width: 320px) {
        font-size: 18px;
        line-height: 21px;
      }
      @media (min-width: 768px) {
        font-size: 36px;
        line-height: 39px;
      }
    }
    p {
      color: #333333;
          font-family: Avenir, Helvetica, Arial, sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
      text-shadow:none;
      padding-bottom: 10px;
    
    }
  }
  
  .header, .header-sm {
    background: #FFFFFF;

    .container {
      background: #FFFFFF ;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
    }

    @media (min-width: 992px) { 
      max-height: 169;
    }

    .cta {

      margin-bottom: 0px;
      background: #FFFFFF;
      min-height: 0px;
      margin-top: 0px;
      box-shadow: none;
      strong {
        color: #333333; 
      }
      .header-cta {
        margin-top: 0px;
        background-size: none;
        padding: 0;
        background: none;
        @media (min-width: 768px) {
          text-align: left;
        }
      }
      .btn {
        width: auto;
        background: #c00;
      }
    }
  }


  .header-sm {
    h1 {
      @media (min-width: 320px) {
        text-align: center;
      }
      @media (min-width: 768px) {
        text-align: left;
      }
      padding-left: 0px ;
    }
    .container {
      @media (min-width: 768px) {
        padding-left: 0px;
        margin-left: auto;
      }
    }
  }

  
  .no-cta-sm {
    background: #000;
    color: #FFFFFF;
    .container, h1 {
      background: #000;
      color: #FFFFFF;
      text-align: center;
    }
  }
  
}

.no-cta-wrapper {
  padding-top: 0px;
  padding-bottom: 0px;
  @media (min-width: 992px) {
    padding-bottom: 30px;
  }  
}

/*
 *links.css
 */
.hasPOS,
.availableFrom,
.areaServed,
.makesOffer,
.containsPlace {
  h2 {
  
    font-weight: 300;
    line-height: 33px;

    > a {
      font-family: 'ultramagnetic-light' !important;

      color: #cc0000 !important;

      @media (max-width: 767px) {
        color: #FFFFFF !important;
      }
    }
  }

  .list-group-item {
    a {
      font: normal 14px/32px Avenir, Helvetica, Arial, sans-serif;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .location-nearby {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    .location-nearby-name {
      color: var(--text-darker);
      font: 300 16px/19px 'ultramagnetic-light';
    }

    .location-nearby-directions {
      color: var(--contrast);
      font: normal 13px/17px Avenir, Helvetica, Arial, sans-serif;

      &:hover {
        color: var(--contrast);
      }
    }

    .location-nearby-phone-number {
      color: var(--contrast-text);
      font: 300 17px/20px 'ultramagnetic-light';
      margin: 15px 0 0;

      &:hover {
        color: var(--contrast-text);
      }
    }

    .btn {
      border-radius: 0;
      font: 300 17px/20px 'ultramagnetic-light';
      padding: 10px 12px;
      width: 170px;

      &:hover {
        color: var(--contrast);
      }
    }
  }
  
  .links-locations-show-more {
    border-width: 1px;
    font-size: 16px;
    font-weight: 900;
    line-height: 15px;
    .caret {
      display: block;
      width: 20px;
      height: 20px;
      margin: 0 auto !important;
    }
  }
}

/*
 * location.css 
 */
body > .location {
  .location-detail {
    strong.name {
      font: 300 24px/27px 'ultramagnetic-light';
    }

    .secondary {
      padding-top: 5px;
    }

    .telephone {
      margin: 10px auto 0;
      font: 300 21px/24px 'ultramagnetic-light';

      @media (max-width: 991px) {
        border-left: 1px solid #333;
        display: inline-block;
        font: 300 13px/13px Arial;
        margin: 0 0 0 5px;
        padding-left: 10px;
      }

      &::before {
        color: var(--contrast-text);
        text-decoration: none;

        @media (max-width: 991px) {
          display: none;
        }
      }

      a {
        color: var(--contrast-text);
        text-decoration: none;

        @media (max-width: 991px) {
          color: #333;
        }
      }
    }

    .btn {
      font: 300 18px/20px 'ultramagnetic-light';
      padding: 15px 11px;
    }
    
    .aggregate {
      a {
        color: var(--contrast);
      }
    }
    
    .additional-info {
      .info-3 {
        color: var(--contrast);
      }
    }
  }
}

body .location .location-detail .telephone {
  border-left: none;

  @media (max-width: 991px) {
    margin: 0px;
    padding-left: 0px;
    padding-top: 10px;
  }
}

/*
 *locator-advanced.css
 */

#start-geolocate {
  .geolocate-error-wrapper {
    border-width: 1px;
  }

  .geolocate-input {
    @media (min-width: 768px) {
      background: #fff;
      padding: 34px 40px 38px;
    }
  }

  .start-label {
    font-family: 'ultramagnetic-light';
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;

    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 30px;
      padding: 0 30px 26px;
    }
  }

  .location-holder {
    .btn {
      padding: 15px 25px;
      border-radius: 0;
      font-family: 'ultramagnetic-light';
      font-size: 20px;
      font-weight: 300;
      line-height: 20px;

      &::before {
        margin-right: 3px;
      }
    }
  }
}

#start-geocoder {
  @media (min-width: 768px) {
    border-left: 2px solid var(--sm-locator-divider);
  }

  input {
    height: calc(48px + 2px);
    width: calc(100% - 65px);

    @media (min-width: 768px) {
      width: 240px;
    }
  }

  .geocoder-pin-far-right .btn-search {
    border-radius: 0;
    padding: 12px 18px 14px 20px;
  }
}

/*
 *locator-list.css
 */

#list-panel {
  .place {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    line-height: 18px;

    @media (min-width: 992px) {
      line-height: 15px;
    }

    .locality {
      line-height: 20px;
    }

    strong.name {
      margin-bottom: 6px;

      @media (min-width: 992px) {
        margin-bottom: 9px;
      }

      a {
        font-family: 'ultramagnetic-light';
        font-weight: 300;
        line-height: 21px;

        @media (max-width: 991px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    .telephone {
      margin: 8px 0 0;

      a {
        color: var(--contrast-text);
        font: 300 18px/21px 'ultramagnetic-light';
        text-decoration: none;
      }
    }

    .hours {
      @media (min-width: 992px) {
        font-size: 14px;
      }
    }

    .links {
      line-height: 20px;
      margin-top: 5px;
    }

    .secondary a {
      font-size: 12px;
      line-height: 17px;
    }

    .btn {
      border-radius: 0;
      font: 300 18px/20px 'ultramagnetic-light';
      padding: 10px 11px;
    }
  }

  .icons {
    a {
      color: var(--contrast-text);
    }
  }

  .pager {
    a.left {
      border-radius: 0;

      @media (max-width: 767px) {
        padding: 12px 32px 12px 30px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        padding: 16px 32px 16px 30px;
      }
    }

    a.right {
      border-radius: 0;
      @media (max-width: 767px) {
        padding: 12px 30px 12px 32px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        padding: 16px 30px 16px 32px;
      }
    }
  }
}

/*
 *locator-search.css
 */
#location-search {
  @media (min-width: 1200px) {
    padding: 20px 109px 20px 111px;
  }

  .mapboxgl-ctrl-geocoder {
    input {
      border-width: 0;
    }
  }

  .btn-search {
    padding: 12px 18px 14px 20px;
    border-radius: 0;
  }
}

/*
 *locator.css
 */

.locator-drawer {
  .container {
    h2 {
      & > a.dropdown-toggle {
        background: #c00;
        font-family: 'ultramagnetic-light';
        color: var(--text-lightest);
        @media (min-width: 768px) {
          padding-left: 0px;
        }
        .caret {
          color: var(--text-lightest);
        }
      }
    }
  }
}

#map {
  .mapboxgl-popup-close-button {
    color: var(--text-darkest);
  }

  .mapboxgl-popup-content {
    padding: 11px 34px 11px 12px;
    font: normal 11px/14px Arial;

    a {
      font-size: 11px;
      line-height: 16px;
    }

    dl {
      margin-top: 6px;
    }
  }
}

/*
 *menu.css
 */

.navbar-collapse {
  .container {
    a.navbar-brand {
      margin: 26px 38px 25px 0;

      &:hover,
      &:focus,
      &:hover:focus {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  ul.navbar-nav {
    .open > a,
    .open > a:focus,
    .open .dropdown-menu > li > a,
    & > li > a,
    & > li > a:focus {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 16px;

      @media (min-width: 992px) {
        font-size: 15px;
        font-weight: normal;
        padding: 37px 25px;
      }

      @media (min-width: 1200px) {
        font-size: 16px;
        line-height: 16px;
      }

      &:hover {
        background-color: var(--xs-menu-hover-background);
      }
    }
    
    li.close{
      & > a,
      & > a:focus {
       padding: 7px 0; 
      }
    }

    &.links {
      & > li > a {
        color: #fff;
      }
    }

  }
}

/*
 *offers.css
 */

.offers-grid {
  .container {
    h2 {
      font: 300 40px/44px "ultramagnetic-light";

      @media (max-width: 767px) {
        font-size: 27px;
        line-height: 30px;
      }
    }

    .offers-grid-item {
      @media (max-width: 767px) {
        &::after {
          margin: 20px 0 0;
          padding-bottom: 10px;
        }
      }

      .offer-info {
        a {
          .offer-title {
            font-family: Avenir, Helvetica, Arial, sans-serif;
            font-size: 20px;
            line-height: 24px;
            text-decoration: none;
          }
  
          .offer-text {
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }          
        }

        .offer-price {
          font: 300 21px/24px "ultramagnetic-light";
          display: none;
        }

        .offer-cta-button {
          font: 300 16px/20px "ultramagnetic-light";
          border-width: 0;
          border-radius: 0;
          padding: 15px;
          width: 130px;
        }

        .offer-expires {
          font: normal 10px/12px "Arial";

          @media (max-width: 767px) {
            display: block;
            position: relative;
            left: auto;
          }
        }
      }
    }

    .offer-more {
      @media (max-width: 767px) {
        background: var(--primary-lightest);
        padding-top: 8px;
        border-width: 1px;
        
        .offer-view-more {
          font-family: Avenir, Helvetica, Arial, sans-serif;

        }
        
      }

      .offer-view-more {
        span {
          font-weight: 900;
        }
      }
    }
  }
}

/*
 * reviews.css
 */
.reviews {
  
  h2 {
    font: 300 36px/39px "ultramagnetic-light";
  }

  .panel {
    background: var(--primary-light);
    border-width: 1px;
    
    .panel-heading {
      .panel-title {
        font: 300 21px/24px "ultramagnetic-light";
        padding-bottom: 6px;
      }
    }
    
    .panel-body {
      font-family: Avenir, Helvetica, Arial, sans-serif;
    }
  }

  #reviews {
    .panel {
      background: var(--primary-lightest);
      border: none;
      
      &:last-of-type {
        border: none;
      }
    }

    hr {
      border-width: 1px;
    }

    nav ul.pager {
     background: var(--primary-light);

     li.disabled a {
      color: #999;
     }

    }

  }
  
  .reviews-show-more {
    border-width: 1px;
  }
  
}

/*
 *secondary-content
 **/

.secondary-content {
  h2 {
    font: 300 24px/24px 'ultramagnetic-light';
    @media (min-width: 768px) {
      font-size: 36px;
      line-height: 39px;
    }
  }
  
  p {
    
    font: normal 14px/21px Avenir, Helvetica, Arial, sans-serif;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  
  .btn-danger {
    border-radius: 0;
    font: 300 20px/20px 'ultramagnetic-light';
    padding: 15px 24px 15px;
    width: auto;
  }
}
/*
 *top.css
 */
.top {
  @media (min-width: 992px) {
    background: var(--md-top-background);
    background: -webkit-gradient(top, #fff 0, var(--md-top-background) 100%, #fff 100%);
    background: -webkit-linear-gradient(top, #fff 0, var(--md-top-background) 100%, #fff 100%);
    background: -moz-linear-gradient(top, #fff 0, var(--md-top-background) 100%, #fff 100%);
    background: -ms-linear-gradient(top, #fff 0, var(--md-top-background) 100%, #fff 100%);
    background: linear-gradient(top, #fff 0, var(--md-top-background) 100%, #fff 100%);
  }

  @media (max-width: 991px) {
    background-color: #3E464B;
  }

  .navbar-header {
    padding: 0 15px;
    text-align: center;

    @media (min-width: 768px) and (max-width: 991px){
      float: none;
      padding: 25px 25px 25px 85px;
      text-align: left;
    }

    a.phone {
      text-decoration: none;
      font-family: "ultramagnetic-light";
      font-weight: 300;
      padding: 0;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 40px;
      }

      @media (min-width: 992px) {
        margin-top: 0;
        font-size: 28px;
        line-height: 1;
        background: none;
        padding: 15px 25px;
        border-left: 1px solid #ccc;
        border-radius: 0;
      }
    }

    &.phone {
      .logos {
          padding: 13px 0 12px 0px;
          
          @media (min-width: 768px) {
            padding: 0;
          }
  
        .navbar-brand {
          float: none;
          display: block;
          margin: 0 auto;
          background-image: var(--brand-3);
          background-repeat: no-repeat;
          height: 35px;
          width: 160px;
    
          @media (min-width: 768px) {
            height: 40px;
            width: 230px;
          }
    
          @media (min-width: 992px) {
            display: none;
          }
        }
      }
    }
  }

  .navbar-right {

    margin-top: 0;

    @media (min-width: 768px) and (max-width: 991px) {
      position: relative;
      top: 0;
    }

    &.phone {
      @media (max-width: 767px) {
        display: none;
      }

     @media (max-width: 991px) {
      a.phone {
        color: var(--text-lightest);
      }
     }

      @media (min-width: 992px) {
        line-height: 1;
      }
    }

    .navbar-nav {
      padding: 18px 0 22px;

      &.links > li {
        > a {
          font-family: Avenir, Helvetica, Arial, sans-serif;
          font-size: 16px;
        }
      }
    }
  }
}

.navbar,
.navbar-inverse {
  .navbar-toggle {
    padding: 10px 12px;
  }
}

@media (max-width: 767px) {
  .footer .navbar-text.legal {
    margin-bottom: 100px;
  }
}

.footer .container .navbar-brand {
  background: url('/assets/dist/images/firestone-shield-logo.png');
  width: 49px;
  height: 56px;

  @media (max-width: 767px) {
    position: absolute;
    bottom: 42px;
    right: 18px;
  }

  @media (min-width: 768px) {
    float: right;
    position: relative;
    top: -18px;
    margin-top: 0px
  }
}

.top .navbar-right.phone::before {
  border: none;
}

.locator .container {
  background: inherit;
}
 
.secondary-content > .container > .row > div:nth-child(4) {

  color: #333333 !important; 
  font-family: 'ultramagnetic-light' !important;
  position: relative;
  float: right;
  font-weight: 300;

  .phone {
    color: #CC0000;
  }
 
  @media (min-width: 300px) {
    line-height: 23px;
    font-size: 20px;
    margin-bottom: 20px;
    margin-right: 0px;
    padding-left: 5px;
    position: static;
    text-align: left;
    width: 100%;
  }

  @media (min-width: 768px) {
    line-height: 28px;
    font-size: 24px;
    margin-top: -20px;
    margin-bottom: 20px;
    margin-right: 0px;
    padding-left: 15px;
    position: static;
    text-align: left;
    width: 60%;
  }

  @media (min-width: 992px) {
    line-height: 28px;
    font-size: 24px;
    margin-top: -90px;
    padding-right: 10px;
    margin-bottom: 0px;
    text-align: right;
    width: 40%;
  }

  @media (min-width: 1200px) { 
    line-height: 28px;
    font-size: 28px;
    margin-right: 30px;
    padding-right: 40px;
    margin-top: -100px;
    margin-bottom: 0px;
  }

  .btn-danger, .btn-danger:hover, .btn-danger:focus, .btn-danger:active {

    color: #333333 !important; 
    margin: 0px;
    padding: 0px 10px;
    height: auto;
    font-weight: 300;
    background: transparent;
    box-shadow: none;

    @media (min-width: 200px) {
      line-height: 23px;
      font-size: 20px;
    }
    @media (min-width: 768px) {
      line-height: 28px;
      font-size: 24px;
    }
    @media (min-width: 992px) {
      line-height: 28px;
      font-size: 24px;
    }
    @media (min-width: 1200px) { 
      line-height: 28px;
      font-size: 28px;
    }
  }
  


}


.header.service-area {

  background: #3E464B;
  padding-bottom: 40px;

}

.service-area h1 {
  color: #fff;
}

.header.service-area .header-anchor-button, .header-sm.service-area .header-anchor-button {
  box-sizing: border-box;
  height: 50px;
  width: 300px;
  border: 1px #fff solid;
  color: #fff;
  font-family: 'ultramagnetic-light';
  border-radius: 0px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding: 16px 0;
  margin-top: 15px;
  background: #CC0000;
}

.service-area .header-detail .container {
  padding: 20px;
  background: #ffffff;
  text-align: center;
  border: 2px solid #fff;
  width: 100% !important;
}

.header.service-area .header-location-button, .header-sm.service-area .header-location-button,
.header.service-area #pr-reviewsnippet {

  display: none;
}

.header.service-area .address .secondary a {

  display: none;
}

.header.service-area .address .name  {

  color: #333333;
  font-family: Ultramagnetic;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;

}

.header.service-area .address .locality  {

  color: #000000;
      font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-bottom: 10px;
  
}

.header.service-area .address .telephone a {

  color: #3478BE;
  font-family: Ultramagnetic;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
  
  
}

.header.service-area .hours {

  color: #333333;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
  
}

.navbar.navbar-default.top {

  background: linear-gradient(180deg, #FFFFFF 0%, #E3E3E3 100%);


}

.header.service-area .address .telephone a {

  text-decoration: none;
}

.header.service-area .address .telephone a::before {

  content: "\f095";
  font-family: FontAwesome;
  display: inline;
  color: #007AC4;
  padding-right: 1px;

}


dt {
  font-weight: normal;
}

.header.service-area .service-area .col-md-8 h1 {

  text-shadow: none;

}

.header.service-area .col-md-8 p {
  text-shadow: none;
}

body > .location .location-detail .telephone span{

  text-decoration: none;

}


.header.service-area .header-anchor-button, .header-sm.service-area .header-anchor-button {
 
  display: table;
  padding: 16px 10px;
  height: 50px;
  min-width: 300px;
  line-height: 20px;
  text-transform: uppercase;


}

.header.service-area .header-anchor-button, .header-sm.service-area .header-anchor-button {
  @media (min-width: 992px) {
    float: left;
  }
}


.header.service-area .header-button-container a:nth-child(1):before, .header.service-area .header-button-container a:nth-child(2):before {
  content: "";
  display: none;
}

.header.service-area .header-button-container a:nth-child(2) {
 min-width: 250px;
}


.service-area #header-h1, .header.service-area .col-md-8 p {

  width: 700px;

}

.service-area .header-detail {
  padding: 20px 30px 0px 20px;
  max-width: 375px;
}

@media(max-width: 1198px) {

  .service-area #header-h1, .header.service-area .col-md-8 p {
    width: 466px;
}


}

@media(max-width: 991px) {

  .service-area #header-h1, .header.service-area .col-md-8 p {

    width: 100%;
  
  }

  .header.service-area .header-anchor-button, .header-sm.service-area .header-anchor-button {

    display: table;
    
  }

  .header-sm.service-area .header-anchor-button {
    display: none;
  }

  .header.service-area .container {

    background: transparent;

  }

  .service-area .header-detail .container {

    background: #fff;

  }



  .navbar.navbar-default.top {

    background: #3E464B;
  
  
  }

  .navbar .navbar-toggle, .navbar-inverse .navbar-toggle {

    left: 0;
    top: 0;

  }

  
  .header.service-area .cta {
    margin-bottom: 0px;
    min-height: 140px
  }

  #list-panel .place {
    background: #fff;

  }

  .icons .row .col-xs-3:nth-child(1) a, .icons .row .col-xs-3:nth-child(4) a {
    display: none;
  }

  

}

@media (max-width: 768px){

  .header.service-area .header-anchor-button, .header-sm.service-area .header-anchor-button {

    display: table;
    margin: 20px 20px 20px 0;
    height:  60px;
    line-height: 60px;
    padding: 0px;

  }

  .service-area .header-detail {

    max-width: 100%;
}


}

@media (max-width: 480px){

  .header.service-area .header-button-container a {
    margin: auto auto 10px auto;
   }
   



  .service-area div#header-h1 h1 {

    color: #FFFFFF;
    font-family: Ultramagnetic;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;

  }

  body > .location .location-detail strong.name {

    color: #333333;
    font-family: Ultramagnetic;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;

  }

  
}

.header-button-container {

  display: table;
  margin: auto;
}


.header-button-container a:nth-child(1):before {

  content: "";
  display: inline-block;
  height: 23.65px;
  width: 23.65px;
  position: relative;
  background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/icon-tire-pricing.svg");
  background-repeat: no-repeat;
  background-position:center;
  background-size: contain;
  top: 4px;
      
}

.header-button-container a:nth-child(2):before {

  content: "";
  display: inline-block;
  position: relative;
  height: 25.6px;
  width: 29px;
  background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/icon-schedule-appointment.svg");
  background-repeat: no-repeat;
  background-position:center;
  background-size: contain;
  top: 5px;
      
}

@media (max-width:1200px){

  .header.no-cta .col-xs-12 h1 {
    font-size: 42px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 46px
  }

  .header.no-cta .col-xs-12 p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
  }

  .header.no-cta {
      min-height: 350px;
  }


}

@media (max-width:991px){

  .header-button-container {
    background-color: transparent;
}

.header.no-cta .col-xs-12 .header-anchor-button {
  margin-top: 0;
}

.header .container {
  background-color: transparent;

}

.header.no-cta .col-xs-12 {
  padding: 0px 30px 30px;
}

  .header.no-cta {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 440px;
    background-color: #000;
    color: #fff;
}

  .header.no-cta .col-xs-12 h1 {
    font-size: 42px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 46px
  }

  .header.no-cta .col-xs-12 p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
  }

  .header.no-cta {
      min-height: 0;
  }

  .header-sm .container {
    background-color: transparent;
    padding-top: 230px;
}

.header-sm .container {
  @media (min-width: 768px) and (max-width: 991px) {
    background-image:  url('/assets/dist/images/fcac-hero-sm.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width:  100%;
    margin: 0px;
  }
}

.header-sm .container {
  @media (max-width: 767px) {
    background-image: url('/assets/dist/images/fcac-hero-xs.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

  .header-sm.no-cta-sm  {

    background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/fcac-hero-sm.jpg");
    width: 100%;
    background-position: top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #000;
    min-height: 300px;
    
}

.header-sm.no-cta-sm .header-anchor-button {
  box-sizing: border-box;
  height: 60px;
  width: 300px;
  background-color: #D22125;
  color: #fff;
  border: 0px solid;
  border-radius: 0px;
  font-family: Ultramagnetic;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding: 13px 0;
  margin-right: 20px;
  margin-top: 0px;
}


}

body > .location .location-detail .image img {
    display: none;
}

body > .location .location-detail .image img, body > .location .location-detail .hours {
  @media (max-width: 767px) {
    display: none;
  }
}

.header.service-area {
  @media (max-width: 767px) {
    padding-bottom:  0px;
  }
}

@media (max-width:645px){

  .header-sm .container {
    background-color: transparent;
    padding-top: 230px;
}

  .header-sm.no-cta-sm  {

    background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/fcac-hero-sm.jpg");
    width: 100%;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000;
    min-height: 300px;
    
}

.header-sm.no-cta-sm .header-anchor-button {

  height: 60px;
  width: 290px;
  max-width: 300px;
  margin-left: 20px;
  margin-right: 0;

}


}

@media (max-width:620px){

  .header-sm .container {
    background-color: transparent;
    padding-top: 230px;
  }

  .header-sm.no-cta-sm  {

    background-image: url("https://file-optimizer.s3.amazonaws.com/firestone/assets/fcac-hero-xs.jpg");
    background-size: contain;
    
  }


.header-sm.no-cta-sm .header-anchor-button {

  height: 60px;
  width: 290px !important;
  max-width: 300px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

}


}

@media (max-width: 380px){

  .header-sm .container {
    background-color: transparent;
    padding-top: 200px;
  }

  .header-sm.no-cta-sm {

    min-height: 270px;
}

  .header-button-container {
    width: 100%;
    padding: 0;
}

.header.no-cta .col-xs-12 {
  padding: 0;
}

  .header.no-cta .col-xs-12 .header-anchor-button {
    box-sizing: border-box;
    height: 60px;
    width: 95%;
    background-color: #D22125;
    color: #fff;
    border: 0px solid;
    border-radius: 0px;
    font-family: Ultramagnetic;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    padding: 13px 0;
    margin-right: auto;
    margin-top: 0px;
}


}


