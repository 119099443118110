.iframe {
	background: #ffffff;
}

.iframe .container {
	@media (max-width: 767px) {
		padding: 0px;
	}
}

.p-w-r .pr-snippet div {
	font-family: Arial;
}

.p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars {
	float: none;
}

.p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write a {
	text-decoration: underline;
}

.p-w-r .pr-snippet-rating-decimal {
	border: none;
	font-weight: bold;
}

.p-w-r {
	padding-top: 25px;
	padding-bottom: 10px;
	text-align: center;
}

.p-w-r {
	@media (max-width: 767px) {
		margin: 0px;
	}
}

.p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
	border: none;
}

.p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write {
	margin-top: 0px;
	text-decoration: underline;
	color: #3E464B;
}
.p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-reco, .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-stars, .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-reco-reco, .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-reco-stars {
	text-align: center;
}

.p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write a, .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write a {
	font-family: "Avenir";
	font-size: 12px;
	padding-left: 10px;
}

.p-w-r a {
	color: #3E464B !important;	
}

.p-w-r .pr-review-snapshot {
	background: #EEEEEE !important;
	display: inline-block;
	margin: auto;
	text-align: center;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-header .pr-review-snapshot-header-intro .pr-headline {
	@media (min-width: 992px) {
		font-size: 36px !important;
		line-height: 39px;
		text-transform: uppercase;
		font-family: Ultramagnetic;
		font-weight: 300;
		padding-top: 20px;
		border: none;
	}
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-sort:last-child a {
	display: none;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-header .pr-review-snapshot-header-intro .pr-subheadline .pr-snapshot-logo {
	display: none;
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-faceoff {
	display: none;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple {
	display: inline-block;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block {
	text-align: center;
}

#pr-review-snapshot div section.pr-review-snapshot-block.pr-review-snapshot-block-recommend {
	display: none;
}

#pr-reviewsnippet div section div div.pr-snippet-stars-reco-reco {
	display: none;
}

#pr-rd-sort-by optgroup option:nth-child(6) {
	display: none;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block:first-child {
	@media (min-width: 992px) {
		background: #FFFFFF;
		width: 332px;
		height: 237px;
		margin-right: 5px;
	}
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block:first-child {
	@media (min-width: 1200px) {
		background: #FFFFFF;
		width: 352px;
		height: 237px;
		margin-right: 5px;
	}
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block:last-child {
	@media (min-width: 992px) {
		background: #FFFFFF;
		width: 494px;
		height: 237px;
	}
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block:last-child {
	@media (min-width: 1200px) {
		background: #FFFFFF;
		width: 514px;
		height: 237px;
	}
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets-headline {
	font-size: 24px !important;
	font-weight: 400;
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-review-count {
	font-size: 24px;
	line-height: 24px;
	font-weight: 400 !important;
	color: #3E464B;
	text-transform: uppercase;
}

.p-w-r .pr-accessible-btn, .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a {
	background-color: #CC0000 !important;
	border: 1px solid #FFFFFF !important;
	color: #FFFFFF !important;
	text-transform: uppercase !important;
	font-size: 16px !important;
	font-weight: 300 !important;
	margin-top: 20px !important;
	min-height: 50px !important;
	padding-top: 17px !important;
}

.p-w-r .pr-accessible-btn, .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a {
	@media (max-width: 991px) {
		width: 160px;
		margin-top: 20px;
	}
}

.p-w-r .pr-accessible-btn, .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a:hover {
	background-color: #CC0000;
	border: none;
}

.p-w-r .pr-accessible-btn, .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a:active {
	background-color: #CC0000;
	border: none;
	outline: none;
}

.p-w-r .pr-accessible-btn, .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a:focus {
	background-color: #CC0000;
	border: none;
	outline: none;
	box-shadow: none;
}

.p-w-r .pr-accessible-btn:hover {
	background: #CC0000;
	border: none;
	outline: none;
}

.p-w-r .pr-accessible-btn:active {
	background: #CC0000;
	border: none;
	outline: none;
	box-shadow: none;
}

.p-w-r .pr-accessible-btn:focus {
	background: #CC0000;
	border: none;
	outline: none;
	box-shadow: none;
}

.p-w-r .pr-submit .pr-logo {
	display: none;
}

.p-w-r .pr-submit .pr-powered span {
	display: none;
}

.p-w-r .pr-review-display .pr-review {
	margin-bottom: 15px;
    display: inline-block;
    width: 100%;
    padding-left: 30px;
}

.p-w-r .pr-review-display .pr-review {
	@media (max-width: 991px) {
		margin-top: 0px;
		margin-bottom: 0px;
		border: 1px solid #E5E5E5;
		border-bottom: none;
		padding-top: 20px;
	}
}

.p-w-r .pr-review-display .pr-rd-footer {
	background: transparent;
}

div.pr-review {
	@media (max-width: 767px) {
	border-top: 1px solid #E5E5E5;
	padding-top: 20px;
	}
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
	background-color: #333333;
	margin-top: 20px;
}

.p-w-r .pr-review-display.pr-rd-display-mobile .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
	background-color: #333333;
	margin-top: 20px;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header .pr-rd-review-total {
	float: left;
	color: #ffffff;
	font-family: Avenir;
	font-size: 20px;
	line-height: 27px;
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters {
	background: #333333 !important;
	max-height: 107px;
	border-bottom: 7px solid #CC0000 ;
	padding: 10px 0px;
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters {
	@media (min-width: 768px) {
		margin-bottom: 70px;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters {
	@media (max-width: 767px) {
		margin-bottom: 175px;
		display: inline-block;
		width: 100%;
	}
}

.p-w-r .pr-review-display .pr-review-filter-info-bar .pr-review-filter-headline {
	@media (max-width: 767px) {
		text-align: center;
	}
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header-with-filters .pr-rd-search-container {
	float: right;
}

.p-w-r .pr-review-display.pr-rd-display-mobile .pr-rd-main-header-with-filters .pr-rd-search-container {
	float: left;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header h1 {
	font-size: 20px;
	line-height: 27px;
	color: #FFFFFF;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header h1.pr-review-filter-headline {
	font-size: 14px;
	color: #3E464B;
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort {
	@media (max-width: 991px) {
		font-size: 18px;
		width: 190px;
		font-family: Avenir;
		font-weight: 900;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort {
	@media (min-width: 1200px) {
		font-size: 18px;
		line-height: 18px;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort {
	@media (max-width: 767px) {
		font-size: 15px;
		width: 190px;
		font-family: Avenir;
		font-weight: 900;
		margin-top: 20px;
	}
}


.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort-group {
	@media (max-width: 991px) {
		width: 246px;
		float: right;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort-group {
	@media (max-width: 767px) {
		width: 100%;
		float: none;
		margin-top: 20px;
		margin-bottom: 10px;
		width: 100%;
		text-align: center;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-search-container {
	@media (max-width: 991px) {
		width: 100%;
		float: right;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-total {
	@media (max-width: 991px) {
		float: left;
		color: #ffffff;
		padding-top: 15px;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-total {
	@media (max-width: 767px) {
		text-align: center;
		width: 100%;
		float: none;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-search-container .pr-rd-search-reviews-input .pr-rd-search-reviews-icon-button {
	background-color: #CC0000;
	color: #FFFFFF;
	height: 40px;
	width: 60px;
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-search-container .pr-rd-search-reviews-input input {
	height: 40px !important;
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-search-container {
	@media (max-width: 767px) {
		display: inline-block;
		text-align: center;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-search-container .pr-rd-search-reviews-input .pr-rd-search-reviews-icon-button svg {
	transform: none;
	height: 24px;
	width: 24px;
	margin-left: -5px;
}


.p-w-r .pr-review-display .pr-review-filter-info-bar {
	@media (max-width: 767px) {
		margin-top: 0px;
	}
}

.p-w-r .pr-review-display .pr-review-filter-info-bar {
	@media (min-width: 768px) {
		margin-top: 10px;
		float: left;
	}
}

.p-w-r .pr-review-display .pr-review-filter-info-bar {
	@media (min-width: 992px) {
		margin-top: -130px;
		float: left;
	}
}

.p-w-r .pr-review-display .pr-review-filter-info-bar {
	@media (min-width: 1200px) {
		margin-top: -120px;
		margin-bottom: 20px;
		float: left;
	}
}

.p-w-r .pr-review-display .pr-review-filter-info-bar .pr-filter-btn {
	@media (min-width: 1200px) {
		float: left;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-search-container .pr-rd-search-reviews-input .pr-rd-search-reviews-clear-button {
	display: none;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-search-container {
	float: right;
	margin-top: -5px;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header-with-filters .pr-rd-search-container {
	float: right;
	margin-top: -5px;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
	background: transparent;
	margin-top: 20px;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
	background: transparent;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-header-sorts {
	@media (min-width: 1200px) {
	margin-top: 25px;
	margin-bottom: 60px;
	padding-right: 20px;
	}
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header-with-filters .pr-rd-review-header-sorts {
	margin-top: 25px;
	margin-bottom: 60px;
	padding-right: 20px;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-header-sorts:before {
	content: "Sort By:";
	padding-right: 10px;
	font-family: Avenir;
	font-size: 18px;
	font-weight: 300;
	line-height: 18px;
	color: #3E464B;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header-with-filters .pr-rd-review-header-sorts:before {
	content: "Sort By:";
	padding-right: 10px;
	font-family: Avenir;
	font-size: 18px;
	font-weight: 300;
	line-height: 18px;
	color: #3E464B;
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort-group:before {
	@media (max-width: 767px) {
	content: "Sort By:";
	padding-right: 10px;
	font-family: Avenir;
	font-size: 15px;
	font-weight: 300;
	line-height: 18px;
	color: #3E464B;
	}
}

.p-w-r .pr-review-display .pr-rd-sort {
	background-color: #3E464B !important;
	border: 0px !important;
	color: #FFFFFF !important;
	height: 36px !important;
	font-size: 18px !important;
	font-weight: 900 !important;
}

.p-w-r .pr-review-display .pr-star-v4 {
	height: 28px;
	width: 28px;
}

.p-w-r .pr-review-display .pr-star-v4:hover {
	height: 28px;
	width: 28px;
}

.p-w-r .pr-review-display .pr-snippet-rating-decimal {
	display: none !important;
}

.p-w-r .pr-review-display .pr-rd-helpful-action .pr-rd-helpful-text {
	display: none !important;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-helpful-action-btn-group {
	display: none !important;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-helpful-action-btn-group {
	display: none !important;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-review {
	padding-left: 50px;
	padding-top: 20px;
	border: 1px solid #E5E5E5;
	border-bottom: none;
	margin-top: 0px;
	margin-bottom: 0px;
}

.p-w-r .pr-rd-flag-review-btn {
	color: #CC0000 !important;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-review {
	padding-left: 50px;
	border: 1px solid #E5E5E5;
	border-bottom: none;
	margin-bottom: 0px;
	margin-top: 0px;
	padding-top: 20px;
}

.p-w-r .pr-review-display a {
	color: #CC0000 !important;
	font-family: Avenir;
	font-size: 14px;
	line-height: 19px;
	font-weight: 300;
}

.p-w-r .pr-review-display a:focus {
	outline: none;
	box-shadow: none;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-review-headline {
	color: #3E464B;
	font-family: Ultramagnetic;
	font-size: 24px;
	font-weight: 300;
	line-height: 24px;
	text-transform: uppercase;
	float: left;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-review-headline {
	color: #3E464B;
	font-family: Ultramagnetic;
	font-size: 24px;
	font-weight: 300;
	line-height: 24px;
	text-transform: uppercase;
	float: left;
}

.p-w-r .pr-review-display .pr-rd-side-content-block {
	float: right;
	padding-right: 22px;
	margin-top: -20px;
}

.p-w-r .pr-review-display .pr-rd-side-content-block {
	@media (max-width: 767px) {
		margin-top: 0px;
		float: none;
	}
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-block .pr-review-snapshot-histogram {
	padding-top: 30px;
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-block .pr-review-snapshot-histogram {
	@media (min-width: 1200px) {
		padding-top: 10px;
	}
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-block .pr-review-snapshot-histogram {
	@media (max-width: 767px) {
		padding-top: 10px;
		margin-top: -10px;
	}
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block .pr-histogram-label {
	color: #3E464B;
	font-family: Avenir;
	font-size: 14px;
	font-weight: 300;
	line-height: 36px;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block .pr-ratings-histogram-barValue {
	border-radius: 0px;
	background: #CC0000;

}


.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block .pr-ratings-histogram-bar {
	border-radius: 0px;
}

.p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-count {
	color: #3E464B;
	font-family: Avenir;
	font-size: 14px;
	font-weight: 300;
	line-height: 36px;
}

.p-w-r .pr-review-display .pr-rd-review-headline {
	@media (max-width: 991px) {
		font-family: Ultramagnetic;
		font-size: 24px;
		font-weight: 300;
		text-transform: uppercase;
		line-height: 24px;
		color: #3E464B;
	}
}

.p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-header {
	width: 100%;
	text-align: center;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-header .pr-review-snapshot-header-intro .pr-subheadline .pr-snapshot-logo-mobile {
	display: none;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-header .pr-review-snapshot-header-intro {
	display: inline-block;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-header .pr-review-snapshot-header-intro .pr-headline {
	text-transform: uppercase;
	font-family: Ultramagnetic;
	font-size: 36px;
	line-height: 39px;
	font-weight: 300;
	margin-top: 20px;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-header .pr-review-snapshot-header-intro .pr-headline {
	@media (max-width: 767px) {
		font-size: 24px;
		margin-top: 0px;
		margin-bottom: 0px;
	}
}

.p-w-r .pr-review-display p {
	text-align: left;
	padding-right: 20px;
}

.p-w-r .pr-review-display p {
	@media (max-width: 991px){
		max-width: 460px;
		font-family: Avenir;
		font-size: 14px;
		font-weight: 300;
		line-height: 19px;
	}
}

div.pr-rd-helpful-action-btn-group {
	display: none;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-footer {
	background-color: #EEEEEE;
	height:169px;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-footer {
	background-color: #EEEEEE;
	height:169px;
}


.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-pagination {
	margin-top: 50px;
	text-align: center;
	width: 100%;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-pagination {
	margin-top: 50px;
	text-align: center;
	width: 100%;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-pagination a:first-child {
	padding-left: 18px;
	padding-right: 18px;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-pagination a {
	color: #ffffff !important;
	border: 1px solid #ffffff;
	text-decoration: none;
	font-size: 16px;
	text-transform: uppercase;
	width: 122px;
	background-color: #CC0000;
	margin-top: 20px;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-pagination a {
	color: #ffffff !important;
	border: 1px solid #ffffff;
	text-decoration: none;
	font-size: 16px;
	text-transform: uppercase;
	width: 122px;
	background-color: #CC0000;
}

.p-w-r .pr-review-display .pr-rd-pagination .pr-rd-pagination-btn {
	height: 60px;
	width: 122px;
	font-family: Ultramagnetic;
	text-transform: uppercase;
	color: #FFFFFF !important;
	background: #CC0000;
	display: inline-block;
	line-height: 60px;
	margin-right: 5px;
	margin-left: 5px;
}

.p-w-r .pr-review-display .pr-rd-pagination .pr-rd-pagination-btn {
	@media (max-width: 767px) {
		font-size: 18px;
	}
}

.p-w-r .pr-review-display .pr-rd-pagination .pr-rd-pagination-btn:last-child {
	color: #ffffff !important;
}

pr-rd-content-block {
	display: inline-block;
	text-align: center;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-to-top {
	float: none;
	text-align: center;
	display: inline-block;
	width: 100%;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-to-top {
	float: none;
	text-align: center;
	display: inline-block;
	width: 100%;
}

.pr-rd-content-block {
	width: 100%;
	display: contents;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-footer {
	display: inline-block;
	width: 100%;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-footer {
	display: inline-block;
	width: 100%;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-to-top {
	display: none;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-to-top {
	display: none;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-review-position {
	float: none !important;
	text-align: center !important;
	padding-top: 20px !important;
}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-review-position {
	float: none;
	text-align: center;
	padding-top: 20px;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-header {
	display: inline-block;
}

.p-w-r .pr-review-snapshot .pr-snippet .pr-snippet-stars-png {
	@media (max-width: 991px) {
	padding-top: 60px;
	width: 70%;
	text-align: center;
	margin-left: 42px;
	}
}

.p-w-r .pr-review-snapshot .pr-snippet .pr-snippet-stars-png {
	@media (max-width: 767px) {
	padding-top: 30px;
	width: 100%;
	text-align: center;
	margin-left: 0px;
	margin-bottom: 20px;
	}
}

.p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-simple .pr-snippet-read-and-write {
	@media (max-width: 991px) {
		margin-top: -20px;
	}
}

.p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-simple .pr-snippet-rating-decimal {
	display: inline-block;
    text-align: center;
    font-family: Ultramagnetic;
    font-size: 24px;
    border: none;
    float: none;
    margin-top: -77px;
    vertical-align: top;
    color: #3E464B;
    font-weight: 300;
}

.p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-simple .pr-snippet-rating-decimal {
	@media (max-width: 768px) {
		margin-top: -10px;
	}
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
	background: transparent;
}

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
	@media (max-width: 992px) {
		margin-top: -20px;
	}
}

.p-w-r .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
	position: absolute;
    text-align: center;
    display: contents;
}

.p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-simple .pr-snippet-read-and-write {
	text-align: center;
}

.p-w-r .pr-review-snapshot .pr-snippet .pr-snippet-stars-png .pr-star-v4-100-filled {
	background-color: #FFBB11;
	text-align: center;
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-content-block {
	margin-bottom: 0px !important;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-review-snapshot-block:first-child {
	height: 237px;
	width: 272px;
	background: #FFFFFF;
	margin-right: 2px;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-review-snapshot-block:first-child {
	@media (max-width: 767px) {
		text-align: center;
		height: 182px;
		width: 280px;
		float: none;
		margin-right: 0px;
	}
}

.p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-review-snapshot-block:last-child {
	width: 364px;
	height: 237px;
	background: #FFFFFF;
	margin-left: 2px;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-review-snapshot-block:last-child {
	@media (max-width: 767px) {
		float: none;
		text-align: center;
		width: 280px;
		height: 203px;
		margin-left: 0px;
	}
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a {
	@media (max-width: 767px) {
		width: 160px !important;
		margin-top: 10px;
	}
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-header-intro {
	border-bottom: none !important;
	padding-bottom: 0px;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple {
	margin-bottom: 20px;
	margin-top: -10px;
}

.p-w-r .pr-review-display .pr-rd-main-footer {
	@media (max-width: 991px) {
		background: #EEEEEE;
		height: 169px;
		text-align: center;
	}
}

.p-w-r .pr-review-display .pr-rd-main-footer {
	@media (max-width: 767px) {
		height: 139px;
	}
}

.p-w-r .pr-review-display .pr-rd-review-position {
	@media (max-width: 991px) {
		display: inline-block;
		font-family: Avenir;
		font-size: 14px;
		color: #3E464B;
		font-weight: 300;
		line-height: 19px;
		margin-top: 20px;
	}
}

#pr-review-display-7528 > div {
	display: none;
}

#pr-review-display > div > section.pr-rd-images.pr-rd-content-block {
	display: none;
}

.pr-rd-to-top.pr-underline {
	display: none;
}

.p-w-r .pr-review-display .pr-rd-pagination {
	border: none;
}
