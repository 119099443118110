/**
 *
 */

.header-sm {
  background-color: var(--sm-header-background);
  color: var(--xs-header-color);
  text-align: center;

  .container {
    background-color: var(--xs-header-background);
  }

  h1 {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    padding: 10px 5px;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 26px;
      line-height: 28px;
      padding: 21px 15px;
    }
  }
}

.header {
  @media (min-width: 768px) {
    background-color: var(--sm-cta-background);
  }

  @media (min-width: 992px) {
    background-color: var(--md-header-background);
    color: var(--md-header-color);
  }

  .container {
    @media (min-width: 768px) {
      background-color: var(--sm-header-container-background);
    }

    @media (min-width: 992px) {
      background-color: var(--md-header-container-background);
      padding: 0;

      & > .row {
        display: table;
        width: 100%;
        margin: 0;
      }
    }
  }

  .col-xs-12 {
    min-height: 0;
  }

  .col-md-8 {
    min-height: 0;

    @media (min-width: 992px) {
      padding: 50px 30px 30px;
      width: 620px;
    }    

    @media (min-width: 1200px) {
      width: 780px;
    }

    h1 {
      font-size: 30px;
      line-height: 33px;
      margin: 0;
      font-weight: bold;

      @media (min-width: 1200px) {
        font-size: 36px;
        line-height: 39px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin: 10px 0 0;
      padding: 0;

      a {
        color: var(--xs-header-link-color);

        &:hover {
          color: var(--xs-header-link-hover-color);
        }
      }
    }
  }
}

.header-sm h1 {
  margin-top: 0px;
  padding-top: 15px;
}

@media (min-width: 768px) {
  .header-sm h1 {
    margin-top: 0px;
    padding-top: 24px;
  }
}

@media (min-width: 992px) {
  .header .col-md-8 {
    padding-top: 0px;
  }
  .header h1 {
   margin-top: 0px;
   padding-top: 32px;
  }
}

@media (min-width: 1200px) {
  .header h1 {
    margin-top: 0px;
    padding-top: 42px;
  }
}

.header-button-container {
  padding-top: 5px;
  text-align: center;

  .btn {
    display: block;
    margin: 0 auto 15px;
    padding: 15px;
    width: 200px;
    text-transform: none;

    @media (min-width: 768px) {
      display: inline-block;
      margin: 0 10px;
    }

    @media (min-width: 992px) {
      width: 170px;
      margin: 28px 20px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (min-width: 1200px) {
      width: 200px;
    }
  }

  @media (min-width: 768px) {
    padding-top: 0;
    margin: -6px 0 30px;
  }

  @media (min-width: 992px) {
    text-align: left;
    margin-bottom: 0;
  }
}