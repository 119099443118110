.table-section {
  background: green;
  .container {
    background: green;
    padding-top: 20px;
    h2 {
      margin-top: 0px;
      padding-top: 0px;
      padding-bottom: 20px;
    } 
    
    p {
      padding-bottom: 5px;
    }
    
    h2, p {
      color:#0f0;
    }
    @media (max-width: 767px) {
      h2 {
        padding-bottom: 5px;
      }  
    }

    @media (min-width: 768px) {
      padding-top: 40px;
      p {
        padding-bottom: 15px;
      }
      h2 {
        padding-bottom: 0px;
      }
      padding-bottom: 15px; 
    }

    @media (min-width: 992px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .table-module {
    background: transparent;
    border-spacing: 2px;
    border-collapse: separate;
    .table-cell {
      color: #0f0;
      border: none !important;
      font-weight: normal;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  
  .table-header, .table-module th  {
    background:#0f0;
    color: black;
    font-weight: 900;
    min-width: 100px;
    max-width: 150px;
    width: 100px;
    padding-top: 16px;
    padding-bottom: 16px;
   
  }
  
}



